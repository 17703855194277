@font-face {
  font-family: 'icomoon-l';
  src:  url('../fonts/l/icomoon.eot?9r9smk');
  src:  url('../fonts/l/icomoon.eot?9r9smk#iefix') format('embedded-opentype'),
    url('../fonts/l/icomoon.ttf?9r9smk') format('truetype'),
    url('../fonts/l/icomoon.woff?9r9smk') format('woff'),
    url('../fonts/l/icomoon.svg?9r9smk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-l-"], [class*=" icon-l-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-l' !important;
  speak: none;
  font-size: 24px !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-l-account:before {
  content: "\e900";
}
.icon-l-arrow-A-d01:before {
  content: "\e901";
}
.icon-l-arrow-A-l01:before {
  content: "\e902";
}
.icon-l-arrow-A-r01:before {
  content: "\e903";
}
.icon-l-arrow-A-u01:before {
  content: "\e904";
}
.icon-l-bell:before {
  content: "\e905";
}
.icon-l-caution:before {
  content: "\e906";
}
.icon-l-cow-face:before {
  content: "\e907";
}
.icon-l-data-add:before {
  content: "\e908";
}
.icon-l-group:before {
  content: "\e909";
}
.icon-l-history:before {
  content: "\e90a";
}
.icon-l-list-alt-add:before {
  content: "\e90b";
}
.icon-l-search:before {
  content: "\e90c";
}
.icon-l-upload-file:before {
  content: "\e90d";
}
