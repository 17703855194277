/*---------------------------------
　フローティングボックス
---------------------------------*/

%uFloatingbox {
  position: fixed;
  z-index: 102000;
  right: 8px;
  bottom: calc(env(safe-area-inset-bottom) + 64px);
  display: inline-flex;
  height: 40px;
  padding: 0 4px;
  border-radius: 20px;
  background: rgba(46, 46, 46, .8);
  box-shadow: 0 2px 3px rgba(0, 0, 0, .32);
  transition-property: all;
  transition-timing-function: ease;
  transition-duration: 200ms;
}

%uFloatingbox__link {
  display: flex;
  width: 48px;
  height: 40px;
  color: white(primary);

  align-items: center;
  justify-content: center;

  [class^="icon-"] {
    z-index: 1;
  }
}

%uFloatingbox__lists {
  position: absolute;
  right: 0;
  bottom: 100%;
  display: block;
  overflow-y: scroll;
  width: 180px;
  transition: all .2s ease;
  border-radius: 8px;
  background: #eee;
  box-shadow: 0 3px 30px rgba(0, 0, 0, .5);
}

/*
　フローティングボックス
---------------------------------*/
.uFloatingbox {
  visibility: hidden;
  opacity: 0;

  @extend %uFloatingbox;

  &--open {
    visibility: visible;
    opacity: 1;

    @extend %uFloatingbox;
  }

  &__backdrop {
    position: fixed;
    z-index: 102000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
  }

  &__items {
    display: flex;
  }

  &__item {
    &:not(:first-child) {
      margin: 0 0 0 4px !important;
    }
  }

  &__link {
    @extend %uFloatingbox__link;

    &--open {
      @extend %uFloatingbox__link;

      &::before {
        position: absolute;
        top: 4px;
        right: 4px;
        display: block;
        width: 48px;
        height: 32px;
        content: '';
        border-radius: 16px;
        background: #909090;

      }
    }
  }

  &__lists {
    visibility: hidden;
    opacity: 0;

    @extend %uFloatingbox__lists;

    &--open {
      visibility: visible;
      opacity: 1;

      @extend %uFloatingbox__lists;
    }
  }

  &__list {
    font-size: 14px;
    display: flex;
    height: 53px;
    padding: 0 16px;
    white-space: nowrap;
    text-decoration: none !important;
    color: black(primary);
    background: white(primary);

    align-items: center;

    &:not(:first-child) {
      margin: 1px 0 0 !important;
    }

    &::after {
      position: absolute;
      right: 18px;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid black(primary);
      border-right: 2px solid black(primary);
    }
  }
}
