/* Alert
----------------------------------------*/

.module-alert {
  margin: 0;
}

.module-alert .ma- {
  &list {
    font-size: 17px;
    line-height: 1.4;
    position: relative;

    &Item {
      background-color: #900000;
      color: #fff;
      display: flex;
       align-items: center;
      padding: calc-rem(4);
      width: 100%;

      > a {
        background: linear-gradient(to left, #a50606 0%, #a82000 100%);
        border: solid calc-rem(1) rgba(198, 61, 61, 0.16);
        display: inherit;
         align-items: inherit;
        padding: calc-rem(12) calc-rem(60) calc-rem(12) calc-rem(8);
        position: relative;
        width: inherit;

        // &::before {
        //   @extend [class^="icon-"];
        //   @include font-size(0, 25);
        //   background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23FFFFFF%3B%20stroke%3A%23fff%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16.783%2C10.35c-0.5-0.5-1.3-0.5-1.8%2C0s-0.5%2C1.3%2C0%2C1.8l7.9%2C7.8l-7.9%2C7.9c-0.5%2C0.5-0.5%2C1.3%2C0%2C1.8s1.3%2C0.5%2C1.8%2C0%20l8.8-8.8c0.5-0.5%2C0.5-1.3%2C0-1.8L16.783%2C10.35z%22%2F%3E%3C%2Fsvg%3E');
        //   content: "";
        //   height: 2.6rem;
        //   position: absolute;
        //   top: 50%;
        //   right: 0.5rem;
        //   transform: translateY(-50%);
        //   width: 2.6rem;
        // }

        &:hover,
        &:active {
          text-decoration: none;
        }
      }

      &_icon {
        font-size: 40px;
        flex: 0 0 auto;
        margin-right: calc-rem(8);
      }

      &_label {
        font-size: 14px;
      }

      &:first-child {
        border-top-width: calc-rem(4);
      }

      &:last-child {
        border-bottom-width: calc-rem(4);
      }
    }
  }
}


/* Alert
----------------------------------------*/
.err-input {
  background-color: #ebdfe1;
  border: 1px solid #bd112d !important; }

.sid-home .err-msg {
  font-size: 13px;
  background-color: #a90c26;
  bottom: -50px;
  color: #fff;
  left: 0;
  padding: 10px;
  position: absolute;
  text-align: center;
  white-space: nowrap;

  &::after {
    border-bottom: 24px solid #a90c26;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: block;
    height: 0;
    left: 29px;
    position: absolute;
    top: -21px;
    width: 0;
  }
}
