.calving-sensor-history {
  .calving-sensor-list {
    max-height: 60vh;
    overflow: scroll;
    .calving-sensor-item {
      padding: 5px 8px;
      border-bottom: 1px solid #ccc;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .md-footer {
    border-top: 1px solid #333;
  }
}
