/* chart on the list */

.chart-container {
  position: fixed;
  bottom: 0;
  height: 45vh;
  width: 96vw;
  z-index: 1;
  margin: 2vw;
  box-shadow: 0 0.2rem 1rem rgba(146, 146, 146, 1);
  background-color: white;
  display: flex;
  flex-direction: column;
}

.chart-header {
  height: 3.5rem;
  width: 100%;
  background-color: dimgray;
  box-shadow: 0 0.2rem 0.5rem rgba(146, 146, 146, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.chart-header:first-child {
  color: white;
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}

.chart-header .close-chart {
  position: absolute;
  padding: 0.4rem 1rem;
  right: 0;
  font-size: 1.8rem;
}

#dysstasia-chart {
  flex: 1 1 auto;
  width: 90%;
  height: 25%;
}

.dysstasia {
  .loading-wrapper {
    display: table-cell;
    width: 280px;
    height: 250px;
    text-align: center;
    vertical-align: middle;
  }
}

#dysstasia-chart .c3-regions .dysstasia-gte-25, .dysstasia-chart .c3-regions .dysstasia-gte-25 {
  fill: #ff0000;
}

.dysstasia-state-confirmed {
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
}

.dysstasia-state-unconfirmed {
  font-size: 1.2rem;
  font-weight: bold;
  color: red;
}

.dysstasia-state-recoverd {
  font-size: 1.2rem;
  font-weight: bold;
  color: #6495ED;
  margin-left: 8px;
}

#activity,
#milking {
  .module-form {
    margin: 0;
    padding: 0;
  }

  .mf-group {
    margin-bottom: 2em;
  }

  #activity-chart-wrapper {
    min-height: 350px;
    max-width: 350px;
    margin: auto;
  }

  #heat-chart-wrapper {
    max-width: 350px;
    min-height: 350px;
    margin: auto;
    svg {
      g.c3-regions {
        g.c3-region {
          &.score-color1 { fill : steelblue; }
          &.score-color2 { fill : #389c64; }
          &.score-color3 { fill : #418632; }
          &.score-color4 { fill : #ffff00; }
          &.score-color5 { fill : #ff0000; }
        }
      }
    }
  }
  .activity-chart-range-selector {
    margin-bottom: 2em;

    div.chart-header {
      background-color: white;
      box-shadow: none;
      display: flex;
      justify-content: space-between;

      .chart-header-item {
        min-width: 100px;
        text-align: left;
        padding: 0px;
      }

      .chart-header-item:nth-child(1) {
        display: none;
      }

      .chart-header-item {
        min-width: 100px;
      }

      .btn-back {
        display: none;
      }
      .btn-next {
        display: none;
      }
    }
    #activity-date {
      width: 7em;
      font-size: 18px;
      font-weight: bold;
      color: black;
      border: none;
      text-align: left;
      pointer-events: none;
      background-color: #fff;
    }
    #activity-date:focus {
      outline: none;
    }
    i.disabled {
      color: #ccc !important;
    }
    button {
      box-shadow: none;
      font-size: 15px;
    }
    button+button {
      margin-left: 0px;
    }
  }
}

#activity-stacked-bar-chart-wrapper {
  .c3-legend-item {
    pointer-events: none;
  }

  .c3-tooltip {
    th {
      font-size: 1.2rem;
      min-height: 33px;
      padding: 5px 0;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      border-top: 1px solid #dbdbdb;
      border-right: 1px solid #eee;
      background: #777;
    }

    td {
      font-size: 1.2rem;
      min-height: 1px;
      padding: 5px;
      vertical-align: middle;
      color: #333;
      border-top: 1px solid #ddd;
      border-right: 1px solid #ddd;
    }
  }
}

#activity-stacked-bar-chart-wrapper, #activity-rate-chart-wrapper {
  .u-loader, .has-no-data {
    margin: auto;
    display: flex;
    width: 320px;
    height: 320px;
    align-items: center;
    justify-content: center;
  }
}

chart-activity-range-selector {
  .edit-btn {
    border: none;
    position: relative;
    display: inline-block;
    min-width: 3rem;
    height: 26px;
    padding: 0 10px;
    color: #666;
    font-size: 1.2rem;
    line-height: 22px;
    background-color: #ececec;
    border-radius: 6px; }

  .edit-btn:disabled {
    background-color: #ff9908;
    color: #fff;
  }
  .edit-btn:hover {
    text-decoration: none;
    opacity: .8;
  }
}

// 分娩兆候指数チャート
.c3-regions .calving-high {
  fill: #bc0822;
}

.c3-regions .calving-low {
  fill: #ff9e08;
}

// チャート共通
.chart-grid-line-red line {
  stroke: red;
}

.chart-grid-line-orange line {
  stroke: orange;
}

.chart-grid-line-gray line {
  stroke: gray;
}

#activity-rate-chart {
  .c3-xgrid-lines {
    .c3-xgrid-line {
      &.breeding-date {
        line {
          stroke: #bc0822;
        }
        text {
          fill: #bc0822;
        }
      }
    }
  }
}
