.columnList {
  &__items {
    padding: 0;
    padding: 0 8px;
    list-style: none;
  }

  &__item {
    padding: 24px 8px 21px 8px;
    border-bottom: 1px solid #ededed;
  }

  &__link {
    text-decoration: none !important;
  }

  &__head {
    display: flex;
    margin: 0 6px 0 0;

    align-items: center;
    justify-content: space-between;
  }

  &__date {
    font-size: 13px;
    margin: 0;
    color: #212121;
  }

  &__heading {
    font-size: 15px;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    margin: 15px 0 0 0;
    padding: 0 20px 0 0;
    color: #212121;

    &::after {
      position: absolute;
      top: calc(50% - 4px);
      right: 2px;
      display: block;
      width: 10px;
      height: 10px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid #333;
      border-right: 2px solid #333;
    }
  }
}