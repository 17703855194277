/* # Footer
----------------------------------------*/

.layout-footer {
  background-color: #000;
  color: #fff;

  > .container {
    display: flex;
     align-items: center;
     flex-wrap: wrap;
     justify-content: center;
    height: calc-rem(25);

    &::before,
    &::after {
      display: none;
    }
  }
}


.layout-footer .lf- {
	&copyright {
    font-size: calc-rem(10);
	}
}
