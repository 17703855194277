// /*----------------------------------------
//  Mixins
// ----------------------------------------*/

// /* Typography
// ----------------------------------------*/

@use "sass:math";

@mixin font-size($font-size, $font-size-up-sm: null) {
// @mixin font-size($font-size) {
  // $font-sizeValue: $font-size / 375 * 100;
  // font-size: #{$font-sizeValue}vw;

  @if $font-size == 0 {
  } @else {
    $font-sizeValue: math.div($font-size, 375) * 100;
    font-size: #{$font-sizeValue}vw;
  }

  @if $font-size-up-sm {
    @media (min-width: 569px) {
      $font-sizeValue: $font-size-up-sm * 0.1;
      font-size: #{$font-sizeValue}rem;
    }
  } @else {
    @media (min-width: 569px) {
      $font-sizeValue: $font-size * 0.1;
      font-size: #{$font-sizeValue}rem;
    }
  }
}
