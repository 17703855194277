/*---------------------------------
　ボタン
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uButton {
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  width: 136px;
  height: 40px;
  opacity: 1;
  color: white(primary);
  border: none;
  border-radius: 20px;
  outline: 0;
  box-shadow: none;

  &:not(:last-child) {
    margin: 0 0 0 24px !important;
  }
}

%uButton--disabled {
  opacity: .32;
  box-shadow: none;
}

/*
　ボタン
---------------------------------*/
.uButton {
  text-indent: .5em;
  letter-spacing: .5em;
  border: 1px solid rgba(0, 0, 0, .12);
  background: linear-gradient(to right, #387ac7, #0d3898);
  box-shadow: 0 2px 3px rgba(0, 0, 0, .36);

  @extend %uButton;

  &:disabled {
    border: 1px solid rgba(0, 0, 0, .12);
    background: linear-gradient(to right, #387AC7, #0D3898);

    @extend %uButton--disabled;
  }

  /*
  　ボタン - 削除ボタン
  ---------------------------------*/
  &--delete {
    text-indent: .5em;
    letter-spacing: .5em;
    border: 1px solid rgba(0, 0, 0, .24);
    background: linear-gradient(to right, #d00c28, #ac0119);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .36);

    @extend %uButton;

    &:disabled {
      border: 1px solid rgba(0, 0, 0, .24);
      background: linear-gradient(to right, #d00c28, #a20017);

      @extend %uButton--disabled;
    }
  }

  /*
  　ボタン - キャンセルボタン
  ---------------------------------*/
  &--cancel {
    color: black(primary);
    background: linear-gradient(to right, #fff, #fff);

    @extend %uButton;

    &:disabled {
      opacity: 1;
      color: black(quaternary);
      background: linear-gradient(to right, #f2f2f2, #f2f2f2);

      @extend %uButton--disabled;
    }
  }

  /*
  　ボタン - 丸みのあるボタン
  ---------------------------------*/
  &--rounded {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    height: 36px;
    margin: 0 !important;
    padding: 0 17px 0 10px;
    color: black(primary);
    border: none;
    border-radius: 18px;
    outline: 0;
    background: #f0f0f0;

    align-items: center;

    [class^="icon"] {
      margin: 0 8px 0 0 !important;
    }

    &Secondary {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      height: 40px;
      margin: 0 !important;
      padding: 0 16px 0 12px;
      color: black(primary);
      border: none;
      border-radius: 18px;
      outline: 0;
      background: white(primary);

      align-items: center;

      [class^="icon"] {
        margin: 0 8px 0 0 !important;
      }
    }
  }

  /*
  　ボタン - 丸みのある枠線ありボタン
  ---------------------------------*/
  &--roundedBorder {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    width: 112px;
    height: 40px;
    color: #444;
    border: 2px solid #666;
    border-radius: 20px;
    outline: 0;
    background: #fff;

    align-items: center;
    justify-content: center;
  }

  /*
  　ボタン - 矢印付きボタン
  ---------------------------------*/
  &--arrow {
    font-size: 13px;
    position: relative;
    display: flex;
    height: 40px;
    padding: 0 30px 0 14px;
    color: black(primary);
    border: 1px solid white(primary);
    border-radius: 20px;
    outline: 0;
    background: white(primary);
    box-shadow: 0 2px 3px rgba(0, 0, 0, .16);

    align-items: center;

    &::after {
      position: absolute;
      top: 14px;
      right: 14px;
      display: block;
      width: 6px;
      height: 6px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid black(primary);
      border-right: 2px solid black(primary);
    }
  }

  &__heading {
    font-size: 14px;
    font-weight: 700;
    margin: 0 16px 0 0 !important;
    color: black(primary);
  }

  &__text {
    font-size: 13px;

    &--secondary {
      font-size: 13px;
      margin-left: auto !important;
      color: #0d52a2;
    }
  }
}