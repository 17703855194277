.article {
  padding: 29px 20px 88px;

  &__head {
    display: flex;

    align-items: center;
    justify-content: space-between;
  }

  &__date {
    font-size: 13px;
    margin: 0;
    color: #212121;
  }

  &__heading {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.7;
    margin: 20px 0 0;
    color: #212121;
  }

  &__body {
    font-size: 14px;
    line-height: 1.75;
    margin: 37px 0 0;
    color: #212121;

    a {
      color: #337ab7;
    }
  }

  &__back {
    font-size: 14px;
    font-weight: 700;
    position: relative;
    display: inline-flex;
    height: 40px;
    margin: 80px 0 0;
    padding: 0 22px 0 46px;
    color: #212121;
    border: 1px solid #909090;
    border-radius: 44px;

    justify-content: center;
    align-items: center;

    .icon-s-back {
      position: absolute;
      top: calc(50% - 9px);
      left: 14px;
    }
  }
}