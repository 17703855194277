/* Header
----------------------------------------*/

.layout-header {
  background-color: #1f1f1f;
  color: #fff;
  position: fixed;
   top: 0;
   left: 0;
	z-index: 100000;

  .is-stickyAlertNotification &,
  .is-stickyAlertDysstasia & {
    top: 8.8rem;
  }

  .is-stickyAlertNotification.is-stickyAlertDysstasia & {
    top: 17.6rem;
  }

	> .container {
		display: flex;
		 align-items: center;
     flex-wrap: wrap;
    height: calc-rem(50);

    &::before,
    &::after {
      display: none;
    }
	}
}


.layout-header .lh- {
	&title {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    overflow: hidden;
    padding-right: 7.9rem;
    padding-left: 4.4rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    &_object {
      max-width: calc-rem(32);
    }
	}

  &list {
    position: absolute;

    &Item {
      position: absolute;
       top: 50%;
      transform: translateY(-50%);

      a {
        display: inline-flex;
         align-items: center;
         justify-content: center;
        height: 5rem;
        padding: 0;
        width: 4.4rem;
      }

      &_icon {
        background-color: #477d17;
        border-radius: 50%;
        display: flex;
         align-items: center;
         justify-content: center;
        height: calc-rem(22);
        position: relative;
        width: calc-rem(22);

        &::before {
          border-style: solid;
          border-width: calc-rem(4) calc-rem(7) calc-rem(4) 0;
          border-color: transparent #fff transparent transparent;
          content: '';
          display: block;
          height: 0;
          margin-left: calc-rem(-3);
          width: 0;
        }
      }

      &[data-listItem="previous"] {
        left: 0;
      }

      &[data-listItem="next"] {
        right: 0;
      }

      .icon-css-arrow {
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 8px;
        transform: rotate(-135deg);
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
      }
    }
  }
}
