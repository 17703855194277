@font-face {
  font-family: 'icomoon-ss';
  src:  url('../fonts/ss/icomoon.eot?a4opc8');
  src:  url('../fonts/ss/icomoon.eot?a4opc8#iefix') format('embedded-opentype'),
    url('../fonts/ss/icomoon.ttf?a4opc8') format('truetype'),
    url('../fonts/ss/icomoon.woff?a4opc8') format('woff'),
    url('../fonts/ss/icomoon.svg?a4opc8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-ss-"], [class*=" icon-ss-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-ss' !important;
  speak: none;
  font-size: 12px !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-ss-check:before {
  content: "\e917";
}
.icon-ss-chevron-r01:before {
  content: "\e918";
}
.icon-ss-modal:before {
  content: "\e919";
}
