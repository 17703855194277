@charset "UTF-8";
/*----------------------------------------
 # Unique Style of 'Detail'
----------------------------------------*/

// 全体のフォントサイズの単位を統一する際には、base.scssを更新し下記のコードを削除予定
body {
  font-size: 12px;

  &.is-legacyScreen {
    font-size: 12px;
    overflow: auto;
  }
}


// Prefix
[class^="detail-"] {
	position: relative;
}

.barn--mobileCowDetail {
  overflow: hidden;
  height: var(--app-height) !important;
  min-height: var(--app-height);
  max-height: var(--app-height);

  &.is-stickyAlertNotification,
  &.is-stickyAlertDysstasia {
    .layout-main {
      padding-top: 128px;
    }
  }

  &.is-stickyAlertNotification.is-stickyAlertDysstasia {
    .layout-main {
      padding-top: 216px;
    }
  }
}

/* Label
----------------------------------------*/

.detail-label {
  display: flex;
  font-size: 13px;
  min-height: calc-rem(48);
  padding: 0 calc-rem(48) 0 0;
  width: 100%;

  .add-label {
    color: rgba(33, 33, 33, .62);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: calc-rem(48);
    width: calc-rem(44);
    position: absolute;
    top: 0;
    right: 0;

    .icon-s-edit {
      color: #444;
    }

    .icon-css-add {
      position: relative;
      width: 40px;
      height: 40px;

      &::before {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 7px);
        display: block;
        width: 14px;
        height: 2px;
        content: '';
        background-color: #444;
      }

      &::after {
        position: absolute;
        top: calc(50% - 1px);
        left: calc(50% - 7px);
        display: block;
        width: 14px;
        height: 2px;
        content: '';
        transform: rotate(90deg);
        background-color: #444;
      }
    }
  }
}

.detail-label .dl- {
  &title {
    color: rgba(33, 33, 33, .47);
    align-self: center;
    margin-bottom: 0;
    font-weight: 400;
  }

  &list {
    margin: 0;
    padding: calc-rem(8) 0;

    &Item {
      border: calc-rem(1) solid #ccc;
      display: inline-flex;
      font-weight: 400;
      margin: calc-rem(4) calc-rem(8) calc-rem(4) 0;
      padding: calc-rem(2) calc-rem(8) 0;
    }
  }
}


/* Schedule
----------------------------------------*/

.detail-schedule {
  background: linear-gradient(to right, #c1207c 0%, #8d117a 100%);
  color: #fff;
  display: flex;
   align-items: center;
  font-size: 14px;
  padding: calc-rem(8);

  .tab-result & {
    margin: calc-rem(-20) calc-rem(-8) calc-rem(12);
  }
}

.detail-schedule .ds- {
  &list {
    width: 70%;

    &Item {
      background-color: rgba(255, 255, 255, .06);
      border: solid calc-rem(1) rgba(255, 255, 255, .02);
      display: flex;
      margin-bottom: calc-rem(1);
      padding: calc-rem(4) calc-rem(8) calc-rem(2);

      &_date {
        font-weight: 700;
      }

      &_label {
        flex: 0 0 auto;
        font-weight: 700;
        padding-right: calc-rem(12);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &title {
    margin-bottom: 0;
    padding-left: calc-rem(12);
    width: calc(30% + 6px);
  }
}


/* Tab
----------------------------------------*/

.detail-tab {
  position: relative;
  background-color: #fff;

  &::before {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #f2f2f2 100%);
    content: "";
    height: calc-rem(56);
    position: fixed;
    right: 0;
    width: calc-rem(32);
    z-index: 100000;
    pointer-events: none;
  }

  .tab-content {
    height: 100%;
    padding: 56px 0 0;
    background-color: #fff;
    font-size: 11px;

    ul[rn-carousel] {
      margin: 0;
    }
  }
}

.tab-result {
  padding: calc-rem(20) calc-rem(8) calc-rem(10)!important;
  overflow: auto;

  &#information {
    padding-right: calc-rem(8) !important;
    padding-left: calc-rem(8) !important;
  }

  &--genom {
    overflow: auto;

    .uContent--secondary {
      padding-bottom: calc-rem(72)!important;
    }
  }

  &#milking, &.activity {
    padding-bottom: calc-rem(150)!important;
  }
}

.detail-tab .dt- {
  &list {
    background-color: #f2f2f2;
    display: flex;
     align-items: flex-end;
    font-size: 14px;
    margin-bottom: 0;
    overflow-x: auto;
    position: fixed;
    left: 0;
    width: 100%;
    z-index: 10000;

    &::-webkit-scrollbar {
      display: none;
    }

    &Item {
      color: rgba(33, 33, 33, .47);
      display: inline-flex;
       align-items: center;
       flex: 0 0 auto;
       justify-content: center;
      font-weight: bold;
      padding: 0 calc-rem(4);
      position: relative;
      text-align: center;
      height: calc-rem(56);

      &:first-child {
        padding-left: calc-rem(16);
      }

      &:last-child {
        padding-right: calc-rem(32);
      }

      &.active {
        color: #212121;

        .dt-listLink {
          &::after {
            background-color: #1d79b2;
            content: "";
            height: calc-rem(4);
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
          }
        }
      }
    }

    &Link {
      position: relative;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
      height: calc-rem(56);
      padding: calc-rem(6) calc-rem(18);
      text-decoration: none;
      width: 100%;
    }

    &.is-sticky {
      position: fixed;
       top: calc-rem(55);
    }
  }

  @media (min-width: 569px) {
    &list {
      &Item {
        padding: calc-rem(8) 0;
      }
    }
  }
}


/* Tab: Information
-------------------*/

.detail-tab {
  .section {
    .p10 {
      padding: 10px;
    }

    &.add-border01 {
      border: calc-rem(1) solid #dadada;
    }
  }

  .section:not(:last-child) {
    margin-bottom: calc-rem(24);
  }

  .section h4:first-child {
    margin-bottom: 0;
  }

  .section h5 {
    font-size: 1.4rem;
    background-color: #739315;
    border: none;
    color: #fff;
    font-weight: normal;
    margin-bottom: 0;
    padding: calc-rem(8) calc-rem(10);
  }

  table {
    width: 100%;
  }

  th,
  td                { font-size: 1.4rem; font-weight: normal; min-height: 33px; vertical-align: middle; border-right: 1px solid #ddd; }
  th                { color: #666; font-size: 1.4rem; font-weight: normal; padding: 8px; border-top: 1px solid #dbdbdb; border-right: none; text-align: center; }
  th span           { font-weight: normal; }
  th .unit          { margin-left: 0; color: #dfdfdf; }
  td                { padding: 10px; border-top: 1px solid #ddd; }

  table tr:first-child th,
  table tr:first-child td {
    border-top: none;
  }

  table tr:nth-child(odd),
  table tr:nth-child(even) {
    background-color: transparent;
  }

  table a {
    text-decoration: underline;
  }

  .section-information {
    overflow: hidden;
  }

  .section-information .information-detail {
    overflow: hidden;
    position: relative;
    .edit-btn {
      background-color: #ff9908;
      border-radius: 4px;
      color: #fff;
      font-size: 1.2rem;
      line-height: 1;
      padding: 4px;
      text-decoration: none;
      margin-left: 8px;
      display: inline-block;
    }
  }

  .section-information .detail-list {
    border-bottom: calc-rem(1) solid #ccc;
    font-size: 0;
    margin-bottom: calc-rem(5);
    padding-bottom: calc-rem(12);
    display: flex;
    align-items: center;
  }

  .section-information .detail-list li {
    font-weight: bold;
    align-items: center;
    display: flex;
    flex-grow: 1;
    font-size: 12px;
    max-width: calc(100% / 4);
    &:first-child {
      flex-grow: 2;
      max-width: calc(100% / 4 * 2);
      padding-left: 8px;
    }
  }

  .section-information .detail-list .glyphicon {
    color: #ddd0a8;
    transform: rotate(90deg);
    margin-right: 8px;
  }

  .section-information .cow-state {
    font-size: 1.5rem;
  }

  .section-information .tag-number {
    display: inline-block;
    line-height: 1;
  }

  .section-information .tag-number-id {
    font-size: 1.3rem;
  }

  .section-information .tag-number-id:nth-of-type(1) {
    display: block;
  }

  .section-information .tag-number-id:nth-of-type(2) {
    font-size: 1.6rem;
  }

  .section-information .detail-list li:last-of-type .tag-number-id {
    font-size: 1.5rem;
    word-break: break-word;
  }

  .section-information table,
  .section-information th,
  .section-information td {
    border: none;
  }

  .section-information tr:first-child {
    th, td {
      padding-top: 8px;
    }
  }

  .section-information tr:last-child {
    th, td {
      padding-bottom: 12px;
    }
  }

  .section-information th,
  .section-information td {
    min-height: auto;
    padding-top: 10px;
    padding-bottom: 4px;
    font-size: 1.4rem;
  }

  .section-information th {
    background-color: transparent;
    color: #666;
    font-weight: normal;
    padding-left: 8px;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: 96px;
  }

  .section-information td::before {
    margin-left: -1em;
  }

  .section-breeding table,
  .section-breeding th,
  .section-breeding td {
    border: none;
  }

  .section-breeding th,
  .section-breeding td {
    min-height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .section-breeding th {
    font-weight: normal;
    position: relative;
    text-align: left;
    white-space: nowrap;
    width: 1%;
  }

  .section-breeding tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  .section-breeding th {
    background-color: transparent;
  }

  .section-breeding td::before {
    margin-left: -1em;
  }

  .section-ancestors th,
  .section-ancestors td {
    text-align: left;
  }

  .section-ancestors th b,
  .section-ancestors td b {
    font-weight: normal;
  }

  .section-ancestors th:last-of-type,
  .section-ancestors td:last-of-type {
    border-right: none;
  }

  .section-ancestors a {
    color: #449dff;
    text-decoration: none;
  }

  .section-therapy th,
  .section-therapy td {
    min-height: auto;
    &:nth-last-of-type(1) {
      border-right: none;
    }
    b {
      font-weight: normal;
    }
  }

  .section-therapy td b {
    width: calc(1em + 2px);
  }

  .section-disease th,
  .section-disease td {
    min-height: auto;
    &:nth-last-of-type(1) {
      border-right: none;
    }
  }

  .section-disease th {
    width: 50%;
    text-align: left;
  }
}

.information-item {
  color: #212121;
  font-size: 15px;
  margin-bottom: calc-rem(40);
  overflow-x: hidden;
}

.information-item .ii- {
  &button {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: #444;
    display: flex;
     align-items: center;
     flex: 0 0 auto;
     justify-content: center;
    font-size: 40px;
    min-height: calc-rem(47);
    margin-left: auto;
    outline: none;
    padding: 0;
    width: calc-rem(44);
  }

  &column {
    width: 50%;
  }

  &data {
    font-size: 15px;
    font-weight: 700;
    padding-right: calc-rem(12);
    word-break: break-all;

    b {
      font-size: calc(1em + 2px);
    }

    .ii-container {
      display: flex;
       align-items: center;
      margin-right: calc-rem(-12);
    }

    &.ii-column {
      width: calc(50% - 8px);
    }

    &:not(.ii-column) {
      width: calc(60% - 12px);
    }
  }

  &link {
    color: #337ab7;
  }

  &label {
    color: #666;
    display: flex;
     align-items: center;
     align-self: flex-start;
    font-size: 14px;
    min-height: calc-rem(48);
    padding-right: calc-rem(12);
    padding-left: calc-rem(12);

    small {
      font-size: 13px;
    }

    &.ii-column {
      min-height: auto;
      width: calc(50% + 8px);
    }

    &:not(.ii-column) {
      width: calc(40% + 12px);
    }
  }

  &row {
    border-bottom: calc-rem(1) solid #ededed;
    display: flex;
     align-items: center;
    min-height: calc-rem(48);

    &:last-child {
      border-bottom: none;
    }

    &.row-wrap {
      flex-wrap: wrap;
      padding-top: calc-rem(5);
      padding-bottom: calc-rem(5);

      .ii-row {
        min-height: auto;
        margin-top: calc-rem(5);
        margin-bottom: calc-rem(5);
      }
    }
  }
}

.information-list {
  color: #333;
  font-size: 15px;
  overflow-x: hidden;
}

.information-list .il- {
  &button {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    color: rgba(33, 33, 33, .62);
    display: flex;
     align-items: center;
     flex: 0 0 auto;
     justify-content: center;
    font-size: 40px;
    min-height: calc-rem(40);
    margin-left: auto;
    outline: none;
    padding: 0;
    width: calc-rem(44);
  }

  &column {
    display: table-cell;
    width: auto;
  }

  &data {
    font-size: 15px;
    font-weight: 700;
    padding: calc-rem(8) calc-rem(8) calc-rem(8) 0;
    word-break: break-all;

    b {
      font-size: calc(1em + 2px);
    }

    .il-container {
      display: flex;
       align-items: center;
      margin-right: calc-rem(-8);
    }
  }

  &header {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    display: flex;
     align-items: center;
     justify-content: flex-end;
    font-size: 17px;
    min-height: calc-rem(48);
    outline: none;
    padding: 0;
    text-align: left;
    width: 100%;

    strong {
      align-self: center;
      margin-right: auto;
      padding: 0 calc-rem(8);
    }

    .icon-css-arrow {
      position: relative;
      display: block;
      width: 40px;
      height: 40px;
      margin: 0 2px 0 0;

      &::before {
        position: absolute;
        top: 11px;
        left: 7px;
        display: block;
        width: 10px;
        height: 10px;
        margin: 0 0 0 8px;
        content: '';
        transform: rotate(135deg);
        border-top: 2px solid #333;
        border-right: 2px solid #333;
      }
    }
  }

  &item {
    background-color: #fff;
    border-radius: calc-rem(4);
    padding: calc-rem(8);

    &.close {
      color: inherit;
      float: none;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      padding-top: 0;
      padding-bottom: 0;
      opacity: inherit;
      text-shadow: none;

      .il-header {
        & ~ * {
          display: none;
        }
      }
    }

    &.open {
      .il-header {
        margin-bottom: calc-rem(8);

        .icon-css-arrow {
          border-radius: 50%;
          background-color: #f2f2f2;

          &::before {
            top: 17px;
            transform: rotate(-45deg);
          }
        }
      }
    }

    &:not(:first-child) {
      margin-top: calc-rem(8);
    }
  }

  &label {
    color: #666;
    font-size: 14px;
    padding: calc-rem(8);
    width: 50%;

    small {
      font-size: 13px;
    }
  }

  &row {
    border-bottom: calc-rem(1) solid #ededed;
    display: table-row;
    min-height: calc-rem(40);

    &:last-child {
      border-bottom: none;
    }
  }

  &table {
    border-collapse: collapse;
    display: table;
    width: 100%;
  }

  @media (min-width: 360px) {
    &label {
      white-space: nowrap;
    }
  }
}

.information-primary {
  margin-top: calc-rem(-8);
}

.information-secondary {
  background-color: #eee;
  margin: 0 calc-rem(-8) calc-rem(-10);
  padding: calc-rem(16) calc-rem(8) calc-rem(150);
}

/* Tab: Timeline
-------------------*/

.detail-tab {
  .timeline { clear: both; position: relative; margin-left: 36px; padding-top: 10px; padding-bottom: 25px;}
  .timeline li { position: relative; width: 100%; margin-left: 0; margin-bottom: 16px; }
  .timeline li:last-child { margin-bottom: 0; }
  .timeline li:before,
  .timeline li:after { content: ""; position: absolute; left: -27px; top: 50%; display: block; background-color: #ddd; }

  .timeline li .timeline-content-wrapper { position: relative; display: table; width: 100%; border: 2px solid #ddd; }

  .timeline li .data-box { position: relative; display: table-cell;/*  float: left; */ width: 15%; padding-top: 5px; vertical-align: middle; background-color: #f7f7f7; }
  .timeline li .data {
    @include font-size(11);
   // font-size: 1.2rem;
   font-weight: bold; text-align: center; line-height: 1.1; font-family: arial; }
  .timeline li .data-box:before,
  .timeline li .data-box:after { content: ""; position: absolute; left: -2px; display: block; height: 2px; }
  .timeline li .data-box:before { top: -2px; }
  .timeline li .data-box:after { bottom: -2px; }
  .timeline li .category01 .data-box:before,
  .timeline li .category01 .data-box:after { background-color: #008c99; }
  .timeline li .category02 .data-box:before,
  .timeline li .category02 .data-box:after { background-color: #f56e9d; }
  .timeline li .category03 .data-box:before,
  .timeline li .category03 .data-box:after { background-color: #df6feb; }
  .timeline li .category04 .data-box:before,
  .timeline li .category04 .data-box:after { background-color: #826ef5; }
  .timeline li .category05 .data-box:before,
  .timeline li .category05 .data-box:after { background-color: #6ebff5; }
  .timeline li .category06 .data-box:before,
  .timeline li .category06 .data-box:after { background-color: #4dab8a; }
  .timeline li .category07 .data-box:before,
  .timeline li .category07 .data-box:after { background-color: #bca446; }
  .timeline li .category08 .data-box:before,
  .timeline li .category08 .data-box:after { background-color: #2775be; }
  .timeline li .category-all .data-box:before,
  .timeline li .category-all .data-box:after { background-color: #7b9915; }

  .timeline-balloon {
    @include font-size(11);
   position: relative; display: table-cell;/*  float: right;  */width: 85%; min-height: 55px; padding: 8px; background: #fff; z-index: 0; }
  .timeline-balloon .category-title {
    @include font-size(10);
   display: inline-block; margin-bottom: 3px; padding: 2px 4px;
    // font-size: 1rem;
   color: #fff; background-color: #4f952b; }
  .timeline-balloon .comment p { margin-top: 5px; }
  .timeline-balloon .comment .add-tx { display: none; }
  .timeline-balloon .comment .expand-open { display: block; margin-top: 5px; }
  .timeline-balloon .comment .expand-open.add-close:before { content:"\e904"; font-family: 'icomoon'; font-size: 1.4rem; vertical-align: middle; line-height: 1; }

  .timeline li .category01 { border-left-color: #008c99; }
  .timeline li .category01 .category-title { background-color: #008c99; }
  .timeline li .category02 { border-left-color: #f56e9d; }
  .timeline li .category02 .category-title { background-color: #f56e9d; }
  .timeline li .category03 { border-left-color: #df6feb; }
  .timeline li .category03 .category-title { background-color: #df6feb; }
  .timeline li .category04 { border-left-color: #826ef5; }
  .timeline li .category04 .category-title { background-color: #826ef5; }
  .timeline li .category05 { border-left-color: #6ebff5; }
  .timeline li .category05 .category-title { background-color: #6ebff5; }
  .timeline li .category06 { border-left-color: #4dab8a; }
  .timeline li .category06 .category-title { background-color: #4dab8a; }
  .timeline li .category07 { border-left-color: #bca446; }
  .timeline li .category07 .category-title { background-color: #bca446; }
  .timeline li .category08 { border-left-color: #2775be; }
  .timeline li .category08 .category-title { background-color: #2775be; }
  .timeline li .category-all { border-left-color: #7b9915; }
  .timeline li .category-all .category-title { background-color: #7b9915; }

  .timeline li .category-facility-box { position: absolute; left: -29px; top: 50%; margin-left: -1.5rem; margin-top: -1.5rem; padding: 4px; line-height: 1; background-color: #7b9915; border-radius: 50%; z-index: 9999; }
  .timeline li .category-facility-box i { font-size: 2.5rem; color: #fff; line-height: 1; }

  .startline { position: relative; padding-left: 56px; padding-top: 1px; padding-bottom: 20px; color: #666; font-family: arial; font-weight: bold; }
  .startline:before { content: ""; display: block; position: absolute; left: 28px; top: 0; width: 20px; height: 20px; background-color: #fff; border: 3px solid #ddd; border-radius: 50%; }


  .timeline {
    list-style: none;
    margin: 0 0 0 calc-rem(3);
    padding: calc-rem(8) 0;

    .timeline-balloon-toggle-contents {
      color: #449dff;
    }

    .timeline-link {
      color: #449dff;
    }
  }

  .startline {
    padding-left: calc-rem(20);

    &::before {
      left: calc-rem(-5);
    }
  }
}

.sid-detail ul[rn-carousel] {
  >li {
    position: relative;
    margin-left: -100%;
    width: 100%;

    &:first-child {
      margin-left: 0;
    }
  }
}

.detail-carousel {
  display: flex;
  height: 100%;
}
