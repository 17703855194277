/*---------------------------------
　モーダル
---------------------------------*/

/*
　mixinの定義
---------------------------------*/
@mixin animationValue($name, $duration, $function, $delay, $count, $state) {
  animation: $name $duration+s $function $delay+s $count $state;
}

/*
　キーフレームの定義
---------------------------------*/
@keyframes animationOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animationSlide {
  0% {
    transform: translate3d(70px, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/
%uModal {
  line-height: 1.4;
  position: fixed;
  z-index: 10005000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  overflow-x: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  width: 100%;
  animation: animationOpacity .5s ease forwards;
  word-break: break-all;
  opacity: 0;
  outline: 0;
  background: rgba(34, 34, 34, .96);
}

%uModal__clickableListItem {
  display: flex;
  min-height: 58px;
  padding: 0 16px;
  transform: translate3d(70px, 0, 0);
  opacity: 0;
  border: 1px solid #383838;
  border-radius: 4px;
  background: linear-gradient(to right, #333 0%, #3b3b3b 49%, #333 100%);

  align-items: center;

  &:active {
    background: linear-gradient(to right, #6a6a6a 0%, #555 49%, #666 100%);
  }

  &:first-child {
    margin: 0 !important;
  }

  &:not(:first-child) {
    margin: 4px 0 0 !important;
  }
}

%uModal__formText {
  position: relative;
}

%uModal__formTextPrepend {
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  top: 0;
  display: flex;
  width: 40px;
  height: 40px;
  pointer-events: none;
  color: black(tertiary);

  justify-content: center;
  align-items: center;
}

%uModal__formSelect {
  position: relative;

  &::after {
    position: absolute;
    top: 16px;
    right: 14px;
    display: block;
    width: 9px;
    height: 9px;
    content: '';
    pointer-events: none;
    border-top: 7px solid #0d52a2;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}

%uModal__formFieldFocus {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all .2s ease;
  pointer-events: none;
  border-bottom: 2px solid #2a66b7;
}

%uModal__formConditionToggle {
  position: relative;
  display: flex;
  width: 100%;
  height: 44px;
  padding: 4px;
  border-radius: 22px;
}

/*
　モーダル
---------------------------------*/
.uModal {
  @extend %uModal;

  &__inner {
    height: 100%;
  }

  /*
  　ヘッダー
  ---------------------------------*/
  &__head {
    padding: 48px 10px 28px;

    &--secondary {
      position: relative;
      display: flex;
      padding: 38px 15px 12px 24px;

      justify-content: space-between;
    }
  }

  &__heading {
    font-size: 17px;
    font-weight: 700;
    margin: 0 0 30px !important;
    padding: 0 16px;
    color: white(primary);

    &--secondary {
      font-size: 21px;
      font-weight: 700;
      margin: 0 !important;
      color: white(primary);
    }
  }

  &__subHeading {
    font-size: 14px;
    position: absolute;
    top: 20px;
    left: 24px;
    color: white(primary);
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 58px;
    height: 58px;
    margin: 0 !important;
    cursor: pointer;
    color: white(primary);

    &::before {
      position: absolute;
      top: 27px;
      left: 18px;
      display: block;
      width: 22px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid white(primary);
    }

    &::after {
      position: absolute;
      top: 27px;
      left: 18px;
      display: block;
      width: 22px;
      content: '';
      transform: rotate(-45deg);
      border-top: 2px solid white(primary);
    }

    &--secondary {
      position: relative;
      width: 30px;
      height: 30px;
      margin: 0 !important;
      cursor: pointer;
      color: white(primary);

      &::before {
        position: absolute;
        top: 12px;
        left: 3px;
        display: block;
        width: 26px;
        content: '';
        transform: rotate(45deg);
        border-top: 2px solid white(primary);
      }

      &::after {
        position: absolute;
        top: 12px;
        left: 3px;
        display: block;
        width: 26px;
        content: '';
        transform: rotate(-45deg);
        border-top: 2px solid white(primary);
      }
    }
  }

  /*
  　スティッキーヘッダー
  ---------------------------------*/
  &__stickyHeader {
    position: fixed;
    z-index: 9999;
    top: -100px;
    left: 0;
    visibility: hidden;
    width: 100%;
    padding: 20px 10px 15px;
    transition: all .5s ease;
    opacity: 0;
    background: rgba(31, 31, 31, .96);
    box-shadow: 0 3px 16px rgba(0, 0, 0, .32);

    &.is-fixed {
      top: 0;
      visibility: visible;
      opacity: 1;
    }

    &.is-focus {
      padding: 48px 10px 28px;

      .uModal {
        &__heading {
          margin: 0 0 30px !important;
        }
      }
    }

    .uModal {
      &__heading {
        margin: 0 0 16px !important;
      }
    }
  }

  /*
  　検索ボックス
  ---------------------------------*/
  &__search {
    position: relative;

    &Body {
      font-size: 14px;
      font-weight: 400;
      line-height: 44px;
      display: block;
      width: 100%;
      height: 44px;
      padding: 0 50px 0 20px;
      transition: all .2s ease;
      color: white(primary);
      border: 1px solid #444;
      border-radius: 22px;
      outline: 0;
      background: linear-gradient(to right, #2f2f2f, #2b2b2b);
      box-shadow: none;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;

      &::placeholder {
        color: white(tertiary) !important;
      }

      &::-webkit-input-placeholder {
        color: white(tertiary) !important;
      }
    }

    .icon-s-search {
      position: absolute;
      top: 0;
      right: 18px;
      height: 44px;
      padding: 0;
      color: white(primary);
      border: none;
      outline: 0;
      background: none;

      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
    }
  }

  /*
  　本体
  ---------------------------------*/
  &__body {
    &--secondary {
      min-height: calc(100% - 80px);
      padding: 0 0 73px;
      border-radius: 16px 16px 0 0;
      background: white(primary);
    }
  }

  /*
  　クリッカブルリスト
  ---------------------------------*/
  &__clickableList {
    &Items {
      padding: 0 10px 10px;
    }

    &Item {
      @extend %uModal__clickableListItem;

      &--justify {
        @extend %uModal__clickableListItem;
        justify-content: space-between;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            @include animationValue(animationSlide, 0.5, ease, $i * 0.1, 1, forwards);
          }
        }

        &:nth-child(n + 21) {
          @include animationValue(animationSlide, 0.5, ease, 2.1, 1, forwards);
        }
      }
    }

    &Cell {
      &--width60 {
        width: 60px;
        margin: 0 0 0 10px !important;

        flex-shrink: 0;
      }
    }

    &Text {
      font-size: 15px;
      line-height: 1.2;
      margin: 0 !important;
      color: white(primary);

      &--size13 {
        font-size: 13px;
        margin: 0 !important;
        color: white(primary);
      }
    }
  }

  /*
  　エラーメッセージ
  ---------------------------------*/
  &__errorMessage {
    visibility: hidden;
    transition: all .5s ease;
    opacity: 0;

    &--show {
      visibility: visible;
      transition: all .5s ease;
      opacity: 1;

      .uModal {
        &__errorMessage {
          &Inner {
            margin: 10px 26px !important;
            padding: 0 0 0 36px;
          }

          &Heading {
            font-size: 17px;
            position: relative;
            color: white(primary);

            .icon-m-help-outlined {
              position: absolute;
              top: 0;
              left: -35px;
              color: rgba(255, 255, 255, .62);
            }
          }

          &Text {
            font-size: 14px;
            color: rgba(255, 255, 255, .62);
          }
        }
      }
    }
  }

  /*
  　タイトル
  ---------------------------------*/
  &__title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.5;
    margin: 0 0 10px !important;
    color: black(primary);

    &:not(:first-child) {
      margin-top: 38px !important;
    }
  }

  /*
  　テキスト
  ---------------------------------*/
  &__text {
    font-size: 14px;
    line-height: 1.75;
    color: black(primary);
  }

  /*
  　ボックス
  ---------------------------------*/
  &__box {
    margin: 0 -8px !important;
    padding: 20px 24px;
    border-radius: 8px;
    background: #f0f0f0;

    .uButton,
    [class^="uButton--"] {
      width: 100%;

      &:not(:first-child) {
        margin: 10px 0 0 !important;
      }
    }

    &--secondary {
      margin: 27px -8px 0 !important;
      padding: 20px 24px;
      border-radius: 8px;
      background: #f0f0f0;

      .uButton,
      [class^="uButton--"] {
        width: 100%;

        &:not(:first-child) {
          margin: 10px 0 0 !important;
        }
      }
    }

    &Buttons {
      margin: 0 -10px !important;

      &:not(:first-child) {
        margin: 19px -8px 0 !important;
      }
    }

    &List {
      color: black(primary);

      &Item {
        display: flex;

        &:not(:first-child) {
          margin: 3px 0 0 !important;
        }
      }

      &Heading {
        font-size: 14px;
        font-weight: 700;
        width: 112px;
        margin: 0 !important;
        padding: 0 10px 0 0;
      }

      &Text {
        font-size: 14px;
        margin: 0 !important;
      }
    }
  }

  /*
  　フォーム
  ---------------------------------*/
  &__form {
    &Help {
      padding: 31px 24px;
    }

    /*
    　フォーム - 条件
    ---------------------------------*/
    &Condition {
      padding: 16px 16px 24px;

      &Head {

        &--toggle {
          margin: 23px 0 43px !important;
          padding: 0 16px;
        }

        &--right {
          display: flex;
          margin: 0 0 18px !important;

          justify-content: flex-end;
        }
      }

      &Toggle {
        background: #ddd;

        @extend %uModal__formConditionToggle;

        &--width157 {
          width: 157px;
          margin: 0 0 0 auto !important;
          background: #ddd;

          @extend %uModal__formConditionToggle;
        }

        &--head {
          background: #eee;

          @extend %uModal__formConditionToggle;
        }

        &Backdrop {
          position: absolute;
          z-index: 1;
          top: 4px;
          left: 4px;
          width: calc(50% - 7px);
          height: calc(100% - 8px);
          transition: all .2s ease;
          border: 1px solid rgba(255, 255, 255, .4);
          border-radius: 18px;
          background: linear-gradient(to right, #fff, #f2f2f2);
          box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

          &--right {
            position: absolute;
            z-index: 1;
            top: 4px;
            left: calc(50% + 3px);
            width: calc(50% - 7px);
            height: calc(100% - 8px);
            transition: all .2s ease;
            border: 1px solid rgba(255, 255, 255, .4);
            border-radius: 18px;
            background: linear-gradient(to right, #fff, #f2f2f2);
            box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
          }
        }

        &Button {
          z-index: 2;
          display: flex;
          width: 100%;
          margin: 0 !important;

          align-items: center;
          justify-content: center;

          &:last-child {
            margin: 0 0 0 4px !important;
          }
        }

        &Text {
          font-size: 14px;
          font-weight: 400;
          transition: all .2s ease;
          color: black(secondary);

          &--active {
            font-size: 14px;
            font-weight: 700;
            transition: all .2s ease;
            color: #0d52a2;
          }
        }

        &Body {
          display: none;
        }
      }

      &Items {
        counter-reset: conditionNumber;
      }

      &Item {
        &:not(:first-child) {
          margin: 24px 0 0 !important;
        }

        &Head {
          display: flex;
          min-height: 29px;
          margin: 0 0 10px !important;
          padding: 0 17px;

          align-items: center;
          justify-content: space-between;
        }

        &Body {
          padding: 16px;
          border-radius: 8px;
          background: #eee;
        }
      }

      &Label {
        font-size: 14px;
        font-weight: 700;
        margin: 0 !important;
        color: black(secondary);

        flex-shrink: 0;

        &::after {
          font-size: 21px;
          margin: 0 0 0 8px !important;
          content: counter(conditionNumber);
          counter-increment: conditionNumber;
        }

        &--secondary {
          font-size: 14px;
          font-weight: 700;
          margin: 0 !important;
          color: black(secondary);

          flex-shrink: 0;
        }
      }

      &Field {
        position: relative;
        display: flex;

        &:not(:first-child) {
          margin: 16px 0 0 !important;
        }

        &Focus {
          z-index: 0;

          @extend %uModal__formFieldFocus;
        }

        &Heading {
          font-size: 14px;
          font-weight: 700;
          display: flex;
          width: 50px;
          margin: 0 !important;
          padding: 0 10px 0 0;
          color: black(secondary);

          flex-shrink: 0;
          align-items: center;

          &+.uModal__formConditionSelectMultiple {
            width: calc(100% - 100px) !important;
          }
        }

        &Label {
          font-size: 13px;
          font-weight: 400;
          position: absolute;
          top: -10px;
          left: 8px;
          pointer-events: none;
          color: black(tertiary);
        }

        &Message {
          font-size: 13px;
          font-weight: 700;
          line-height: 1.3;
          display: inline-flex;
          padding: 4px 8px;
          color: white(primary);
          border-radius: 0 0 4px 4px;
          background: #b2001a;
        }
      }

      &Text {
        @extend %uModal__formText;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;
        height: 100%;

        &+.uModal__formConditionText {
          margin: 0 0 0 10px !important;
        }

        &Prepend {
          left: 0;
          color: rgba(33, 33, 33, .47);

          @extend %uModal__formTextPrepend;

          &~.uModal {
            &__form {
              &Condition {
                &Text {
                  &Body {
                    padding-left: 40px !important;
                  }
                }
              }
            }
          }
        }

        &Append {
          right: 0;
          color: rgba(33, 33, 33, .47);

          @extend %uModal__formTextPrepend;

          &~.uModal {
            &__form {
              &Condition {
                &Text {
                  &Body {
                    padding-right: 40px !important;
                  }
                }
              }
            }
          }
        }

        &Body {
          font-size: 14px;
          font-weight: 400;
          line-height: 40px;
          display: block;
          width: 100%;
          height: 40px;
          padding: 0 8px;
          transition: border, background .2s ease;
          color: black(primary);
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px 4px 0 0;
          outline: 0;
          background: white(primary);
          box-shadow: none;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &:disabled {
            pointer-events: none;
            opacity: 1;
          }

          &::placeholder {
            color: black(quaternary) !important;
          }

          &:-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-ms-input-placeholder {
            color: black(quaternary) !important;
          }

          &::-webkit-input-placeholder {
            color: black(quaternary) !important;
          }

          &:focus {
            &+.uModal {
              &__form {
                &Condition {
                  &Field {
                    &Focus {
                      z-index: 0;
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          &.is-error {
            border-bottom: 1px solid #bc0822 !important;
            background: #faecee !important;

            &:focus {
              &+.uModal {
                &__form {
                  &Condition {
                    &Field {
                      &Focus {
                        width: 0;
                      }
                    }
                  }
                }
              }
            }
          }

          &--disabled {
            font-size: 14px;
            font-weight: 400;
            line-height: 40px;
            display: block;
            width: 100%;
            height: 40px;
            padding: 0 8px;
            transition: border, background .2s ease;
            pointer-events: none;
            color: #e7e7e7;
            border: none;
            border-bottom: 1px solid rgba(204, 204, 204, 0.32);
            border-radius: 4px 4px 0 0;
            outline: 0;
            background: #e7e7e7;
            box-shadow: none;

            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;

            &::placeholder {
              color: #e7e7e7 !important;
            }

            &:-ms-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::-ms-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::-webkit-input-placeholder {
              color: #e7e7e7 !important;
            }

            &::selection {
              color: transparent;
            }
          }
        }
      }

      &Select {
        @extend %uModal__formSelect;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 100%;

        &+.uModal__formConditionSelect {
          margin: 0 0 0 10px !important;
        }

        &Append {
          right: 35px;
          color: rgba(33, 33, 33, 0.47);

          @extend %uModal__formTextPrepend;
        }

        &Body {
          font-size: 14px;
          font-weight: 400;
          line-height: 40px;
          width: 100%;
          height: 40px;
          padding: 0 30px 0 12px;
          transition: all .2s ease;
          color: black(primary);
          border: none;
          border-bottom: 1px solid #ccc;
          border-radius: 4px 4px 0 0;
          outline: 0;
          background: white(primary);
          box-shadow: none;

          appearance: none;
          -moz-appearance: none;
          -webkit-appearance: none;

          &:focus {
            &+.uModal {
              &__form {
                &Condition {
                  &Field {
                    &Focus {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }

          &.is-error {
            border-bottom: 1px solid #bc0822 !important;
            background: #faecee !important;

            &:focus {
              &+.uModal {
                &__form {
                  &Condition {
                    &Field {
                      &Focus {
                        width: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      &SelectMultiple {
        width: 100%;

        @extend %uModal__formSelect;

        &::after {
          top: calc(50% - 4px);
        }

        &Body {
          overflow: hidden;
          width: 100%;
          height: 40px;
          padding: 9px 30px 7px 8px;
          cursor: pointer;
          transition: all .2s ease;
          white-space: nowrap;
          text-overflow: ellipsis;
          border-bottom: 1px solid #ccc;
          border-radius: 2px 2px 0 0;
          background: white(primary);
          margin-bottom: 0px !important;

          &Text {
            font-size: 14px !important;
            font-weight: 400;
            line-height: 1.7;
            color: black(primary);
          }

          &::selection {
            background: none;
          }
        }

        &Placeholder {
          font-size: 14px;
          line-height: 1.7;
          position: absolute;
          top: 0;
          left: 0;
          padding: 9px 8px 7px 8px;
          pointer-events: none;
          color: rgba(33, 33, 33, 0.47);
        }

        &Focus {
          @extend %uModal__formFieldFocus;

          &--focus {
            width: 100%;

            @extend %uModal__formFieldFocus;
          }
        }

        &Backdrop {
          display: none;

          &--open {
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        &List {
          position: absolute;
          overflow: hidden;
          height: 0;
          opacity: 0;

          &--open {
            position: absolute;
            z-index: 2;
            top: 40px;
            left: 0;
            visibility: visible;
            width: 100%;
            transition: opacity .4s ease;
            opacity: 1;
            background: white(primary);
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

            &::before {
              position: absolute;
              z-index: 2;
              top: 0;
              left: 0;
              display: block;
              width: calc(100% - 16px);
              height: 20px;
              content: '';
              pointer-events: none;
              background: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
            }

            &::after {
              position: absolute;
              z-index: 2;
              bottom: 0;
              left: 0;
              display: block;
              width: calc(100% - 16px);
              height: 20px;
              content: '';
              pointer-events: none;
              background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
            }
          }
        }

        &Scroll {
          display: flex;
          overflow-y: scroll;
          width: 100%;
          max-height: 220px;
          padding: 12px 8px 0 8px;

          flex-wrap: wrap;
        }

        &Items {
          display: flex;

          flex-wrap: wrap;

          &::after {
            display: block;
            width: 100%;
            height: 20px;
            content: '';
          }
        }

        &Item {
          display: flex !important;
          height: 36px !important;
          margin: 8px 0 0 8px !important;
          cursor: pointer;

          justify-content: flex-start;
          align-items: center;

          &:hover {
            .uModal {
              &__form {
                &Condition {
                  &SelectMultiple {
                    &Item {
                      &Button {
                        color: black(secondary);
                        border: 1px solid white(primary);
                        background: #f2f2f2;
                        box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                        @media (pointer: coarse) {
                          color: black(tertiary);
                          border: 1px solid #ddd;
                          background: #f2f2f2;
                          box-shadow: none;
                        }
                      }

                      &Body {
                        &:checked {
                          &+.uModal {
                            &__form {
                              &Condition {
                                &SelectMultiple {
                                  &Item {
                                    &Button {
                                      font-weight: 700;
                                      color: #0d52a2;
                                      border: 1px solid white(primary);
                                      background: #f2f2f2;
                                      box-shadow: 0px 3px 6px rgba(0, 0, 0, .16);

                                      @media (pointer: coarse) {
                                        font-weight: 700;
                                        color: #0d52a2;
                                        border: 1px solid white(primary);
                                        background: linear-gradient(to right, #fff, #f2f2f2);
                                        box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &Body {
            display: none;

            &:checked {
              &+.uModal {
                &__form {
                  &Condition {
                    &SelectMultiple {
                      &Item {
                        &Button {
                          font-weight: 700;
                          color: #0d52a2;
                          border: 1px solid white(primary);
                          background: linear-gradient(to right, #fff, #f2f2f2);
                          box-shadow: 0px 1px 2px rgba(0, 0, 0, .16);

                          &::before {
                            border-right: 2px solid #0d52a2;
                            border-bottom: 2px solid #0d52a2;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &Button {
            font-size: 13px;
            font-weight: 400;
            position: relative;
            display: flex;
            width: 100%;
            height: 36px;
            padding: 0 13px 0 31px;
            transition: all .2s ease;
            color: black(tertiary);
            border: 1px solid #ddd;
            border-radius: 16px;
            background: #f2f2f2;
            box-shadow: none;

            justify-content: center;
            align-items: center;

            &::before {
              position: absolute;
              top: 10px;
              left: 14px;
              display: block;
              width: 6px;
              height: 11px;
              content: '';
              transition: all .2s ease;
              transform: rotate(45deg);
              border-right: 2px solid rgba(33, 33, 33, .16);
              border-bottom: 2px solid rgba(33, 33, 33, .16);
            }

            &::selection {
              background: none;
            }
          }
        }
      }

      &Delete {
        position: relative;
        display: flex;
        width: 20px;
        height: 20px;
        margin: 0 !important;
        color: black(secondary);

        align-items: center;
        justify-content: center;
        flex-shrink: 0;
      }

      &Add {
        display: flex;
        height: 44px;
        margin: 24px 0 0 !important;
        border: 1px dashed #adadad;
        border-radius: 8px;

        align-items: center;

        &Button {
          display: flex;
          padding: 0 0 0 16px;
          cursor: pointer;

          flex-grow: 1;
          align-items: center;
        }

        &Text {
          font-size: 14px;
          display: flex;
          margin: 0 !important;
          color: black(tertiary);

          align-items: center;
          flex-grow: 1;
        }

        &Icon {
          position: relative;
          display: block;
          width: 42px;
          height: 42px;
          margin: 0 !important;

          flex-shrink: 0;

          &::before {
            position: absolute;
            top: calc(50% - 2px);
            left: calc(50% - 7px);
            display: block;
            width: 15px;
            height: 3px;
            content: '';
            background: #0d52a2;
          }

          &::after {
            position: absolute;
            top: calc(50% - 2px);
            left: calc(50% - 7px);
            display: block;
            width: 15px;
            height: 3px;
            content: '';
            transform: rotate(90deg);
            transform-origin: center;
            background: #0d52a2;
          }
        }
      }
    }
  }

  /*
  　フッター
  ---------------------------------*/
  &__foot {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    min-height: 72px;
    padding: 16px 0 env(safe-area-inset-bottom);
    background: #eee;
    box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);

    justify-content: center;

    &--secondary {
      position: relative;
      display: flex;
      flex-direction: row-reverse;
      padding: 0 24px;
      background: white(primary);

      align-items: center;
    }
  }
}