%__category {
  font-size: 13px;
  display: flex;
  height: 24px;
  margin: 0;
  padding: 0 5px;

  align-items: center;
}

.notification {
  &__category {
    &--maintenance {
      color: #305694;
      background: #f2f2f2;

      @extend %__category;
    }

    &--update {
      color: #305694;
      background: #f2f2f2;

      @extend %__category;
    }

    &--incident {
      color: #fff;
      background: #db44ae;

      @extend %__category;
    }

    &--information {
      color: #718623;
      background: #f2f2f2;

      @extend %__category;
    }
  }
}