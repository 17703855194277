/*----------------------------------------
 # Base
----------------------------------------*/

html {
  height: 100%;
}

body {
  @include font-size(12);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-wrap: break-word;
  height: 100%;
  word-wrap: break-word;
  background-color: #fff;
}


/* Image
----------------------------------------*/

img {
	height: auto;
	max-width: 100%;
}


/* Link
----------------------------------------*/

a {
	color: inherit;

	&:hover,
	&:focus {
		color: inherit;
	}
}


/*----------------------------------------
 # Typography
----------------------------------------*/

/* Heading
----------------------------------------*/

%heading {
	font-weight: 700;
	line-height: $line-height-base - .2;
	margin-top: 0;
	margin-bottom: .5em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@extend %heading;
}

// Suffix
[class$="-title"],
[class*="-title "],
[class*="-title-"],
[class$="_title"],
[class*="_title "],
[class*="_title-"],
[class$="-heading"],
[class*="-heading "],
[class*="-heading-"],
[class$="_heading"],
[class*="_heading "],
[class*="_heading-"] {
	@extend %heading;
	font-size: 1em;
}


/* List
----------------------------------------*/

dl {
  margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

dt,
dd {
	line-height: inherit;
}

dd {
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}

ol,
ul {
	margin-top: 0;
	margin-bottom: 1em;
	padding-left: 1.5em;

	&:last-child {
		margin-bottom: 0;
	}
}

// Prefix
[class^="list-"],
[class*=" list-"] {
	@include list-unstyled;
}

// Suffix
[class$="-list"],
[class*="-list "],
[class*="-list-"],
[class$="_list"],
[class*="_list "],
[class*="_list-"] {
	@include list-unstyled;
}


/* Paragraph
----------------------------------------*/

p {
	margin-bottom: 1em;

	&:last-child {
		margin-bottom: 0;
	}
}


/* Table
----------------------------------------*/

table {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }
}
