/* mobile-alert-calving */

.mobile-alert-calving .header-info .header-info-button {
  margin: 0 0.5em 0 0;
}

.mobile-alert-calving .mobile-alert-calving-title {
  &-unconfirmed {
    color: #ffffff;
    background: #4d0000;
    border: 1px solid #9f0024;
    border-radius: 4px;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 0 12px;
    opacity: 1;
    padding: 3.5px 22px;
  }

  &-confirmed {
    color: #fff;
    background: #444444;
    border: 1px solid #444444;
    border-radius: 4px;
    display: inline-block;
    font-size: 14px;
    margin: 0 0 0 12px;
    opacity: 1;
    padding: 3.5px 22px;
  }
}

/* mobile-alert-calving-list-item */

.mobile-alert-calving-list-item .mm-listItem_value .alert-level-label {
  font-weight: 700;
}

.mobile-alert-calving-list-item .mm-listItem_value .alert-level-label.alert-level-high {
  color: #bc0822;
}

.mobile-alert-calving-list-item .mm-listItem_value .alert-level-label.alert-level-low {
  color: #ff9e08;
}

/* mobile-alert-calving-carousel */

.mobile-alert-calving-carousel .module-controller {
  align-items: flex-start;
  height: 7rem;
  padding: 1.2rem;
  z-index: 105000 !important;
}

.mobile-alert-calving-carousel .module-controller .mc-button {
  background-color: #444;
  border: .1rem solid #666;
  border-radius: .6rem;
  padding: 1.1rem 1.2rem;
}

.mobile-alert-calving-carousel .module-controller .mc-button.button-primary {
  background-color: #2a87c1;
  border-color: #2a87c1;
}

.mobile-alert-calving-carousel .module-controller .mc-list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.mobile-alert-calving-carousel .module-controller .mc-listItem {
  display: flex;
  align-items: center;
  padding-left: 1.2rem;
}

.mobile-alert-calving-carousel .module-dialog .md-body.body-frame {
  max-height: 52vh;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.mobile-alert-calving-carousel .module-dialog .confirmed-status-label {
  font-weight: 700;
}

.mobile-alert-calving-carousel .module-dialog .confirmed-status-label.confirmed-status-sign {
  color: #bc0822;
}

.mobile-alert-calving-carousel .module-dialog .confirmed-status-label.confirmed-status-no-sign {
  color: #bbbbbb;
}

.mobile-alert-calving-carousel .module-dialog .alert-level-label {
  font-weight: 700;
}

.mobile-alert-calving-carousel .module-dialog .alert-level-label.alert-level-high {
  color: #bc0822;
}

.mobile-alert-calving-carousel .module-dialog .alert-level-label.alert-level-low {
  color: #ff9e08;
}

.mobile-alert-calving-carousel .module-dialog .confirmed-comment {
  word-break: break-all;
}

.mobile-alert-calving-carousel .module-dialog .carousel-item {
  width: 100%;
}

/* mobile-alert-calving-report-form */

.mobile-alert-calving-report-form.module-form .md-footer .md-button {
  border: none;
  font-size: 14px;
  min-width: 60px;
}

.mobile-alert-calving-report-form.module-form .md-footer .md-button.button-continue {
  background: #f0f0f0;
  border-radius: 4px;
  color: #366678;
  font-weight: 700;
  padding: 6px 2px;

  &:disabled {
    background: #e5e5e5;
    color: #adadad
  }
}
