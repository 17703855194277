/*---------------------------------
　カード
---------------------------------*/

/*
　@extend用のプレースホルダーセレクタ
---------------------------------*/

%uCard__clickable {
  position: absolute;
  z-index: 1;
  top: 0;
  left: -48px;
  display: flex;
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0 !important;
  padding: 0 0 0 16px;
  transition: background .2s ease;

  align-items: center;
}

%uCard__inner {
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding: 12px 10px;
  transition: border .2s ease;
  border-radius: 8px;
  background: white(primary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);
}

/*
　カード
---------------------------------*/
.uCard {
  overflow: hidden;
  border-radius: 8px;
  background: white(primary);
  box-shadow: 0 1px 2px rgba(0, 0, 0, .16);

  &+& {
    margin: 10px 0 0 !important;
  }

  &--unselectable {
    position: relative;
    margin: 0 !important;
    padding: 6px 0;
    transition: all .2s ease;
  }

  &--selectable {
    position: relative;
    margin: 0 -38px 0 38px !important;
    padding: 6px 0;
    transition: all .2s ease;
  }

  &__clickable {
    border-bottom: 1px solid rgba(255, 255, 255, 0);

    @extend %uCard__clickable;

    &--selected {
      border-bottom: 1px solid white(primary);
      background: #e8f0fe;

      @extend %uCard__clickable;
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    border: 2px solid #ccc;
    border-radius: 2px;
    background: white(primary);
  }

  &__checked {
    width: 16px;
    height: 16px;
    background: white(primary);

    .icon-s-check-box-outlined {
      color: #2a66b7;
    }
  }

  &__inner {
    border: 2px solid white(primary);

    @extend %uCard__inner;

    &--selected {
      border: 2px solid #2a66b7;
    }
  }

  &__head {
    display: flex;
    margin: 0 0 10px !important;

    &CowNo {
      font-size: 15px;
      font-weight: 700;
      width: 100px;
      margin: 0 !important;
      padding: 6px;
      color: black(primary);

      flex-shrink: 0;
    }

    &Right {
      position: relative;
      display: flex;
      width: 100%;
      padding: 6px 0 6px 16px;
      border-left: 1px solid #ddd;

      .uCard__headCowLabel {
        margin: -6px 0 0 20px !important;
      }

      &--directionColumn {
        overflow: hidden;
        flex-direction: column;

        .uCard__headCowGroupName {
          overflow: hidden;
          margin: 0 0 5px !important;
          white-space: nowrap;
          text-overflow: ellipsis;

          &--noCowLabel {
            margin: 0 !important;
          }
        }

        .uCard__headCowLabel {
          margin: -6px 0 0 !important;
          max-width: 100%;
        }
      }
    }

    &CowGroupName {
      font-size: 15px;
      font-weight: 700;
      margin: 0 !important;
      white-space: nowrap;
      word-break: break-all;
      color: black(primary);

      &--hidden {
        font-size: 15px;
        font-weight: 700;
        position: absolute;
        visibility: hidden;
        margin: 0 !important;
        white-space: nowrap;
        color: black(primary);
      }
    }

    &CowLabel {
      &Items {
        display: inline-flex;

        flex-wrap: wrap;
      }

      &Item {
        font-size: 13px;
        line-height: 1;
        margin: 6px 8px 0 0 !important;
        padding: 4px 7px;
        color: black(primary);
        border: 1px solid #ccc;
      }
    }
  }
}