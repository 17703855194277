/*---------------------------------
　リスト
---------------------------------*/

/*
　リスト
---------------------------------*/
.uList {
  &--secondary {

    &+.uList,
    &+[class^="uList--"] {
      margin: 6px 0 0;
    }

    .uList__column,
    [class^="uList__column--"] {
      min-height: 44px;
      padding: 7px 0 7px 7px;
      border-radius: 8px;
      background: #f2f2f2;
    }
  }

  &__row {
    display: flex;

    &:not(:first-child) {
      border-top: 1px solid #eee;
    }
  }

  &__column {
    display: flex;
    width: 100%;
    height: 50px;
    padding: 0 10px;
    background: white(primary);

    align-items: center;

    &:not(:first-child) {
      border-left: 1px solid #eee;
    }

    &--wrap {
      display: flex;
      width: 100%;
      min-height: 50px;
      padding: 10px 0 10px 10px;
      background: white(primary);

      align-items: center;
      flex-wrap: wrap;

      &:not(:first-child) {
        border-left: 1px solid #eee;
      }
    }

    &Inner {
      display: flex;
      margin: 0 16px 0 0 !important;
    }
  }

  &__button {
    display: flex;
    width: 34px;
    height: 36px;
    margin: 0 0 0 10px !important;
    background: white(primary);

    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    .uList__columnInner+& {
      border-radius: 0 4px 4px 0;
    }

    &Link {
      display: flex;
      width: 34px;
      height: 34px;
      transition: all .1s linear;
      text-decoration: none !important;
      color: black(secondary);
      border-radius: 50%;

      align-items: center;
      justify-content: center;
    }
  }

  &__heading {
    font-size: 14px;
    font-weight: 400;
    display: flex;
    height: 30px;
    margin: 0 10px 0 0 !important;
    color: black(secondary);

    align-items: center;
    flex-shrink: 0;

    &--width106 {
      font-size: 14px;
      font-weight: 400;
      display: flex;
      width: 106px;
      height: 30px;
      margin: 0 !important;
      color: black(secondary);

      align-items: center;
      flex-shrink: 0;
    }
  }

  &__body {
    display: flex;
    height: 30px;
    color: black(primary);

    align-items: center;


    &--grow {
      display: flex;
      min-height: 30px;
      color: black(primary);

      flex-grow: 1;
      align-items: center;

    }

    &Num {
      font-size: 15px;
      font-weight: 700;
      margin: 0 !important;
      text-align: right;

      &+.uList__bodyText,
      &+[class^="uList__bodyText--"] {
        margin: 0 0 0 8px !important;
      }

      &--grow {
        font-size: 15px;
        font-weight: 700;
        margin: 0 !important;
        text-align: right;

        flex-grow: 1;

        &+.uList__bodyText,
        &+[class^="uList__bodyText--"] {
          margin: 0 0 0 8px !important;
        }
      }

      &--width50 {
        font-size: 15px;
        font-weight: 700;
        width: 50px;
        margin: 0 !important;
        text-align: right;

        &+.uList__bodyText,
        &+[class^="uList__bodyText--"] {
          margin: 0 0 0 8px !important;
        }
      }
    }

    &Text {
      font-size: 14px;

      &--width37 {
        font-size: 14px;
        width: 37px;

        flex-shrink: 0;
      }

      &--secondary {
        font-size: 15px;
        font-weight: 700;
        margin: 0 !important;
        word-break: break-all;
      }
    }
  }

  &__rank {
    font-size: 14px;
    font-weight: 700;
    display: flex;
    width: 34px;
    height: 29px;
    margin: 0 0 0 10px !important;
    color: #043d67;
    border-radius: 4px;
    background: #eee;

    align-items: center;
    justify-content: center;
  }
}