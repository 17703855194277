/*----------------------------------------
 # Unique Style of 'Home'
----------------------------------------*/

// Prefix
[class^="home-"] {
	position: relative;
}


/* Header
----------------------------------------*/

.sid-home .layout-header {
  border-bottom-color: #fff;

  .lh-title_object {
    padding-top: calc-rem(4);
  }
}


/* Main
----------------------------------------*/

.sid-home .layout-main {
  background: url('../images/mobile/unique/home/main_texture.jpg') center center no-repeat;
  background-size: cover;
}


/* Search
----------------------------------------*/

.home-search {
  background-color: rgba(#ececec, .9);
  border: calc-rem(6) solid rgba(#ececec, .9);
  display: table;
  margin: calc-rem(100) auto calc-rem(90);
}

.home-search .hs- {
  &field {
    border-color: transparent;
    border-radius: calc-rem(3);
    box-shadow: none;
    height: calc-vw(41);
     max-height: calc-rem(41);
    font-size: calc-rem(16);
    margin: auto;
    padding-right: calc-rem(26);
    width: calc-vw(250);

    &::-webkit-input-placeholder {
      @include font-size(15, 16);
      color: #888;
    }
  }

  &button {
    @include font-size(30);
    background: transparent;
    border: none;
    color: #777;
    display: flex;
     align-items: center;
    padding: 0;
    position: absolute;
     top: 50%;
     right: calc-rem(5);
    transform: translateY(-50%);
  }
}


/* Menu
----------------------------------------*/

.home-menu {
  padding: 0 calc-rem(8);
}

.home-menu .hm- {
  &title {
    @include font-size(17);
    background-color: rgba(#fff, .9);
    margin-bottom: 0;
    padding: calc-rem(15) calc-rem(19);
  }

  &list {
    background-color: rgba(#fff, .9);

    &Item {
      @include font-size(13);
      border-bottom: calc-rem(1) solid #ccc;
      display: flex;
       align-items: center;
       flex-wrap: wrap;
      line-height: 1.4;
      position: relative;

      > a {
        display: inherit;
         align-items: inherit;
        min-height: calc-rem(50);
        padding: 0 calc-rem(30) 0 calc-rem(10);
        position: relative;
        width: 100%;

        &::before {
          @extend [class^="icon-"];
          @include font-size(0, 20);
          background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23333333%3B%20stroke%3A%23333%3B%20stroke-width%3A2%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16.783%2C10.35c-0.5-0.5-1.3-0.5-1.8%2C0s-0.5%2C1.3%2C0%2C1.8l7.9%2C7.8l-7.9%2C7.9c-0.5%2C0.5-0.5%2C1.3%2C0%2C1.8s1.3%2C0.5%2C1.8%2C0%20l8.8-8.8c0.5-0.5%2C0.5-1.3%2C0-1.8L16.783%2C10.35z%22%2F%3E%3C%2Fsvg%3E');
          content: "";
          height: calc-rem(20);
          position: absolute;
           top: 50%;
           right: calc-rem(5);
          transform: translateY(-50%);
          width: calc-rem(20);
        }

        &:hover {
          text-decoration: none;
        }
      }

      &_icon {
        @include font-size(20);
        flex: 0 0 auto;
        margin-right: calc-rem(10);
      }

      &_number {
        display: inline-flex;
         align-items: center;
         flex: 0 0 auto;
        margin-left: auto;

        b {
          @include font-size(21);
          margin: 0 .25em;
        }
      }

      &_state {
        @include font-size(15);

        strong {
          margin: 0 .25em;
        }
      }

      &.is-disabled {
        color: #ccc;

        > a {
          pointer-events: none;

          &::before {
            background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23ccc%3B%20stroke%3A%23ccc%3B%20stroke-width%3A2%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16.783%2C10.35c-0.5-0.5-1.3-0.5-1.8%2C0s-0.5%2C1.3%2C0%2C1.8l7.9%2C7.8l-7.9%2C7.9c-0.5%2C0.5-0.5%2C1.3%2C0%2C1.8s1.3%2C0.5%2C1.8%2C0%20l8.8-8.8c0.5-0.5%2C0.5-1.3%2C0-1.8L16.783%2C10.35z%22%2F%3E%3C%2Fsvg%3E');
          }
        }
      }

      &.is-highlight {
        background-color: #fff;

        .hm-listItem_icon,
        .hm-listItem_number b,
        .hm-listItem_state strong {
          color: #c02c94;
        }
      }
    }
  }
}

/* Header
----------------------------------------*/
.sid-home .layout-header {
  background-color: rgba(0, 0, 0, .5);
  border-bottom: none;
}
.sid-home .layout-header .lh-title_object {
  display: none;
}

/* Logo
----------------------------------------*/
.home-logo {
  background: rgba(0, 0, 0, .8);
  border-radius: 50%;
  display: flex;
   align-items: center;
   justify-content: center;
  height: 12.3rem;
  margin: 11.9rem auto 6.7rem;
  width: 12.3rem;
}

.home-logo .hl-object {
  width: 4.8rem;
}

/* Utility
----------------------------------------*/
.home-utility {
  display: flex;
  margin-top: 31rem;
  margin-bottom: 1.6rem;
  padding-right: .8rem;
  padding-left: .8rem;
}

.home-logo ~ .home-utility {
  margin-top: 0;
}

.home-utility .hu-button {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: .2rem;
  display: inline-flex;
   align-items: center;
   flex-wrap: wrap;
   justify-content: center;
  padding: .6rem;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.home-utility .hu-button_label {
  font-size: 3.733333333vw;
  display: block;
  line-height: 1.2;
  margin-bottom: .6rem;
  text-align: center;
  width: 100%;
}
  @media (min-width: 569px) {
    .home-utility .hu-button_label {
      font-size: 1.4rem; } }

.home-utility .hu-button [class^="icon-"] {
  font-size: 14.4vw;
}
  @media (min-width: 569px) {
    .home-utility .hu-button [class^="icon-"] {
      font-size: 5.4rem; } }

.home-utility .hu-button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.home-utility .hu-button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.home-utility .hu-button:last-child::before {
  background-color: #aaa;
  content: "";
  position: absolute;
   top: .8rem;
   bottom: .8rem;
   left: 0;
  width: .1rem;
}

.home .hamburger-menu-button {
  border-top-color: #fff;
  top: 18px;
}

.home .hamburger-menu-button::before,
.home .hamburger-menu-button::after {
  background-color: #fff;
}

/*----------------------------------------
 #17
----------------------------------------*/
/* Header
----------------------------------------*/
.hamburger-menu-content {
  background: rgba(34, 34, 34, .96);
}

.hamburger-menu-close-wrapper .hamburger-menu-close {
  background: none;
}

.sid-home .layout-header {
  background-color: rgba(31, 31, 31, .9);
}

.sid-home .layout-header .lh-title_object {
  display: none;
}

/* Main
----------------------------------------*/
.sid-home .layout-main {
  background: #F2F2F2;
  padding: 0;

  .is-stickyAlertNotification &,
  .is-stickyAlertDysstasia & {
    padding-top: 88px;
  }

  .is-stickyAlertNotification.is-stickyAlertDysstasia & {
    padding-top: 176px;
  }
}

.sid-home .layout-main > .container {
  background: #1F1F1F;
  padding-top: 5rem; }

/* Footer
----------------------------------------*/
.nav-footer {
  background-color: rgba(242, 242, 242, .96);
  border-top: .1rem solid rgba(221, 221, 221, .96);
  padding-bottom: env(safe-area-inset-bottom);
  position: fixed;
   bottom: 0;
   left: 0;
  width: 100%;
  z-index: 100000;
}

.nav-footer .nf-list {
  display: flex;
}

.nav-footer .nf-listItem {
  color: rgba(33, 33, 33, 0.62);
  display: inline-flex;
   align-items: center;
   flex-direction: column;
   justify-content: flex-end;
  font-size: 1.2rem;
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  width: 100%;
}

.nav-footer .nf-listItem > a,
.nav-footer .nf-listItem > button {
  -webkit-appearance: none;
  background: none;
  border: none;
  display: inherit;
   align-items: inherit;
   flex-direction: inherit;
   justify-content: inherit;
  height: 5.6rem;
  padding: 0;
  width: 100%;
  text-decoration: none;
}

.nav-footer .nf-listItem_icon {
  margin-bottom: .4rem;
}

.nav-footer .nf-listItem_icon [class*="icon-"] {
  display: flex;
  width: 24px;
  height: 24px;

  align-items: center;
  justify-content: center;
}

.nav-footer .nf-listItem_label {
  line-height: 1.2;
  margin-bottom: .5rem;
}

@media (orientation: landscape) {
  .nav-footer .nf-listItem {
    flex-direction: row;
    justify-content: center;
  }

  .nav-footer .nf-listItem_icon {
    margin-right: .4rem;
  }

  .nav-footer .nf-listItem_icon [class*=icon-] {
    margin-bottom: 0;
  }
}

/* Account
----------------------------------------*/
.home-account {
  color: #fff;
  font-size: 4.266666667vw;
  margin-bottom: 2.6rem;
  padding: 3.8rem 2.4rem 0;
}

@media (min-width: 569px) {
  .home-account {
    font-size: 1.6rem;
  }
}

@media screen and (orientation: landscape) {
  .home-account {
    font-size: 1.6rem;
  }
}

.home-account .ha-email {
  color: rgba(255, 255, 255, .7);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.home-account .ha-name {
  font-size: 5.333333333vw;
  line-height: 1.2;
  margin-bottom: .5rem;
}

@media (min-width: 569px) {
  .home-account .ha-name {
    font-size: 2rem;
  }
}

@media screen and (orientation: landscape) {
  .home-account .ha-name {
    font-size: 2rem;
  }
}

/* Content
----------------------------------------*/
.home-content {
  background-color: #F2F2F2;
  padding: 2.8rem .8rem;
  padding-bottom: calc(5.6rem + env(safe-area-inset-bottom));
  height: 100%;
}

.home-content .hc-alert {
  color: rgba(51, 51, 51, .47);
  display: flex;
   justify-content: center;
  font-size: 5.6vw;
  margin-top: 2.6rem;
  margin-bottom: 2.6rem;
}

@media (min-width: 569px) {
  .home-content .hc-alert {
    font-size: 2.1rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-alert {
    font-size: 2.1rem;
  }
}

.home-content .hc-card {
  background-color: #fff;
  border-radius: .4rem;
  box-shadow: 0 .1rem .3rem rgba(0, 0, 0, .16);
  display: flex;
   flex-wrap: wrap;
  font-size: 1.4rem;
  margin-bottom: .8rem;
  padding: 1rem .2rem .6rem .8rem;
  position: relative;
}

.home-content .hc-card > a {
  display: inherit;
   flex-wrap: inherit;
  text-decoration: none;
  width: 100%;
}

.home-content .hc-card > a::before {
  content: "";
  height: 100%;
  position: absolute;
   top: 0;
   left: 0;
  width: 100%;
}

.home-content .hc-card .icon-css-arrow {
  position: absolute;
  right: 10px;
  bottom: 17px;
  display: block;
  width: 7px;
  height: 7px;
  transform: rotate(45deg);
  border-top: 1px solid #000;
  border-right: 1px solid #000;
}

.home-content .hc-card_state {
  display: block;
  font-size: 3.466666667vw;
  font-weight: 700;
  margin-bottom: .8rem;
  width: 100%;
}

@media (min-width: 569px) {
  .home-content .hc-card_state {
    font-size: 1.3rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-card_state {
    font-size: 1.3rem;
  }
}

.home-content .hc-card_number {
  display: block;
}

.home-content .hc-card_number b {
  font-size: 7.466666667vw;
  line-height: 1;
  padding-right: .8rem;
}

@media (min-width: 569px) {
  .home-content .hc-card_number b {
    font-size: 2.8rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-card_number b {
    font-size: 2.8rem;
  }
}

.home-content .hc-card.is-disabled {
  background-color: #EAEAEA;
  pointer-events: none;
}

.home-content .hc-card.is-disabled * {
  color: rgba(33, 33, 33, .38);
}

.home-content .hc-card.is-disabled [class*="icon-"] {
  display: none;
}

.home-content .hc-card.is-highlight .hc-card_number b,
.home-content .hc-card.is-highlight .hc-card_state {
  color: #C02C94;
}

.home-content .hc-date {
  font-size: 5.6vw;
  font-weight: 700;
  position: relative;
}

@media (min-width: 569px) {
  .home-content .hc-date {
    font-size: 2.1rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-date {
    font-size: 2.1rem;
  }
}

.home-content .hc-date_today {
  color: rgba(13%, 13%, 13%, .7);
  font-size: 4vw;
  font-weight: 400;
  position: absolute;
   top: -2rem;
   left: 50%;
  transform: translateX(-50%);
}

@media (min-width: 569px) {
  .home-content .hc-date_today {
    font-size: 1.5rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-date_today {
    font-size: 1.5rem;
  }
}

.home-content .hc-group {
  transition: all .3s ease;
}

.home-content .hc-group .row {
  display: flex;
   flex-wrap: wrap;
  margin-right: -.4rem;
  margin-left: -.4rem;
}

.home-content .hc-group .row::before,
.home-content .hc-group .row::after {
  display: none;
}

.home-content .hc-list {
  background-color: #fff;
  margin: 0 -.8rem 3.2rem;
  width: 100vw;
}

.home-content .hc-list:last-child {
  margin-bottom: 1.6rem;
}

.home-content .hc-listItem {
  font-size: 3.46667vw;
  border-bottom: 0.1rem solid #F2F2F2;
  display: flex;
   align-items: center;
   flex-wrap: wrap;
  line-height: 1.4;
  position: relative;
}

@media (min-width: 569px) {
  .home-content .hc-listItem {
    font-size: 1.3rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-listItem {
    font-size: 1.3rem;
  }
}

.home-content .hc-listItem:last-child {
  border-bottom: none;
}

.home-content .hc-listItem > a {
  display: inherit;
   align-items: inherit;
  min-height: 5.6rem;
  padding: 1rem 1.6rem;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.home-content .hc-listItem .icon-css-arrow {
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 2px 0 8px;
  transform: rotate(45deg);
  border-top: 2px solid #000;
  border-right: 2px solid #000;
}

.home-content .hc-listItem_number {
  display: inline-flex;
   align-items: baseline;
   flex: 0 0 auto;
  margin-left: auto;
  padding-right: 1rem;
}

.home-content .hc-listItem_number b {
  font-size: 6.4vw;
  margin-right: 1rem;
}

@media (min-width: 569px) {
  .home-content .hc-listItem_number b {
    font-size: 2.4rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-listItem_number b {
    font-size: 2.4rem;
  }
}

.home-content .hc-listItem_state {
  font-size: 4vw;
}

@media (min-width: 569px) {
  .home-content .hc-listItem_state {
    font-size: 1.5rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-listItem_state {
    font-size: 1.5rem;
  }
}

.home-content .hc-listItem_state strong {
  margin: 0 .25em;
}

.home-content .hc-list[data-style="nest"] {
  border-top: 0.1rem solid #F2F2F2;
  margin: 0;
  padding: 0 1.6rem 0 2.4rem;
}

.home-content .hc-list[data-style="nest"] .hc-listItem > a {
  min-height: 5.4rem;
  padding-right: 0;
  padding-left: 1rem;
}

.home-content .hc-list[data-style="nest"] .hc-listItem_number b {
  font-size: 5.6vw;
  font-weight: 500;
}

@media (min-width: 569px) {
  .home-content .hc-list[data-style="nest"] .hc-listItem_number b {
    font-size: 2.1rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-list[data-style="nest"] .hc-listItem_number b {
    font-size: 2.1rem;
  }
}

.home-content .hc-list[data-style="nest"] .hc-listItem_state {
  font-size: 3.733333333vw;
}

@media (min-width: 569px) {
  .home-content .hc-list[data-style="nest"] .hc-listItem_state {
    font-size: 1.4rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-list[data-style="nest"] .hc-listItem_state {
    font-size: 1.4rem;
  }
}

.home-content .hc-pager {
  background-color: #F2F2F2;
  display: flex;
   align-items: center;
   justify-content: space-between;
  margin: -3.2rem -.8rem 0;
  padding: 3.6rem .8rem 1.6rem;
  position: static;
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
}

.home-content .hc-pager_button {
  position: relative;
  background-color: #fff;
  border-radius: .4rem;
  box-shadow: 0 .1rem .3rem rgba(0, 0, 0, .16);
  display: inline-flex;
   align-items: center;
   justify-content: center;
  height: 4.4rem;
  width: 4.4rem;
}

.home-content .hc-pager_button .icon-css-arrow {
  position: absolute;
  top: calc(50% - 5px);
  display: block;
  width: 10px;
  height: 10px;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
}

.home-content .hc-pager_button:first-child .icon-css-arrow {
  left: 18px;
  transform: rotate(-135deg);
}

.home-content .hc-pager_button:not(:first-child) .icon-css-arrow {
  right: 18px;
  transform: rotate(45deg);
}

.home-content .hc-section {
  margin-bottom: 1.6rem;
  padding-right: .4rem;
  padding-left: .4rem;
}

.home-content .hc-section_title {
  color: rgba(33, 33, 33, .62);
  font-size: 4vw;
  font-weight: 400;
  padding-right: .6rem;
  padding-left: .6rem;
  width: 100%;
}

@media (min-width: 569px) {
  .home-content .hc-section_title {
    font-size: 1.5rem;
  }
}

@media screen and (orientation: landscape) {
  .home-content .hc-section_title {
    font-size: 1.5rem;
  }
}

.home-content .hc-section .row {
  margin-right: -.4rem;
  margin-left: -.4rem;
}

.home-content .hc-section [class*="col-"] {
  padding-right: .4rem;
  padding-left: .4rem;
}

.home-content .hc-section .col-xs-1 {
  width: calc(8.33333vw - .4rem);
}

.home-content .hc-section .col-xs-2 {
  width: calc(16.66667vw - .4rem);
}

.home-content .hc-section .col-xs-3 {
  width: calc(25vw - .4rem);
}

.home-content .hc-section .col-xs-4 {
  width: calc(33.33333vw - .4rem);
}

.home-content .hc-section .col-xs-5 {
  width: calc(41.66667vw - .4rem);
}

.home-content .hc-section .col-xs-6 {
  width: calc(50vw - .4rem);
}

/* Logo
----------------------------------------*/
.home-logo {
  background: none;
  border-radius: 0;
  display: inherit;
  height: auto;
  margin: 0;
  position: absolute;
  bottom: 1.2rem;
  left: 2.4rem;
  width: auto;
  z-index: 100000;
}

.home-logo .hl-object {
  width: 2.2rem;
}

/* Tab
----------------------------------------*/
.home-tab {
  background-color: #fff;
  height: 7.2rem;
  position: static;
  position: -webkit-sticky;
  position: sticky;
   top: 5rem;
  z-index: 10;
}

.home-tab .ht-list {
  display: flex;
   align-items: flex-end;
   justify-content: space-between;
  font-size: 4.266666667vw;
  height: inherit;
  padding-right: 2.4rem;
  padding-left: 2.4rem;
  button:focus {
    outline: 0;
  }
}

@media (min-width: 569px) {
  .home-tab .ht-list {
    font-size: 1.6rem;
  }
}

@media screen and (orientation: landscape) {
  .home-tab .ht-list {
    font-size: 1.6rem;
  }
}

.home-tab .ht-listItem,
.home-tab .ht-listItem > a,
.home-tab .ht-listItem > button {
  display: inline-flex;
   align-items: center;
   justify-content: center;
  height: 5.6rem;
  position: relative;
  width: 37.333333333vw;
}

.home-tab .ht-listItem {
  color: rgba(33, 33, 33, .47);
}

.home-tab .ht-listItem::after {
  background-color: white;
  content: "";
  height: .4rem;
  position: absolute;
   bottom: 0;
   left: 0;
  width: 100%;
}

.home-tab .ht-listItem > a,
.home-tab .ht-listItem > button {
  background: none;
  border: none;
  color: inherit;
  text-decoration: none;
  transition: all .3s ease;
}

.home-tab .ht-listItem > a::after,
.home-tab .ht-listItem > button::after {
  content: "";
  height: .4rem;
  position: absolute;
   bottom: 0;
   left: 0;
  transition: all .3s ease;
  width: 100%;
}

.home-tab .ht-listItem > a:hover,
.home-tab .ht-listItem > button:hover {
  color: rgba(33, 33, 33, .47);
  font-weight: normal;
}

.home-tab .ht-listItem > a:hover::after,
.home-tab .ht-listItem > button:hover::after {
  background-color: #1D79B2;
}

.home-tab .ht-listItem.is-active {
  color: #212121;
  font-weight: 700;
}

.home-tab .ht-listItem.is-active > a,
.home-tab .ht-listItem.is-active > button {
  color: #212121;
  font-weight: 700;
}

.home-tab .ht-listItem.is-active::after {
  background-color: #1D79B2;
  content: "";
  height: .4rem;
  position: absolute;
   bottom: 0;
   left: 0;
  width: 100%;
}

/* Utility
----------------------------------------*/
.home-utility {
  background-color: #2e2e2e;
  margin-bottom: 0;
  padding: .8rem 0;
}

.home-utility .hu-button {
  background: none;
  border-radius: 0;
  color: #fff;
  flex-wrap: nowrap;
  height: 5.5rem;
  margin: 0 2.4rem;
  padding: .8rem;
  width: calc(100% - 5rem);
}

.home-utility .hu-button_label {
  flex: 0 0 auto;
  margin-bottom: 0;
  width: auto;
}

@media screen and (orientation: landscape) {
  .home-utility .hu-button_label {
    font-size: 1.4rem;
  }
}

.home-utility .hu-button:last-child::before {
  background-color: rgba(255, 255, 255, .15);
  top: 0;
  bottom: 0;
  left: -2.4rem;
}

.home-carousel {
  display: flex;
  height: 100%;
  background-color: #F2F2F2;
}

.home ul[rn-carousel] {
  > li {
    position: relative;
    margin-left: -100%;
    width: 100%;

    &:first-child {
      margin-left: 0;
    }
  }
}