.edit-calving-sensor-history-modal-form {
  .calving-sensor-number {
    &-input {
      display: flex;
      padding: 0 .8rem;

      .mf-group {
        margin: 0 auto;
      }

      .row {
        padding: 0 .8rem;
      }

      .number-delimiter {
        align-items: center;
        display: flex;
        justify-content: center;
        width: 20px;
      }
    }

    .mf-subgroup.is-error {
      .mf-label {
        color: #d22323;
      }

      .form-control {
        border-bottom-color: #d22323;
        color: #d22323;
      }

      .number-delimiter {
        color: #d22323;
      }
    }
  }
}

.edit-calving-sensor-history-modal-history-box {
  .history-box-title {
    font-size: 14px;
    color: #777;
  }

  .history-box-content {
    appearance: none;
    background-color: #fff;
    border: 1px dashed #ddd;
    color: #777;
    padding: 10px;

    &-row {
      margin: 0 0 5px 0;

      &:last-child {
        margin: 0;
      }
    }

    &-title {
      display: inline-block;
      font-size: 14px;
      margin: 0;
      text-align: right;
      width: 130px;
    }

    &-text {
      display: inline-block;
      font-size: 14px;
      margin: 0;
    }
  }
}
