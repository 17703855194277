.operation-history {
  label {
    font-weight: 400;
    color: #757575;
    margin-bottom: 0;
  }
  .no-data {
    color: #ff9908;
    font-weight: 700;
    padding: 1.5em;
  }
  .smooth-scroll {
    -webkit-overflow-scrolling: touch;
    overflow-scrolling: auto;
    height: calc(100vh - 100px);
  }
  .header-info {
    background-color: #f3f3f3;
    position: fixed;
    width: 100%;
    z-index: 100000;

    // 旧アイコンフォント調整
    .icon-l-arrow-A-l01,
    .icon-l-arrow-A-r01 {
      font-size: 14px !important;
      width: 14px;
      height: 14px;
      padding: 0;
      color: #fff !important;
      border: none;
      border-radius: 50%;
      outline: 0;
      background-color: #ff9908;
    }
    + .smooth-scroll {
      padding: 4.4rem 0 0;
      height: calc(100vh - 10.7rem);

      .is-stickyAlertNotification &,
      .is-stickyAlertDysstasia & {
        height: calc(100vh - 19.5rem);
      }

      .is-stickyAlertNotification.is-stickyAlertDysstasia & {
        height: calc(100vh - 28.3rem);
      }
    }
  }
  .category-info {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .mm-list {
    margin-bottom: none;
    .medicine-list {
      border: 1px dotted gray;
      margin: 0.25em 1em 0 0;
      padding: 0.3em;
    }
    .mm-listItem {
      line-height: 1.8em;

      span {
        display: inline-block;
        font-weight: 700;
      }
      section {
        display: inline-block;
        margin-right: 5px;
      }
    }
    div {
      width: 100%;
    }
    .width-50 {
      display: inline-block;
      width: 47%;
      margin: 0 0.5em 0 0;
    }
  }
  .mm-listItem_toggle {
    div {
      width: 100%;
      min-height: 0;
    }
  }
  .module-menu.has-checkbox:last-child {
    margin-bottom: 0;
  }
  label[for='mobile-operation-history-current-date'] {
    margin: 0;
    display: flex;
    align-items: center;
    .icon-s-calendar {
      margin-left: 5px;
      margin-bottom: 1px;
    }
  }
  #mobile-operation-history-current-date {
    border: 0;
  }
}

.block-ui-container {
  z-index: 105001 !important;

  // React版モーダルより上に重ねる
  .barn--mobileCustomlistRuntime ~ &,
  .barn--mobileReproductionPlannedEventsList ~ &,
  .barn--mobileAlertList ~ &,
  .barn--mobile-alert-dysstasia ~ &,
  .barn--mobile-alert-dysstasia-resolved ~ &,
  .barn--mobile-alert-calving ~ &,
  .barn--mobile-alert-calving-confirmed ~ & {
    z-index: 10009000 !important;
  }
}
