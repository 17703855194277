/*---------------------------------
　コンテンツ
---------------------------------*/

/*
　コンテンツ
---------------------------------*/
.uContent {

  &--secondary {
    padding: 12px 10px;
    background: #f0f0f0;
  }

  &--tertiary {
    overflow-x: hidden;
    overflow-y: scroll;
    padding: 4px 10px 93px;
    border-top: 2px solid white(primary);
    background: #f0f0f0;
  }

  &--quaternary {
    overflow-x: hidden;
    overflow-y: scroll;
    min-height: calc(100vh - 107px);
    padding: 0 0 93px;
    background: #f0f0f0;

    .header-info {
      background: #fff;
    }

    .uContent__inner {
      margin: 5px 10px 26px;
    }
  }

  &__mask {
    &--head {
      position: fixed;
      z-index: 1000000;
      top: 0;
      left: 0;
      width: 100%;
      height: 106px;
      background: rgba(255, 255, 255, 0);
    }

    &--head__withStickyAlert {
      height: 194px;

      @extend .uContent__mask--head;
    }
  }

  &__controller {
    &--foot {
      position: fixed;
      z-index: 104000;
      bottom: 0;
      left: 0;
      display: flex;
      width: 100%;
      min-height: 73px;
      padding: 16px 16px env(safe-area-inset-bottom);
      background: #eee;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);

      justify-content: space-between;

      .uButton {
        width: 96px;
        margin: 0 !important;
        white-space: nowrap;

        @media (min-width: 340px) {
          margin: 0 0 0 16px !important;
        }
      }

      .uButton--cancel {
        width: 96px;
        white-space: nowrap;

        &:not(:last-child) {
          margin: 0 !important;

          @media (min-width: 340px) {
            margin: 0 0 0 auto !important;

          }
        }
      }
    }
  }

  &__head {
    display: flex;
    margin: 30px 16px 0px !important;

    align-items: center;
    justify-content: space-between;
  }

  &__heading {
    font-size: 17px;
    font-weight: 700;
    color: #333;

    &--secondary {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      height: 36px;
      margin: 0;
      padding: 0 16px;
      color: rgba(33, 33, 33, 0.62);
      background: white(primary);

      align-items: center;
    }

    &--tertiary {
      font-size: 17px;
      font-weight: 400;
      display: flex;
      margin: 20px 0 0;
      padding: 0 16px;
      color: rgba(33, 33, 33, 0.62);

      align-items: center;
    }

    &Num {
      font-size: 14px;
      font-weight: 700;
      margin: 0 0 0 12px;
      color: black(primary);

      &::after {
        font-size: 14px;
        font-weight: 400;
        margin: 0 0 0 4px;
        content: '頭';
      }
    }
  }

  &__text {
    font-size: 14px;
    color: black(secondary);
  }

  &__conditions {
    display: flex;
    height: 50px;
    padding: 0 12px 0 16px;
    background: #3b3b3b;

    align-items: center;
    justify-content: space-between;

    &Title {
      font-size: 13px;
      line-height: 1.3;
      margin: 0;
      color: white(secondary);
    }

    &Value {
      font-size: 14px;
      line-height: 1.3;
      margin: 0;
      color: white(primary);
    }

    &Other {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      width: 116px;
      height: 36px;
      color: white(primary);
      border-radius: 18px;
      background: #666;

      align-items: center;
      justify-content: center;
    }
  }

  &__body {

    &--center {
      display: flex;
      height: 100%;

      align-items: center;
      justify-content: center;
    }
  }
}