// Prefix
[class^="layout-"] {
    position: relative;
    width: 100%;
}

.container {
  padding-right: 0;
  padding-left: 0;
  width: 100%;
}

// Import
@import "components/header";
@import "components/main";
@import "components/footer";


/*----------------------------------------
 Module
----------------------------------------*/

// Prefix
[class^="module-"],
[class*=" module-"] {
  margin-bottom: 1rem;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
}

// Import
@import "components/alert";
@import "components/menu";


@import "components/chart";
@import "components/home";
@import "components/detail";
@import "components/mobile";
@import "components/operation-history";
@import "components/modal";
@import "components/list";
@import "components/bulkentry-events";
@import "components/bulk-edit-labels";
@import "components/edit-labels";
@import "components/customlist";
@import "components/edit-ble-id-confirm-modal";
@import "components/alert-label";
@import "components/sticky-alert";
@import "components/column-list";
@import "components/article";
@import "components/notification";
@import "components/calving-sensor-number";
@import "components/calving-sensor-history-modal";
@import "components/edit-calving-sensor-history-modal";
@import "components/edit-calving-sensor-history-confirm-modal";
@import "components/alert-calving";
@import "components/alert-calving-sensor-dropped";
@import "components/u-modal";
@import "components/u-content";
@import "components/u-card";
@import "components/u-list";
@import "components/u-button";
@import "components/u-floatingbox";
