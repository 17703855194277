.bulk-edit-labels {
  ul.label-list {
    list-style-type: none;
    max-height: 60vh;
    overflow: scroll;
    overflow: -webkit-touch;
    li {
      margin-left: 1em;
    }
  }
}
