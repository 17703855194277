/* Menu
----------------------------------------*/

.module-menu {
  background-color: #fff;
  box-shadow: 0 calc-rem(1) calc-rem(2.5) rgba(146, 146, 146, .5);
  margin: 0 0 calc-rem(30) 0;

  &.mm-list.chart-visible {
    margin-bottom: 40vh;
  }

  &.mm-list .mm-listItem_state .labels-wrapper {
    width: 8rem;
  }

  &.mm-list .mm-listItem_state .illness {
    float: left;
  }

  &.mm-list .mm-listItem_state .chronic {
    float: right;
  }

  &.mm-list .mm-listItem_state .illness > span {
    border: 1px solid #c02c94;
    border-radius: 0.2rem;
    font-size: 1.2rem;
    color: #c02c94;
    padding: 0.2rem 0.4rem;
  }

  &.mm-list .mm-listItem_state .chronic > span {
    border-bottom: 1px solid #c02c94;
    font-size: 1.2rem;
    color: #c02c94;
    padding: 0.2rem 0.4rem;
  }
}

.mm-list-subtitle {
  font-size: 1.6rem;
}

.module-menu .mm- {
  &list {
    &Item {
      font-size: 1.3rem;
      border-bottom: calc-rem(1) solid #ccc;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      min-height: 5.5rem;
      position: relative;
      line-height: 1.2;

      > a {
        display: inherit;
        align-items: stretch;
        min-height: inherit;
        padding: 0 1.6rem;
        position: relative;
        width: 100%;

        &::before {
          @include fa-icon;
          content: '\f105';
          display: none;
          font-size: calc(1em + #{calc-rem(2)});
          position: absolute;
          top: 50%;
          right: calc-rem(10);
          transform: translateY(-50%);
        }
      }

      &_head {
        align-self: center;
        font-size: 1.6rem;
        margin-right: auto;
        padding-top: 1.8rem;
        padding-bottom: 1.8rem;
        width: 70%;
      }

      &_data {
        color: #757575;
        align-self: center;
        font-size: 1.3rem;
        padding-right: .8rem;
        padding-left: .8rem;
        text-align: right;
        width: 30%;
      }

      &_button {
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        justify-content: center;
        margin-right: -1.6rem;
        width: 4.8rem;

        &::before {
          border-top: 2px solid #333;
          border-right: 2px solid #333;
          content: "";
          height: 10px;
          transform: translateX(-25%) rotate(45deg);
          width: 10px;
        }
      }

      &_container {
        color: #212121;
        display: inherit;
        align-items: flex-start;
        margin-top: .5rem;
        margin-bottom: -1.5rem;
        padding-right: 2rem;
        text-align: left;
        width: 100%;
      }

      &_primary {
        display: flex;
        flex: 0 0 auto;
        max-width: 50%;
      }

      &_secondary {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .mm-listItem_value {
          border-left: .1rem solid #ddd;
          padding-left: 1.2rem;
        }
      }

      &_labelGroup {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: .5rem;
        width: 100%;
      }

      &_label {
        border: .1rem dashed #bbb;
        font-size: 1.3rem;
        font-weight: 400;
        margin-right: .8rem;
        margin-bottom: .8rem;
        padding: .3rem .7rem;
      }

      &_value {
        font-size: 1.6rem;
        font-weight: 400;
        margin-right: 2.4rem;
        margin-bottom: 2rem;
        padding-top: .4rem;
        padding-bottom: .4rem;
        word-break: break-all;

        strong {
          color: #757575;
          display: block;
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
      }



      &_collapse {
        background-color: #F2F2F2;
        padding: 1.2rem 1.2rem 2rem;
        width: 100%;

        .actions {
          flex: 0 0 auto;
          margin-left: 5vw;
        }

        &Group {
          background-color: #fff;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 1.6rem;
          padding: 1.6rem 1rem;

          .mm-listItem_state {
            margin: 0;
            width: 100%;

            th,
            td {
              padding: calc-rem(8) 0;
              font-size: 1.3rem;
            }

            th {
              color: #757575;
              padding-right: .8rem;
              position: relative;
              text-align: right;
              white-space: nowrap;
              width: 1%;

              &::after {
                background-color: #ddd;
                content: "";
                height: calc(100% - .8rem);
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                width: .1rem;
              }
            }

            td {
              font-weight: 700;
              padding-left: .8rem;
              width: 50%;
              word-break: break-all;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }

          .dysstasia-list & {
            flex: 1 1 auto;
            display: flex;
            padding: 1rem 1rem;
            align-items: center;

            & table{
              width: 100%;
            }
            & th {
              padding: 0.2rem 0rem;
            }
            & td {
              padding: 0.2rem 0rem;
            }
            & a {
              color: #333;
            }
            &.cow-info {
              margin-bottom: 0;
            }
            &.report-result {
              color: white;
              background-color: #366678;
              display: table;
              margin-bottom: 1rem;
            }
            &.to-cow-detail {
              border: 1px solid #ddd;
              display: table;
            }
          }
        }
      }

      &_continue {
        color: #2e7dbf;
        display: table;
        font-weight: 700;

        a {
          border: .1rem solid #337AB7;
          display: inline-block;
          font-size: 1.3rem;
          padding: 1.3rem 1.4rem;
          min-width: 4.4rem;
          &.to-cow-detail {
            font-size: 3.2vw;
            padding: 0.9rem;
          }
        }
      }

      &_number {
        @include font-size(13);
        margin-right: 0;
        padding-right: 5px;
        padding-left: 9.2vw;
        position: relative;
        text-align: left;
        width: 33%;

        &.long {
          width: 66%;
        }

        &:nth-of-type(2) {
          padding-left: 12.2vw;
        }

        span {
          &:first-child {
            @include font-size(0, 13);
            left: 0;
            top: 2px;
            position: absolute;
            font-size: 2.8vw;
            font-weight: 700;
          }
        }
      }

      &_state {
        @include font-size(13);
        margin-right: 0;
        text-align: left;

        span {
          @include font-size(10.5);
          font-weight: 700;
          margin-right: calc-rem(5);

          &:first-child {
            @include font-size(10.5, 13);
            font-weight: 700;
          }
        }
        .mm-listItem_label,
        .mm-listItem_label:first-child {
          font-weight: 400;
        }
      }

      &_toggle {
        background: transparent;
        border: none;
        cursor: pointer;
        display: inherit;
        flex-wrap: inherit;
        min-height: inherit;
        padding: 1.6rem 2rem 1.6rem 1.6rem;
        position: relative;
        transition: color .3s ease;
        width: 100%;

        &::after {
          @extend [class^="icon-"];
          @include font-size(0, 20);
          background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23333333%3B%20stroke%3A%23333%3B%20stroke-width%3A2%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16.783%2C10.35c-0.5-0.5-1.3-0.5-1.8%2C0s-0.5%2C1.3%2C0%2C1.8l7.9%2C7.8l-7.9%2C7.9c-0.5%2C0.5-0.5%2C1.3%2C0%2C1.8s1.3%2C0.5%2C1.8%2C0%20l8.8-8.8c0.5-0.5%2C0.5-1.3%2C0-1.8L16.783%2C10.35z%22%2F%3E%3C%2Fsvg%3E');
          content: "";
          display: none;
          height: calc-rem(20);
          position: absolute;
          top: 50%;
          right: calc-rem(5);
          transform: translateY(-50%);
          transition: all .3s ease;
          width: calc-rem(20);
        }

        .mm-listItem_button {
          border-radius: 50%;
          content: "";
          height: 3rem;
          position: absolute;
          top: 50%;
          right: 1rem;
          transform: translate(-45%, -60%) rotate(90deg);
          width: 3rem;

          &.no-rotate {
            transform: translate(-45%, -60%);
          }
        }

        &.no-expansion::after {
          color: transparent;
        }

        &:focus {
          outline: none;
        }

        &[aria-expanded="true"] {
          &::after {
            background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23477d17%3B%20stroke%3A%23477d17%3B%20stroke-width%3A2%3B%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M16.783%2C10.35c-0.5-0.5-1.3-0.5-1.8%2C0s-0.5%2C1.3%2C0%2C1.8l7.9%2C7.8l-7.9%2C7.9c-0.5%2C0.5-0.5%2C1.3%2C0%2C1.8s1.3%2C0.5%2C1.8%2C0%20l8.8-8.8c0.5-0.5%2C0.5-1.3%2C0-1.8L16.783%2C10.35z%22%2F%3E%3C%2Fsvg%3E');
            transform: translateY(-50%) rotate(90deg);
          }
          .mm-listItem_button {
            background-color: #F0F0F0;
            &::before {
              border-color: #366678;
              transform: translateX(25%) rotate(225deg);
            }
          }
        }

        &[aria-expanded="false"] {
          & + .mm-listItem_collapse {
            display: none;
          }
        }

        &[aria-expanded="true"] {
          color: #477d17;
          font-weight: 700;

          .mm-listItem_number {
            span {
              &:first-child {
                font-weight: 400;
              }
            }
          }

          .mm-listItem_state {
            span {
              font-weight: 400;

              &:nth-of-type(2) {
                font-weight: 700;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 569px) {
    &listItem_ {
      &number {
        padding-left: 0;

        &:nth-of-type(2) {
          padding-left: 0;
        }

        span {
          &:first-child {
            position: static;
          }
        }
      }
    }
  }
}
