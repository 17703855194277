// /*----------------------------------------
//  Variables
// ----------------------------------------*/

// /* Color
// ----------------------------------------*/

$text-color: #333;

// /* Typography
// ----------------------------------------*/

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: normal;
  src: local('YuGothic-Medium'),
    local('Yu Gothic Medium'),
    local('YuGothic Medium'),
    local('YuGothic-Regular');
}

@font-face {
  font-family: 'MyYuGothicM';
  font-weight: bold;
  src: local('YuGothic-Bold'),
    local('Yu Gothic'),
    local('YuGothic Bold');
}

$font-family-sans-serif: Arial, MyYuGothicM, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;

$line-height-base: 1.6;
