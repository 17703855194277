/* # Main
----------------------------------------*/

.layout-main {
  flex: 1;
  padding: 4rem 0 calc(5.7rem + env(safe-area-inset-bottom));
  height: var(--app-height);

  .is-stickyAlertNotification &,
  .is-stickyAlertDysstasia & {
    padding-top: 13.8rem;
  }

  .is-stickyAlertNotification.is-stickyAlertDysstasia & {
    padding-top: 22.6rem;
  }
}

.layout-main-spacer {
  height: 5rem;
}

.overall-layout-adjust {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.footer-action-bar {
  //padding-bottom: env(safe-area-inset-bottom);
}
