// /*----------------------------------------
//  Functions
// ----------------------------------------*/

@use "sass:math";

@function calc-rem($pixel, $base: 10) {
  @if (unitless($pixel)) {
    $pixel: $pixel * 1px;
  }

  @if (unitless($base)) {
    $base: $base * 1px;
  }

  @return math.div($pixel, $base) * 1rem;
}


@function calc-vw($pixel, $base: 375) {
  @if (unitless($pixel)) {
    $pixel: $pixel * 1px;
  }

  @if (unitless($base)) {
    $base: $base * 1px;
  }

  @return math.div($pixel, $base) * 100vw;
}

/*
　色 - 黒
---------------------------------*/
$black: (
  primary: #212121,
  secondary: #666,
  tertiary: #757575,
  quaternary:#bdbdbd
);

@function black($key) {
  @return map-get($black, $key);
}

/*
　色 - 白
---------------------------------*/
$white: (
  primary: #fff,
  secondary: #b2b2b2,
  tertiary: #777
);

@function white($key) {
  @return map-get($white, $key);
}