.mobile-bulkentry-events-register {
  .blank-form {
    height: 100vh;
    background: white;
  }
  #cow-numbers {
    background-color: #fff;
    border: dashed 1px #ddd;
    color: #777;
    padding: 1rem 1.2rem;
    width: 100%;
    max-height: 100%;
    font-size: 1.6rem;
  }
}

.mobile-remove-cows {
  margin: auto auto;
  max-height: 60vh !important;
  .md-content {
    padding-top: 2em;
    .md-body {
      padding: 2em 1em 0em 1em;
      .cow-list {
        max-height: 55vh;
        overflow: scroll;
        padding: 0px;
        label {
          display: flex;
          input[type="checkbox"] {
            margin: 0em 1em;
          }
        }
      }
    }
  }
}