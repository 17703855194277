.category-info {
  background-color: #E5E5E5;
  border-bottom: 1px solid #fff;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  font-size: 1.6rem;
  min-height: 4.8rem;
  padding: .8rem 1.3rem;
  position: -webkit-sticky;
  position: sticky;
  top: 9.9rem;
  z-index: 1;
}

.category-info>* {
  max-width: 50%;
}

.category-info>*:first-child:last-child {
  max-width: none;
}

.header-info {
  border-bottom: .1rem solid #ccc;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  height: 4.4rem;
  padding: .5rem 1.3rem;
}

.header-info .button {
  background-color: #366678;
  border: .1rem solid #366678;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 700;
  outline: none;
  padding: .5rem .7rem .3rem;
  transition: all .3s;
  min-width: 5.1rem;
}

.header-info .button[aria-selected="true"] {
  background-color: transparent;
  color: #366678;
}

.header-info + .module-menu {
  padding-top: 4.4rem!important;
}

.module-controller.select-mode {
  background-color: #F2F2F2;
  color: inherit;
  height: calc(6.8rem + env(safe-area-inset-bottom));
  padding-top: 1.2rem;
  padding-bottom: calc(1.2rem + env(safe-area-inset-bottom));
  transition: all .3s;
}

.module-controller.select-mode .mc-button {
  border: .1rem solid #366678;
  color: #366678;
  font-weight: 700;
  padding: .5rem .7rem .3rem;
  min-width: 7rem;
}

.module-controller.select-mode .mc-button[type="submit"] {
  background-color: #366678;
  color: #fff;
  padding-top: 1.1rem;
  padding-bottom: .9rem;
}

.module-controller.select-mode .mc-button:disabled {
  background-color: #e5e5e5;
  color: #d9d9d9;
  border: none;
}

.module-controller.select-mode.invisible {
  bottom: -6.8rem;
}

.module-menu.has-checkbox {
  box-shadow: none;
  margin-bottom: 0;
  padding: 0;
  position: relative;
  left: -3.2rem;
  transition: all .3s;
  width: calc(100% + 3.2rem);
}

.module-menu.has-checkbox .mf-checkbox {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-75%);
}

.module-menu.has-checkbox .mf-checkbox_label {
  line-height: 1.4;
  padding-left: 3.2rem;
  width: 100%;
}

.module-menu.has-checkbox .mf-checkbox_label::before {
  top: 50%;
  left: 1.4rem;
  transform: translateY(-50%);
}

.module-menu.has-checkbox .mm-listItem_toggle {
  padding-left: 4.6rem;
  text-align: left;
}

.module-menu.has-checkbox .mm-listItem_collapse {
  margin-left: 3.2rem;
}

.module-menu.has-checkbox:not(:last-child) .mm-listItem:last-child {
  border-bottom: none;
}

.category-group .module-menu.has-checkbox {
  margin-bottom: 0;
}

.category-group:last-child {
  margin-bottom: 2.2rem;
}

.cow-list .header-info {
  background-color: #f3f3f3;
  position: fixed;
  width: 100%;
  z-index: 100000;
}

.cow-list .select-mode .smooth-scroll {
  overflow: visible;
}

.cow-list .select-mode .module-menu.has-checkbox {
  left: 0;
  width: 100%;
}

.cow-list .select-mode .module-menu.has-checkbox .mm-listItem.is-select {
  background-color: #E6EDF0;
}

.cow-list .select-mode .module-menu.has-checkbox .mm-listItem_toggle::after,
.cow-list .select-mode .module-menu.has-checkbox .mm-listItem_button {
  opacity: 0;
}

.cow-list .select-mode .module-menu.has-checkbox .mm-listItem_collapse {
  display: none;
}

@media (max-width: 568px) {
  .module-menu .mm-listItem_number.cowNo {
    padding-left: 12.2vw;
  }
}

.custom-list .module-controller {
  z-index: 100000 !important;
}

.module-dialog.customlist-cow-detail-modal,
.module-dialog.event-detail-modal
 {
  z-index: 105000 !important;
}

.module-dialog.customlist-cow-detail-modal {
  padding: 0;
  ul[rn-carousel]>li {
    padding: 0 0.5em;
    margin: 0 0 0 2em;
    width: calc(100% - 4em);
  }
}

/* Controller
----------------------------------------*/
.dysstasia .module-controller {
  align-items: flex-start;
  height: 7rem;
  padding: 1.2rem;
  z-index: 105000 !important;
}


.dysstasia .module-controller .mc-button {
  background-color: #444;
  border: .1rem solid #666;
  border-radius: .6rem;
  padding: 1.1rem 1.2rem;
}

.dysstasia .module-controller .mc-button.button-primary {
  background-color: #2a87c1;
  border-color: #2a87c1;
}

.dysstasia .module-controller .mc-list {
  display: flex;
   align-items: center;
   justify-content: flex-end;
  width: 100%;
}

.dysstasia .module-controller .mc-listItem {
  display: flex;
   align-items: center;
  padding-left: 1.2rem;
}

/* Dialog
----------------------------------------*/
.dysstasia .module-dialog .md-body.body-frame {
  height: 52vh;
   max-height: none;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
}

.module-dialog .md-container {
  margin-top: 5.5rem;
}

.module-dialog .md-title {
  font-size: calc(1em + 1.1rem);
  padding-right: 1rem;
  padding-left: 1rem;
}

.module-dialog .md-title_label {
  display: inline-block;
  font-size: calc(1em - .9rem);
  padding-right: .8rem;
  vertical-align: middle;
}

.module-dialog .md-labelGroup {
  display: flex;
}

.module-dialog .md-label {
  border: .1rem solid #a7a7a7;
  border-radius: .4rem;
  color: #a7a7a7;
  display: inline-block;
  font-size: 1.3rem;
  margin: 0 .8rem 2.6rem 0;
  padding: .2rem 2rem 0;
}

.module-dialog .md-label.dysstasia-state-confirmed {
  border-color: #a7a7a7;
  color: #a7a7a7;
}

.module-dialog .md-label.dysstasia-state-recoverd {
  border-color: #4468ba;
  color: #4468ba;
}

.module-dialog .md-label.dysstasia-state-unconfirmed {
  border-color: #ce3954;
  color: #ce3954;
}

.module-dialog ~ .module-controller {
  background-color: #333;
}

@media (orientation: landscape) {
  .module-dialog .md-body.body-frame {
    max-height: 52.8vh;
  }
}

/* Menu
----------------------------------------*/
.cow-list.has-state .module-menu {
  box-shadow: none;
  margin: 0;
  padding: 0;
}

.cow-list.has-state .module-menu .mm-listItem_button {
  margin-right: -1.3rem;
}

.cow-list.has-state .module-menu .mm-listItem_button::before {
  transform: rotate(-45deg);
}

.cow-list.has-state .module-menu .mm-listItem_container {
  align-items: stretch;
  margin-top: 0;
  padding-right: 0;
}

.cow-list.has-state .module-menu .mm-listItem_label {
  border: none;
  display: inline-block;
  margin: 0 0 .4rem;
}

.cow-list.has-state .module-menu .mm-listItem_label:last-child {
  margin-bottom: 0;
}

.cow-list.has-state .module-menu .mm-listItem_label.dysstasia-state-confirmed {
  background-color: #f2f2f2;
  color: #757575;
}

.cow-list.has-state .module-menu .mm-listItem_label.dysstasia-state-unconfirmed {
  background-color: #fcecee;
  color: #CE3954;
  font-weight: 700;
}

.cow-list.has-state .module-menu .mm-listItem_label.dysstasia-state-recoverd {
  background-color: #eaf0ff;
  color: #4468ba;
}

.cow-list.has-state .module-menu .mm-listItem_labelGroup {
  align-items: flex-start;
  flex: 0 0 auto;
}

.cow-list.has-state .module-menu .mm-listItem_primary {
  flex-direction: column;
  padding-left: 1.6rem;
  max-width: 32.4vw;
}

.cow-list.has-state .module-menu .mm-listItem_secondary {
  flex-wrap: wrap;
}

.cow-list.has-state .module-menu .mm-listItem_secondary .mm-listItem_value {
  margin-right: 0;
  margin-bottom: 1.5rem;
  padding-right: 2rem;
  width: 100%;
}

.cow-list.has-state .module-menu .mm-listItem_toggle {
  padding-right: 4.4rem;
  padding-left: 0;
}

.cow-list.has-state .module-menu .mm-listItem_value {
  padding-top: .7rem;
  padding-bottom: .7rem;
}
