.modal-error {
  z-index: 105001 !important;
}
.um-modal-dialog {
  header.layout-header {
    z-index: 105001 !important;
  }
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(#000,.2);
  z-index: 100000;
  text-align: left;
  background-color: rgba(0, 0, 0, 0.7);
  margin: 0;
  padding: 0;

  .mf-action button[disabled] {
    -webkit-appearance: none;
      -moz-appearance: none;
          appearance: none;
    background-color: #eee;
    border-color: #ddd;
    border-style: dashed;
    color: #777;
  }
}
.ble-id-history {
  .ble-id-list {
    max-height: 60vh;
    overflow: scroll;
    .ble-id-item {
      padding: 5px 8px;
      border-bottom: 1px solid #ccc;
      max-height: 100%;

      &:last-child {
        border-bottom: none;
      }
    }
  }
  .md-footer {
    border-top: 1px solid #333;
  }
}

.switch-farm-modal {
  .icon-l-arrow-A-l01 {
    font-size: 12px !important;
    width: 10px;
    height: 10px;
    color: #fff;
    border-radius: 50%;
    background-color: #333;
  }
}

.mobile-standard-modal {
  .mobile-standard-modal-message {
    font-size: 15px;
    white-space: pre-wrap;
  }

  .md-container {
    .ok-button {
      background: #f0f0f0;
      border-radius: 4px;
      color: #366678;
      font-weight: 700;
      padding: 6px 2px;
    }
  }
}

.mobile-cows-carousel {
  .module-controller {
    z-index: 105000 !important;
    height: 8rem;
    padding: 2.4rem 1.2rem 1.2rem;

    align-items: flex-start;
  }

  .mc-button {
    padding: 1.1rem 1.2rem;
    border: .1rem solid #666;
    border-radius: .6rem;
    background-color: #444;

    &.button-primary {
      border-color: #2a87c1;
      background-color: #2a87c1;
    }
  }

  .mc-list {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: flex-end;
  }

  .mc-listItem {
    display: flex;
    padding-left: 1.2rem;

    align-items: center;
  }
}

.modal-size-normal {
  background-color: rgba(51, 51, 51, .95);
}