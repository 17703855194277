@font-face {
  font-family: 'icomoon-m';
  src:  url('../fonts/m/icomoon.eot?ixzakr');
  src:  url('../fonts/m/icomoon.eot?ixzakr#iefix') format('embedded-opentype'),
    url('../fonts/m/icomoon.ttf?ixzakr') format('truetype'),
    url('../fonts/m/icomoon.woff?ixzakr') format('woff'),
    url('../fonts/m/icomoon.svg?ixzakr#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-m-"], [class*=" icon-m-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-m' !important;
  speak: none;
  font-size: 20px !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-m-account-outlined:before {
  content: "\e900";
}
.icon-m-back:before {
  content: "\e901";
}
.icon-m-bell-outlined:before {
  content: "\e902";
}
.icon-m-book:before {
  content: "\e903";
}
.icon-m-calendar:before {
  content: "\e904";
}
.icon-m-check-box-outlined:before {
  content: "\e905";
}
.icon-m-copy:before {
  content: "\e906";
}
.icon-m-data-add:before {
  content: "\e907";
}
.icon-m-delete:before {
  content: "\e908";
}
.icon-m-dots:before {
  content: "\e909";
}
.icon-m-download-file:before {
  content: "\e90a";
}
.icon-m-export:before {
  content: "\e90b";
}
.icon-m-facility:before {
  content: "\e90c";
}
.icon-m-filter:before {
  content: "\e90d";
}
.icon-m-graph:before {
  content: "\e90e";
}
.icon-m-help-outlined:before {
  content: "\e90f";
}
.icon-m-history:before {
  content: "\e910";
}
.icon-m-home:before {
  content: "\e911";
}
.icon-m-list-alt:before {
  content: "\e912";
}
.icon-m-list-card:before {
  content: "\e913";
}
.icon-m-milk:before {
  content: "\e914";
}
.icon-m-note:before {
  content: "\e915";
}
.icon-m-notice:before {
  content: "\e916";
}
.icon-m-scatter-plot:before {
  content: "\e917";
}
.icon-m-search:before {
  content: "\e918";
}
.icon-m-setting:before {
  content: "\e919";
}
.icon-m-shipment:before {
  content: "\e91a";
}
.icon-m-sideboard:before {
  content: "\e91b";
}
.icon-m-syncAlt:before {
  content: "\e91c";
}
.icon-m-tag:before {
  content: "\e91d";
}
.icon-m-tune:before {
  content: "\e91e";
}
.icon-m-upload-file:before {
  content: "\e91f";
}
.icon-m-view-list-outlined:before {
  content: "\e920";
}
.icon-m-view-module-outlined:before {
  content: "\e921";
}
