.stickyAlert {
  position: fixed;
  z-index: 100000;
  top: 0;
  left: 0;
  width: 100%;

  &__items {}

  &__item {
    &--dysstasia {
      height: 88px;
      border: 4px solid #900000;
      background: linear-gradient(to right, #a50606, #a82000);

      .stickyAlert {
        &__inner {
          border: 1px solid rgba(198, 61, 61, .16);
        }
      }
    }

    &--notification {
      height: 88px;
      border: 4px solid #480086;
      background: linear-gradient(to right, #7400b1, #5800b1);

      .stickyAlert {
        &__inner {
          border: 1px solid rgba(130, 0, 199, 0.16);
        }
      }
    }
  }

  &__inner {
    line-height: 1.4;
    position: relative;
    display: flex;
    height: 100%;
    padding: 0 40px 0 10px;
    color: #fff;

    align-items: center;

    &::after {
      position: absolute;
      top: calc(50% - 5px);
      right: 20px;
      display: block;
      width: 12px;
      height: 12px;
      content: '';
      transform: rotate(45deg);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }

    .icon-l-caution {
      font-size: 40px;
      margin: 0;
    }
  }

  &__main {
    margin: 0 0 0 9px;

  }

  &__heading {
    font-size: 14px;
    font-weight: 700;
    margin: 0;
  }

  &__body {
    font-size: 17px;
    font-weight: 400;
    display: -webkit-box;
    overflow: hidden;
    margin: 2px 0 0;

    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__emphasis {
    font-weight: 700;
  }
}