.custom-list {
  .filter-option-modal {
    display: inline-block; 
  }
  .um-modal-dialog {
    main .container .module-form {
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      overflow-scrolling: touch;
      height: calc(100vh);
      form {
        margin-top: 50px;
      }
    } 
  }
  .header-info {
    div {
      button {
        font-size: calc(1em + .8rem);
        background-color: #f5f5f5;
        border: none;
        padding: 0px;
        margin-left: 1em;
      }
    }
  }
}

.filter-option-modal {
  .mf-action {
    button:disabled {
      background-color: #e5e5e5;
      color: #d9d9d9;
      border: none;
    }
  }
}
