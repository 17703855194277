.alertLabel {
  display: inline-block;
  margin: 0 .5rem 0 0;
  padding: .3rem .7rem;
  font-size: 1.3rem;
  border-bottom: 1px solid #ccc;
  font-weight: 700;
  color: #555;

  &--small {
    padding: 1px 5px;
    font-size: 1.1rem;
  }

  &--acuteIllness {
    border: 1px solid #e25f00;
    border-radius: .2rem;
    color: #ea6300;
  }

  &--illness {
    border: 1px solid #c02c94;
    border-radius: .2rem;
    color: #c02c94;
  }

  &--chronicIllness {
    border-bottom: 1px solid #c02c94;
    color: #c02c94;
  }
}