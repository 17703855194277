.message {
  height: 16px;
  margin: 0 auto 6rem;
  text-align: center;
  color: #ff573f;
}

.mobile {
  height: 100%;

  [ui-view=mobile-main] {
    height: 100%;
  }

  .history {
    .table {
      ul {
        margin-bottom: 10px;
      }

      li.category {
        width: 150px;
        font-weight: bold;
        font-size: 16px;
        padding-top: 7px;
        vertical-align: top;
      }

      .selection {
        display: table;
        text-align: left;
        width: 100%;

        .selection-item {
          position: relative;
          list-style: none;
          display: inline-block;
          text-align: center;
          margin: 10px;

          &.breeding {
            background-color: #56b1bc;
          }

          &.medical {
            background-color: #bc7e9c;
          }

          &.other {
            background-color: #ace8ba;
          }
        }
      }
    }

    a.circle-btn {
      width: 130px;
      height: 90px;
      display: inline-block;
      text-decoration: none;
      padding-top: 20px;
      border: solid #333 3px;
      border-radius: 10%;
      color: #333;
      font-size: 26px;
      font-weight: bold;

      &:hover {
        background-color: #ace8ba;
      }
    }
  }

  .box-history {
    right: 0;
    padding: 0;
    width: 100%;
    float: none;
    overflow: visible;

    .mb-0 {
      margin-bottom: 0px;
    }

    .individual-history h4 {
      margin-bottom: 0;
    }

    h4 {
      position: relative;
      padding-left: 3.6rem;

      i {
        position: absolute;
        left: 5px;
        top: 6px;
      }
    }
  }

  #timeline-cow {
    padding: 0;
  }

  .box-history {
    > div {
      overflow-y: auto;
    }

    th .required {
      position: absolute;
    }

    .filtering-wrapper {
      position: relative;
      margin-left: 0;
      padding-top: 10px;
      text-align: right;
      border-left: 3px solid #ddd;
    }

    .filtering {
      position: relative;
      right: 10px;
      top: 0px;
      display: inline-block;
      margin-right: 10px;
      padding: 2px 18px 2px 6px;
      color: #333;
      font-size: 1rem;
      border: 1px solid #eaeaea;
      border-radius: 6px;
      background-color: #fff;

      &:hover {
        text-decoration: none;
      }

      i {
        position: absolute;
        right: 2px;
        color: #ff9908;
        padding-left: 3px;
        font-size: 1.4rem;
      }
    }

    .filtering-top {
      position: absolute;
      right: 0px;
      top: 9px;
      display: inline-block;
      margin-right: 10px;
      padding: 2px 18px 2px 6px;
      color: #333;
      font-size: 1rem;
      border: 1px solid #eaeaea;
      border-radius: 6px;
      background-color: #fff;

      &:hover {
        text-decoration: none;
      }

      i {
        position: absolute;
        right: 2px;
        color: #ff9908;
        padding-left: 3px;
        font-size: 1.4rem;
      }
    }

    .filter-list {
      display: none;
      position: relative;
      margin: 10px 10px 0;
      padding: 10px;
      border: 1px solid #ccc;
      background-color: #f7f7f7;

      &:before {
        display: block;
        content: "";
        position: absolute;
        top: -12px;
        right: 41px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 8px 12px;
        border-color: transparent transparent #ccc transparent;
      }

      &:after {
        display: block;
        content: "";
        position: absolute;
        top: -10px;
        right: 41px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0px 8px 12px;
        border-color: transparent transparent #f7f7f7 transparent;
      }

      p {
        position: absolute;
        left: 8px;
        top: 8px;
        display: inline-block;
        width: auto;
        margin-bottom: 5px;
        border: 1px solid #eaeaea;
        background: #fff;
        border-radius: 6px;

        a {
          padding: 0 7px 0 22px;
          font-size: 1rem;
          color: #333;

          &:hover {
            text-decoration: none;
          }

          i {
            position: absolute;
            left: 4px;
            top: 2px;
            font-size: 1.4rem;
          }
        }
      }

      ul {
        text-align: left;

        &.mt25 {
          margin-top: 25px;
        }

        li {
          display: inline-block;
          width: 32%;
          margin-bottom: 3px;
          text-align: left;

          input {
            vertical-align: middle;
          }
        }
      }
    }

    .timeline {
      clear: both;
      position: relative;
      margin-left: 0;
      padding-top: 10px;
      padding-bottom: 25px;
      border-left: 3px solid #ddd;

      li {
        position: relative;
        width: 87%;
        margin-left: 26px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        &:before, &:after {
          content: "";
          position: absolute;
          left: -27px;
          top: 50%;
          display: block;
          background-color: #ddd;
        }

        &:before {
          width: 27px;
          height: 3px;
        }

        &:after {
          width: 8px;
          height: 8px;
          margin-top: -2px;
          margin-left: -4px;
          border-radius: 50%;
          background-color: #fff;
          border: 2px solid #ddd;
        }

        .timeline-content-wrapper {
          position: relative;
          display: table;
          width: 100%;
          border: 2px solid #ddd;
          .comment-item dl section:first-child dd{
            border-top: none;
          }
        }

        .data-box {
          position: relative;
          display: table-cell;

          /*  float: left; */
          width: 15%;
          padding-top: 5px;
          vertical-align: middle;
          background-color: #f7f7f7;
        }

        .data {
          font-size: 1.2rem;
          font-weight: bold;
          text-align: center;
          line-height: 1.1;
          font-family: arial;
          max-height: 100%;
        }

        .data-box {
          &:before, &:after {
            content: "";
            position: absolute;
            left: -2px;
            display: block;
            width: 2px;
            height: 2px;
          }

          &:before {
            top: -2px;
          }

          &:after {
            bottom: -2px;
          }
        }

        .category01 .data-box {
          &:before, &:after {
            background-color: #008c99;
          }
        }

        .category02 .data-box {
          &:before, &:after {
            background-color: #f56e9d;
          }
        }

        .category03 .data-box {
          &:before, &:after {
            background-color: #df6feb;
          }
        }

        .category04 .data-box {
          &:before, &:after {
            background-color: #826ef5;
          }
        }

        .category05 .data-box {
          &:before, &:after {
            background-color: #6ebff5;
          }
        }

        .category06 .data-box {
          &:before, &:after {
            background-color: #4dab8a;
          }
        }

        .category07 .data-box {
          &:before, &:after {
            background-color: #bca446;
          }
        }

        .category08 .data-box {
          &:before, &:after {
            background-color: #2775be;
          }
        }

        .category09 .data-box {
          &:before, &:after {
            background-color: #1C90FD;
          }
        }

        .category10 .data-box {
          &:before, &:after {
            background-color: #666;
          }
        }

        .category11 .data-box {
          &:before, &:after {
            background-color: #666;
          }
        }

        .category12 .data-box {
          &:before, &:after {
            background-color: #826ef5;
          }
        }

        .category-all .data-box {
          &:before, &:after {
            background-color: #7b9915;
          }
        }
      }
    }

    .post-wrapper {
      width: 92%;
      height: 50px;
      margin: 0 auto;
      text-align: center;
      border: 3px dotted #ccc;
      border-radius: 5px;

      i {
        padding-bottom: 2px;
        font-size: 2rem;
        color: #333;
      }

      a.box-post {
        display: block;
        height: 100%;
        color: #333;
        line-height: 44px;

        &:hover {
          text-decoration: none;
          background-color: #eee;
        }
      }
    }

    .right-shoulder h4, .box-history-innner {
      transition: all 0.5s ease 0s;
    }

    .right-shoulder h4 .tag-inputting {
      display: none;
      margin-left: 9px;
      padding: 5px;
      color: #8aa633;
      background-color: #fff;
      -webkit-animation: flash 3.5s infinite linear;
      animation: flash 3.5s infinite linear;
    }

    .normal-type {
      display: block;
    }

    .right-shoulder {
      .edit-btn {
        display: block;
      }

      .white-btn {
        display: none;
      }
    }

    .box-event-input {
      display: none;
    }

    &.on-event-input {
      .right-shoulder .edit-btn, .normal-type, .event-input-step02 {
        display: none;
      }

      .right-shoulder .white-btn, .box-event-input {
        display: block;
      }
    }

    .right-shoulder .edit-btn i {
      left: 0;
      margin-top: -1.1rem;
      font-size: 2.2rem;
    }

    &.on-event-input {
      .right-shoulder {
        h4 {
          margin-bottom: 0px;
          color: #fff !important;
          background-color: #8aa633;

          .tag-inputting {
            display: inline;
          }
        }

        .white-btn {
          position: absolute;
          top: 5px;
          right: 6px;

          &:hover {
            opacity: 1;
          }
        }
      }

      .box-history-innner {
        border: 4px solid #8aa633;
        border-top: none;
      }

      .box-event-input {
        padding-top: 20px;

        .box-event-btn {
          color: #1298ba;

          a {
            color: #1298ba;
          }

          &.breeding-event {
            color: #e3659a;

            a {
              color: #e3659a;
            }
          }

          &.other-event {
            color: #999636;

            a {
              color: #999636;
            }
          }
        }
      }

      .read-tx {
        margin: 0 10px 20px 20px;
        font-size: 1.4rem;
        font-weight: bold;
      }

      .box-event-btn {
        margin: 10px 10px 25px;

        &:nth-last-of-type(1) {
          margin-bottom: 0;
        }

        p {
          position: relative;
          margin: 0 0 10px 8px;
          padding-left: 30px;
          font-size: 1.5rem;
          font-weight: bold;

          i {
            position: absolute;
            left: 0;
            top: -4px;
            font-size: 2.8rem;
            color: #1298ba;
          }
        }

        &.breeding-event p i {
          color: #e3659a;
          font-size: 2.8rem;
        }

        .other-event p i {
          color: #999636;
          font-size: 2.6rem;
        }

        ul {
          padding-bottom: 12px;
          background-color: #ededed;

          li {
            float: left;
            width: calc(100% / 4 - 20px);
            margin: 10px 10px 0;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            border-radius: 3px;
            background-color: #fff;
            box-shadow: 0 1px 2px 0 rgba(90, 90, 90, 0.3);

            a {
              display: block;
              width: 100%;
              height: 50px;
              padding-top: 19px;
              font-size: 1.3rem;
              box-sizing: border-box;

              &:hover {
                color: #fff;
                text-decoration: none;
                border-radius: 3px;
                background-color: #1298ba;
              }
            }

            &.multi-lines a {
              padding-top: 9px;
              line-height: 1.3;
            }
          }
        }

        &.breeding-event ul li a:hover {
          color: #fff;
          background-color: #e3659a;
        }

        &.other-event ul li a:hover {
          color: #fff;
          background-color: #999636;
        }
      }

      .event-input-step01, .event-input-step02 {
        display: none;
      }

      .edit-regist-list01 dl {
        margin-top: 0;
      }

      .event-input-step02 {
        .edit-regist-list01 {
          padding-left: 7px;
        }

        .table-wrapper {
          height: calc(100vh - 343px);
          margin: 0 10px;
          padding-left: 0;
          padding-right: 0;
          overflow: auto;

          th {
            width: 135px;
          }

          td {
            select, textarea {
              width: 100%;
            }

            select {
              &.min-size {
                width: 5em;
              }

              &.middle-size {
                width: 10em;
              }
            }

            textarea {
              height: 8em;
            }

            .w-date {
              width: 115px;
            }

            select.w-time {
              width: 50px;
            }

            .m-right10 {
              margin-right: 10px;
            }

            .workerName {
              width: 185px;
            }

            .mastitis-bacteria, .mastitis-score {
              width: 150px;
            }

            .breast-group {
              float: left;
            }

            .breast-mr {
              margin-right: 20px;
            }
          }
        }

        .btn-box {
          position: absolute;
          bottom: 4px;
          left: 4px;
          width: calc(100% - 8px);
          padding: 15px 10px;
          background-color: #ededed;

          li {
            display: inline-block;
            margin-right: 18px;

            :nth-last-of-type(1) {
              margin-right: 0;
            }

            button {
              min-width: 100px;
              height: auto;
              padding: 14px 10px;
              font-size: 1.4rem;
              font-weight: bold;
              line-height: 1;

              &.white-btn {
                color: #555;
              }

              &:disabled {
                background-color: #8d8d8d;
                pointer-events: none;
              }
            }
          }
        }
      }
    }

    .timeline-balloon {
      position: relative;
      display: table-cell;

      /*  float: right;  */
      width: 85%;
      padding: 8px;
      background: #fff;
      z-index: 0;

      p {
        margin: 0;
      }

      .category-title {
        display: inline-block;
        margin-bottom: 3px;
        padding: 2px 4px;
        font-size: 1rem;
        color: #fff;
      }

      .medicines {
        display: flex;

        .medicines-label {
          white-space: nowrap;
        }
      }

      .comment {
        word-break: break-all;
        overflow-wrap: break-word;
        max-height: 0px;
        overflow: hidden;
        transition: max-height 0.5s ease;

        p {
          margin-top: 5px;
        }

        section {
          border-top: 1px dotted #ccc;
        }

        .add-tx {
          display: none;
        }

        .expand-open {
          display: block;
          margin-top: 5px;

          &.add-close:before {
            content: "\e903";
            font-family: 'icomoon';
            font-size: 1.4rem;
            vertical-align: middle;
            line-height: 1;
          }
        }
      }

      .comment-item-comment {
        border: 1px dotted #ccc;
        border-radius: 3px;
        padding: 3px 5px;
        margin: 5px 0px;
      }

      .pulldown-btn {
        position: absolute;
        right: 3px;
        top: 3px;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 0;
        text-align: center;
        border-radius: 3px;
        background-color: #dfdfdf;

        i {
          font-size: 1.5rem;
        }
      }
    }

    .pulldown-menu {
      position: absolute;
      float: right;
      right: 2px;
      left: auto;
      min-width: 5.5em;
      top: 19px;
      width: 5.5em;
      height: auto;
      padding: 5px;
      text-align: center;
      background-color: #fff;
      border: 2px solid #b2b2b2;
      box-shadow: 2px 2px 0 0 rgba(150, 150, 150, 0.2);

      a {
        display: block;
        margin-bottom: 5px;
        padding-bottom: 5px;
        color: #333;
        border-bottom: 1px solid #ddd;

        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }

        &:hover {
          text-decoration: none;
        }

        i {
          padding-right: 5px;
        }
      }
    }

    .more {
      position: relative;
      display: block;
      width: 100%;
      height: 37px;
      margin-top: -37px;
      color: #333;
      font-size: 1.1rem;
      text-align: center;
      line-height: 37px;
      background-color: rgba(215, 215, 215, 0.5);

      &:hover {
        text-decoration: none;
      }

      i {
        color: #ff9908;
        font-size: 1.6rem;
      }
    }

    .timeline li {
      .category01 {
        border-left-color: #008c99;

        .timeline--eventNameWrapper {
          border-color: #008c99;
        }

        .category-title {
          background-color: #008c99;
        }

        .timeline--externalLabel {
          color: #008c99;
        }
      }

      .category02 {
        border-left-color: #f56e9d;

        .timeline--eventNameWrapper {
          border-color: #f56e9d;
        }

        .category-title {
          background-color: #f56e9d;
        }

        .timeline--externalLabel {
          color: #f56e9d;
        }
      }

      .category03 {
        border-left-color: #df6feb;

        .timeline--eventNameWrapper {
          border-color: #df6feb;
        }

        .category-title {
          background-color: #df6feb;
        }

        .timeline--externalLabel {
          color: #df6feb;
        }
      }

      .category04 {
        border-left-color: #826ef5;

        .timeline--eventNameWrapper {
          border-color: #826ef5;
        }

        .category-title {
          background-color: #826ef5;
        }

        .timeline--externalLabel {
          color: #826ef5;
        }
      }

      .category05 {
        border-left-color: #6ebff5;

        .timeline--eventNameWrapper {
          border-color: #6ebff5;
        }

        .category-title {
          background-color: #6ebff5;
        }

        .timeline--externalLabel {
          color: #6ebff5;
        }
      }

      .category06 {
        border-left-color: #4dab8a;

        .timeline--eventNameWrapper {
          border-color: #4dab8a;
        }

        .category-title {
          background-color: #4dab8a;
        }

        .timeline--externalLabel {
          color: #4dab8a;
        }
      }

      .category07 {
        border-left-color: #bca446;

        .timeline--eventNameWrapper {
          border-color: #bca446;
        }

        .category-title {
          background-color: #bca446;
        }

        .timeline--externalLabel {
          color: #bca446;
        }
      }

      .category08 {
        border-left-color: #2775be;

        .timeline--eventNameWrapper {
          border-color: #2775be;
        }

        .category-title {
          background-color: #2775be;
        }

        .timeline--externalLabel {
          color: #2775be;
        }
      }

      .category09 {
        border-left-color: #1c90fd;

        .timeline--eventNameWrapper {
          border-color: #1c90fd;
        }

        .category-title {
          background-color: #1c90fd;
        }

        .timeline--externalLabel {
          color: #1c90fd;
        }
      }

      .category10 {
        border-left-color: #666666;

        .timeline--eventNameWrapper {
          border-color: #666666;
        }

        .category-title {
          background-color: #666666;
        }

        .timeline--externalLabel {
          color: #666666;
        }
      }

      .category11 {
        border-left-color: #666666;

        .timeline--eventNameWrapper {
          border-color: #666666;
        }

        .category-title {
          background-color: #666666;
        }

        .timeline--externalLabel {
          color: #666666;
        }
      }

      .category12 {
        border-left-color: #826ef5;

        .timeline--eventNameWrapper {
          border-color: #826ef5;
        }

        .category-title {
          background-color: #826ef5;
        }

        .timeline--externalLabel {
          color: #826ef5;
        }
      }

      .category13 {
        border-left-color: #f56e9d;

        .category-title {
          font-size: 1.2rem !important;
          font-weight: 700;
          padding-left: 0 !important;
          color: #df4278 !important;
          background-color: transparent;
        }
      }

      .category-facility-box {
        position: absolute;
        left: -29px;
        top: 50%;
        margin-left: -1.5rem;
        margin-top: -1.5rem;
        padding: 4px;
        line-height: 1;
        background-color: #7b9915;
        border-radius: 50%;
        z-index: 9999;

        i {
          font-size: 2.5rem;
          color: #fff;
          line-height: 1;
        }
      }
    }
  }

  .edit-regist-list01 dl {
    display: inline-block;
    float: none;
    width: auto;
    margin-left: 0;
    padding: 0;
    font-size: 1.4rem;

    dt, dd {
      display: inline-block;
      float: none;
      width: auto;
      margin-left: 0;
      padding: 0;
      font-size: 1.4rem;
    }

    margin-top: 10px;
    margin-bottom: 15px;
    margin-top: 0;

    dt, dd {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    dt {
      margin-right: 3px;
    }

    dd {
      position: relative;
      margin-right: 12px;
      padding: 0 0 0 0;
      line-height: 1;
      border-radius: 5px;
      border: 1px dashed #ccc;

      &:nth-last-of-type(1) {
        margin-right: 0;
      }

      a {
        display: inline-block;
        padding: 6px 28px 6px 7px;
        color: #333;
        font-size: 1.4rem;

        &:hover {
          background-color: #eee;
          text-decoration: none;
        }
      }

      input.date-ver {
        position: relative;
        width: 120px;
        padding-top: 3px;
        padding-bottom: 3px;
        font-size: 1.4rem;
        border: none;
        background: transparent url("../images/icon-edit.svg") no-repeat 100px 4px;
        background-size: 15px 15px;
        box-shadow: none;
        cursor: pointer;

        :hover {
          background-color: #eee;
        }
      }
    }
  }

 .timeline--head {
    display: grid;
    margin-bottom: 5px;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(2, auto);
  }

  .timeline--eventName {
    margin: 0 !important;
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .timeline--note {
    padding: 5px 0 0 0;
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .timeline--daysAgo {
    padding: 0 12px 0 16px;
    text-align: right;
    grid-column: 2/3;
    grid-row: 1/2;
  }

  // 個別タイムライン上での牛番号のリンクを非表示にする
  .timeline--cowIdLabel {
    display: none;
  }

  .isInputPathExternal {
    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, auto);

    .timeline--eventNameWrapper {
      display: flex;
      border-width: 1px;
      border-style: solid;
    }

    .timeline--note {
      padding: 5px 0 0 0;

      grid-column: 1/3;
      grid-row: 2/3;
    }

    .timeline--externalLabel {
      font-size: 12px;
      display: inline-grid !important;
      padding: 0 4px !important;

      align-items: center;
      justify-content: center;
    }

    .timeline--daysAgo {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }
}

@-webkit-keyframes flash {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes flash {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.main-header, .sub-header, .main-nav {
  display: none;
}

// 2017/11/07 追記 コンテンツが少なくてもフッターが最下部になるように
.sid-home, .sid-list {
  min-height: calc(100vh - 5.5rem);
  display: flex;
  flex-direction: column;
}

.box-history #timeline-cow {
  .startline {
    padding-left: 30px;
    font-size: 1.4rem;
    &::before {
      left: 0px;
    }
  }

  .timeline-year-wrapper {
    background: #fff;
    border-bottom: 1px solid #ddd;
    padding: 10px 0 0 0;
    color: #666;
    font-size: 2.1rem;
    &.display-none {
      display: none;
    }
    .timeline-year {
      width: 95%;
      height: 30px;
      padding: 5px 0;
      text-align: center;
      .timeline-year-span {
        // background: $desamisGreen;
        color: #fff;
        padding: 3px 10px;
        border-radius: 1rem;
      }
    }
  }

  .timeline {
    border-left: 3px solid #ddd;
    clear: both;
    margin-left: 8px;
    padding-bottom: 25px;
    padding-top: none;
    position: relative;

    li {
      margin-bottom: 15px;
      margin-left: -3px;
      position: relative;
      width: 100%;
      border-bottom: 1px solid #ddd;
      padding-bottom: 0px;

      div.data-box {
        background-color: #fff;
        display: table-cell;
        padding-top: 5px;
        position: relative;
        vertical-align: super;
        width: 15%;
        border-right: 1px solid #eee;
        p {
          margin-bottom: 0;
        }
      }

      .data-box {
        &::after, &::before {
          display: none;
        }
      }

      .timeline-content-wrapper {
        border: none;
        display: table;
        position: relative;
        width: 100%;
        border-bottom: none;
        border-left: 3px solid;
      }

      .category01 {
        border-left-color: #008c99;
      }

      .category02 {
        border-left-color: #f56e9d;
      }

      .category03 {
        border-left-color: #df6feb;
      }

      .category04 {
        border-left-color: #826ef5;
      }

      .category05 {
        border-left-color: #6ebff5;
      }

      .category06 {
        border-left-color: #4dab8a;
      }

      .category07 {
        border-left-color: #bca446;
      }

      .category08 {
        border-left-color: #2775be;
      }

      .category09 {
        border-left-color: #1C90FD;
      }

      .category10, .category11 {
        border-left-color: #666;
      }

      .category12 {
        border-left-color: #826ef5;
      }

      .category13 {
        border-left-color: #f56e9d;
      }

      .category-all {
        border-left-color: #7b9915;
      }

      .data {
        font-family: arial;
        font-size: 1.3rem;
        font-weight: 700;
        line-height: 1.4;
        text-align: center;
        max-height: 100%;
      }

      .year-mark {
        display: none;
      }

      .last-event-of-year .timeline-content-wrapper {
        margin-top: 70px;
        .year-mark {
          position: absolute;
          width: calc(100% + 11px);
          height: 44px;
          padding: 10px 0 0 11px;
          top: -55px;
          left: -11px;
          display: block;
          background: #fff;
          border-top: 1px solid #ddd;
          border-bottom: 1px solid #ddd;
          color: #666;
          font-size: 2.1rem;
        }
      }
    }
  }

  .timeline-balloon {
    background: #fff;
    display: table-cell;
    padding: 8px 20px 8px 8px;
    position: relative;
    width: 85%;
    font-size: 1.3rem;
    z-index: 0;
    .timeline-balloon-toggle-contents {
      display: none;
    }
    &.togglable {
      &:hover {
        cursor: pointer;
      }
      .timeline-balloon-toggle-contents {
        display: block;
        text-align: right;
      }
    }
    .pulldown-btn {
      background-color: #fff;
      border-radius: 3px;
      display: block;
      height: 1.6rem;
      line-height: 0;
      position: absolute;
      right: 3px;
      text-align: center;
      top: 10px;
      text-decoration: none;
      width: 1.6rem;
      color: #333;
      border: 1px solid #ccc;

      .icon-css-arrow {
        position: absolute;
        top: 3px;
        left: 4px;
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(135deg);
        border-top: 1px solid #333;
        border-right: 1px solid #333;
      }
    }
  }

  .category-title {
    color: #fff;
    display: inline-block;
    font-size: 1.3rem;
    margin-bottom: 5px;
    padding: 1px 3px;
    border-bottom: none;
    font-weight: none;
    margin-top: none;
  }

  .timeline li {
    &::after, &::before {
      display: none;
    }
  }
}

.input-label {
  display: inline-block;
  min-width: 75px;
  text-align: right;
}

.timeline-filter-menu {
  position: relative;

  .button-bar {
    text-align: right;
    width: 100%;
    padding: 2px 0px 2px 0px;

    .toggle-filter-button {
      border-radius: 6px;
      margin: 1em 0.5em;
      padding: 0.5em;
    }
  }

  .filter-box {
    margin: 0em 0em 0em 0em;
    position: relative;
    width: 100%;
    padding: 5px;
    color: black;
    border: 1px solid #aaa;
    background-color: white;
    border-radius: 2px;
    z-index: 9999;
    display: flex;
    flex-flow: column nowrap;
    text-align: left;

    .filter-tool-box {
      padding: 0.2em 0.5em;
      text-align: right;

      a {
        color: white;
      }

      button {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 2px 5px;

        &:hover {
          background-color: white;
          color: black;
        }
      }
    }

    .filter-group-box {
      display: flex;
      flex-flow: row nowrap;
      margin: .5em .5em 0em;
      padding: .5em 0 0 0;
      border-top: 1px solid #aaa;
    }

    .filter-title-box {
      flex-grow: 1;
      flex-basis: 25%;
      border-right: 1px solid rgba(0, 0, 0, 0.3);
      font-size: 14px;

      &-input {
        margin: 0.2em 0.2em 0.3em 0;
      }
    }

    .filter-form-box {
      flex-grow: 4;
      flex-basis: 75%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 0 0 10px;

      .filter-form-item {
        flex-basis: 33.33%;
        margin: 0 0;
        vertical-align: middle;

        label input {
          margin: 0.2em 0.2em 0.3em 0;
        }
      }

      .filter-form-item-2col {
        flex-basis: 50%;
      }

      .filter-form-item-3col {
        flex-basis: 60%;
      }

      .filter-form-item-2col label input {
        margin: 0.2em 0.2em 0.3em 0;
      }
    }

    select {
      vertical-align: middle;
    }

    .days, .bcs, .count {
      width: 8rem;
    }
  }
}

.tab-content #activity h4 {
  margin-bottom: .8em;
  font-size: 1.5rem;
}


/* Unique Style of 'Login'
----------------------------------------*/

.login {
  font-size: 1.4rem;
  margin: 0 auto;
  padding-top: 8rem;
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: auto;
  top: 0;
  background-color: #fff;

  .l-alert {
    background-color: #f4ebeb;
    border: 0.1rem solid #d22424;
    color: #d22424;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 1.5rem;
    line-height: 1.2;
    margin: 2.4rem auto;
    padding: 1.2rem;
    max-width: 40rem;
  }

  .l-form {
    background-color: #f0f0f0;
    margin: 0 -1.5rem 3.2rem;
    padding: 4rem 1.6rem;
  }

  .l-form_button {
    background-image: linear-gradient(-80deg, rgba(64, 131, 150, 0.74) 0%, rgba(6, 58, 117, 0.74) 100%);
    background-color: #6c9fad;
    border: none;
    border-radius: 0.2rem;
    color: #fff;
    font-size: 1.8rem;
    height: 5rem;
    transition: background .3s ease;
    width: 100%;

    &:hover, &:focus {
      background-color: #133a65;
      outline: none;
    }
  }

  .l-form_checkbox {
    opacity: 0;
    position: absolute;

    ~ [class^="icon-check"] {
      color: #999;
      display: inline-flex;
      align-items: center;
      font-size: 2.4rem;
      height: 3.2rem;
      margin-right: 0.5rem;
      transition: color .3s ease;

      &::before {
        content: "\e917";
      }
    }

    &:checked ~ [class^="icon-check"] {
      animation: .08s linear input-checked;
      color: #477d17;

      &::before {
        content: "\e916";
      }
    }

    &:focus ~ [class^="icon-check"] {
      color: #477d17;
    }

    &.is-clear ~ [class^="icon-check"] {
      color: #999;
    }
  }

  .l-form_field {
    border: none;
    border-bottom: 0.1rem solid transparent;
    border-radius: 0;
    font-size: 1.6rem;
    font-weight: 400;
    font-family: "Original Yu Gothic", "Yu Gothic", YuGothic, Arial, Meiryo, sans-serif;
    line-height: 1.2;
    order: 1;
    padding: 1.4rem 1.2rem;
    transition: border .3s ease;
    width: 100%;

    &:focus {
      border-bottom-color: #295688;
      outline: none;
      box-shadow: none;
      -moz-box-shadow: none;
      -webkit-box-shadow: none;

      ~ b {
        color: #295688;
      }
    }
  }

  .l-form_group {
    margin-bottom: 3.2rem;

    &[data-style="checkbox"] {
      display: inline-flex;
    }
  }

  .l-form_label {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    b {
      display: block;
      line-height: 1.2;
      margin-bottom: 1.2rem;
      transition: color .3s ease;
    }

    &:focus b {
      color: #295688;
    }

    &.is-alert {
      b {
        color: #d22323;
      }

      .l-form_field {
        border-bottom-color: #d22323;
      }
    }
  }

  .l-title {
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 4rem;
    margin-top: 0;
    text-align: center;
  }

  .l-title_object {
    display: block;
    margin: 0 auto 0.5rem;
    width: 7.7rem;
  }

  .l-title + .l-alert {
    margin-top: -1.6rem;
  }

  .l-utility {
    line-height: 1.2;
    margin-bottom: 7rem;
    text-align: center;

    a {
      font-size: 14px;
      color: inherit;
      text-decoration: none;

      &:hover {
        color: inherit;
        text-decoration: underline;
      }
    }
  }
}

@media (min-width: 451px) {
  .login {
    display: table-cell;
    padding-top: 6rem;

    &.is-collapse {
      padding-top: 0;
      vertical-align: middle;
    }
  }
}

a:focus {
  color: inherit;
  text-decoration: underline;
}

@media (pointer: none) {
  .login .l-utility a[href^="tel:"] {
    pointer-events: none;
  }
}

.login .l-form {
  padding-right: 2.8rem;
  padding-left: 2.8rem;
}

@media (min-width: 451px) {
  .login {
    .l-container {
      margin-bottom: 10rem;
      padding-top: 4rem;
    }

    .l-form {
      box-shadow: 0 0.2rem 0.8rem rgba(62, 62, 62, 0.5);
      margin-right: auto;
      margin-left: auto;
      padding-right: 2.8rem;
      padding-left: 2.8rem;
      width: 40rem;
    }

    .l-form_group[data-style="checkbox"] {
      margin-top: -0.8rem;
      margin-bottom: 2.4rem;
    }

    .l-utility {
      margin-bottom: 0;

      a {
        text-decoration: underline;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@keyframes input-checked {
  0% {
    transform: scale(0.6);
  }

  90% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}


.sipmle-header-area {
  margin-bottom: 20px;
}

.sipmle-title {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 24px;
}

.sipmle-mb20 {
  margin-bottom: 20px;
}

.sipmle-contents {
  padding: 15px;
}

.simple-item-container {
  margin-bottom: 10px;
}

.simple-w80p {
  width: 80%;
}

.simple-w100p {
  width: 100%;
}

.temporary-admin-menu {
  margin-left: 10px;
  margin-top: 10px;
  color: white;
}

.md-footer {
  display: flex;
  justify-content: flex-end;
  padding: 1.6rem .8rem;

  .md-button {
    background-color: #fff;
    border: none;
    color: #777;
    font-size: calc(1em + .1rem);
    padding: .6rem .8rem;
    min-width: 6rem;

    &.button-continue {
      font-weight: 700;
      color: #366678;
      border-radius: 4px;
      background: #f0f0f0;
    }

    ~ .md-button {
      margin-left: .8rem;
    }

    &[disabled] {
      opacity: .5;
      color: #d9d9d9;
      background: #e5e5e5;
    }
  }
}

.md-body {
  padding: 2.5rem 1.6rem 0;
}

.module-form {
  &.md-body {
    background-color: transparent;
    margin-bottom: 0;
  }

  .warning-message {
    padding: 0 0 1.6rem 0;
    color: #ff9908;
    font-size: 1.4rem;
  }

  background-color: #fff;
  padding: 2rem 1.6rem 0;

  .mf-icon, .mf-unit {
    color: #777;
    font-size: calc(1em + .4rem);
    pointer-events: none;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  .mf-icon:first-child, .mf-unit:first-child {
    left: 1.2rem;
  }

  .mf-icon:last-child, .mf-unit:last-child {
    right: 1.2rem;
  }

  .mf-action {
    background-color: #f2f2f2;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin: 8.8rem -1.6rem 0;
    padding: 3.5rem .6rem 1.5rem;
  }

  .mf-action_button {
    background-color: #fff;
    border-color: transparent;
    height: 5.6rem;
    font-size: 17px;
    margin-right: 1rem;
    margin-left: 1rem;
    width: 100%;

    &[type="submit"] {
      background-color: #5d7836;
      color: #fff;
      font-weight: 700;

      &:not(:first-child) {
        width: 162.5%;
      }
    }
  }

  .mf-addgroup {
    border-top: .1rem solid #ddd;
    border-bottom: .1rem solid #ddd;
    margin: 5rem auto 2rem;
    padding: 4.5rem 0 2.5rem;
    position: relative;
  }

  .mf-addgroup_button {
    background-color: #f2f2f2;
    border: none;
    height: 2.5rem;
    padding: 0;
    width: 2.5rem;

    &.button-close {
      font-size: calc(1em + 1.3rem);
      position: absolute;
      top: 4.1rem;
      right: 0;
      line-height: 1;
    }

    .icon-css-cancel {
      position: relative;
      display: block;
      width: 25px;
      height: 25px;

      &::before {
        position: absolute;
        top: 12px;
        left: 5px;
        display: block;
        width: 15px;
        height: 1px;
        content: '';
        transform: rotate(45deg);
        background-color: #000;
      }

      &::after {
        position: absolute;
        top: 12px;
        left: 5px;
        display: block;
        width: 15px;
        height: 1px;
        content: '';
        transform: rotate(-45deg);
        background-color: #000;
      }
    }
  }

  .mf-addgroup {
    + .mf-addgroup, &.mf-no-border {
      border-top: none;
      margin-top: -2rem;
    }
  }

  .mf-checkbox {
    font-size: calc(1em + .2rem);
    margin-bottom: 1.2rem;
  }

  .mf-checkbox_label {
    font-weight: 400;
    margin-bottom: 0;
    padding-left: 2.3rem;
    position: relative;

    &::before {
      background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23b5b5b5%3B%7D%20.st1%7Bdisplay%3Anone%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20class%3D%22st0%22%20d%3D%22M34.7%2C8v25.8c0%2C1-0.8%2C1.8-1.8%2C1.8H7.1c-1%2C0-1.8-0.8-1.8-1.8V8c0-1%2C0.8-1.8%2C1.8-1.8h25.8%20C33.9%2C6.2%2C34.7%2C7%2C34.7%2C8z%20M32.9%2C9.3c0-0.8-0.6-1.4-1.4-1.4h-23C7.7%2C8%2C7.1%2C8.6%2C7.1%2C9.3v23c0%2C0.8%2C0.6%2C1.4%2C1.4%2C1.4h23%20c0.8%2C0%2C1.4-0.6%2C1.4-1.4C32.9%2C32.3%2C32.9%2C9.3%2C32.9%2C9.3z%22%2F%3E%3C%2Fg%3E%3Ccircle%20class%3D%22st1%22%20cx%3D%2228.7%22%20cy%3D%2228.2%22%20r%3D%226.9%22%2F%3E%3C%2Fsvg%3E');
      content: "";
      height: 1.8rem;
      margin-right: .5rem;
      position: absolute;
      top: .1em;
      left: 0;
      width: 1.8rem;
    }
  }

  .mf-checkbox input:checked + .mf-checkbox_label::before {
    background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bdisplay%3Anone%3B%7D%20.st1%7Bfill%3A%23366678%3B%7D%3C%2Fstyle%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2228.747%22%20cy%3D%2228.155%22%20r%3D%226.934%22%2F%3E%3Cg%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M32.879%2C6.206H7.122c-1.015%2C0-1.84%2C0.825-1.84%2C1.84v25.757c0%2C1.017%2C0.825%2C1.84%2C1.84%2C1.84h25.757%20c1.017%2C0%2C1.84-0.823%2C1.84-1.84V8.045C34.719%2C7.03%2C33.896%2C6.206%2C32.879%2C6.206z%20M29.164%2C17.046L18.861%2C27.35%20c-0.218%2C0.218-0.508%2C0.338-0.816%2C0.338s-0.598-0.12-0.815-0.338l-6.392-6.39c-0.218-0.218-0.338-0.507-0.338-0.816%20c0-0.308%2C0.12-0.598%2C0.338-0.815c0.45-0.45%2C1.182-0.452%2C1.634%2C0l5.574%2C5.574l9.487-9.488c0.448-0.449%2C1.182-0.45%2C1.632%2C0%20C29.613%2C15.864%2C29.613%2C16.597%2C29.164%2C17.046z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  }

  .mf-checkboxgroup {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mf-checkboxgroup_item {
    border: .1rem solid #ddd;
    border-right: none;
    color: #ccc;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    min-height: 4.5rem;
    font-size: calc(1em + .4rem);
    width: 50%;
  }

  .mf-checkboxgroup um-form-input {
    &:last-child .mf-checkboxgroup_item, &:nth-child(even) .mf-checkboxgroup_item {
      border-right: .1rem solid #ddd;
    }

    &:nth-child(n+3) .mf-checkboxgroup_item {
      border-top: none;
    }
  }

  .mf-checkboxgroup_label {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 400;
    margin: 0;
    padding: .9rem 1.3rem;
    transition: all .3s ease;
    width: 100%;

    &::before {
      background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23ccc%3B%7D%20.st1%7Bdisplay%3Anone%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20class%3D%22st0%22%20d%3D%22M34.7%2C8v25.8c0%2C1-0.8%2C1.8-1.8%2C1.8H7.1c-1%2C0-1.8-0.8-1.8-1.8V8c0-1%2C0.8-1.8%2C1.8-1.8h25.8%20C33.9%2C6.2%2C34.7%2C7%2C34.7%2C8z%20M32.9%2C9.3c0-0.8-0.6-1.4-1.4-1.4h-23C7.7%2C8%2C7.1%2C8.6%2C7.1%2C9.3v23c0%2C0.8%2C0.6%2C1.4%2C1.4%2C1.4h23%20c0.8%2C0%2C1.4-0.6%2C1.4-1.4C32.9%2C32.3%2C32.9%2C9.3%2C32.9%2C9.3z%22%2F%3E%3C%2Fg%3E%3Ccircle%20class%3D%22st1%22%20cx%3D%2228.7%22%20cy%3D%2228.2%22%20r%3D%226.9%22%2F%3E%3C%2Fsvg%3E');
      content: "";
      height: 1.8rem;
      margin-right: .5rem;
      margin-bottom: .2rem;
      width: 1.8rem;
    }
  }

  .mf-checkboxgroup input:checked + .mf-checkboxgroup_label {
    background-color: #366678;
    color: #fff;

    &::before {
      background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bdisplay%3Anone%3B%7D%20.st1%7Bfill%3A%23FFFFFF%3B%7D%3C%2Fstyle%3E%3Ccircle%20class%3D%22st0%22%20cx%3D%2228.747%22%20cy%3D%2228.155%22%20r%3D%226.934%22%2F%3E%3Cg%3E%20%3Cpath%20class%3D%22st1%22%20d%3D%22M32.879%2C6.206H7.122c-1.015%2C0-1.84%2C0.825-1.84%2C1.84v25.757c0%2C1.017%2C0.825%2C1.84%2C1.84%2C1.84h25.757%20c1.017%2C0%2C1.84-0.823%2C1.84-1.84V8.045C34.719%2C7.03%2C33.896%2C6.206%2C32.879%2C6.206z%20M29.164%2C17.046L18.861%2C27.35%20c-0.218%2C0.218-0.508%2C0.338-0.816%2C0.338s-0.598-0.12-0.815-0.338l-6.392-6.39c-0.218-0.218-0.338-0.507-0.338-0.816%20c0-0.308%2C0.12-0.598%2C0.338-0.815c0.45-0.45%2C1.182-0.452%2C1.634%2C0l5.574%2C5.574l9.487-9.488c0.448-0.449%2C1.182-0.45%2C1.632%2C0%20C29.613%2C15.864%2C29.613%2C16.597%2C29.164%2C17.046z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
    }
  }

  .mf-field {
    position: relative;
    width: 100%;

    .form-control {
      background-color: #f2f2f2;
      border-color: transparent;
      border-radius: 0;
      box-shadow: none;
      font-size: 1.6rem;
      height: 4.5rem;
      padding: 1rem 1.2rem;
      resize: none;

      &::-webkit-input-placeholder, &:placeholder-shown {
        color: #ccc;
      }

      &[disabled] {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        border-color: #ddd;
        border-style: dashed;
        color: #777;
      }

      &[rows] {
        height: auto;
      }

      &.has-icon:not(:last-child):not(select), &.has-clear:not(:last-child):not(select), &.has-unit:not(:last-child):not(select) {
        padding-right: 4.2rem;
      }

      &.has-icon:not(:first-child):not(select), &.has-clear:not(:first-child):not(select), &.has-unit:not(:first-child):not(select) {
        padding-left: 4.2rem;
      }
    }

    .mf-clear {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 45px;
      height: 45px;

      &::before {
        position: absolute;
        top: 21px;
        left: 14px;
        display: block;
        width: 18px;
        content: "";
        transform: rotate(45deg);
        border-top: 2px solid #666;
      }

      &::after {
        position: absolute;
        top: 21px;
        left: 14px;
        display: block;
        width: 18px;
        content: "";
        transform: rotate(-45deg);
        border-top: 2px solid #666;
      }

      &.is-error {

        &::before,
        &::after {
          border-top-color: #d22323;
        }
      }
    }
  }

  .mf-field_action {
    margin-top: 2rem;

    .mf-button {
      background: none;
      border: .1rem dotted #ccc;
      border-radius: .45rem;
      color: #777;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: calc(1em + .1rem);
      font-weight: 700;
      height: 4.2rem;
      margin-left: auto;
      padding: 0 1rem;

      [class*="icon"] {
        font-size: calc(1em + .9rem);
        margin-right: 1rem;
      }

      .icon-css-plus {
        position: relative;
        display: block;
        width: 20px;
        height: 20px;

        &::before {
          position: absolute;
          top: 8px;
          left: 3px;
          display: block;
          width: 14px;
          height: 1px;
          content: '';
          background-color: #000;
        }

        &::after {
          position: absolute;
          top: 8px;
          left: 3px;
          display: block;
          width: 14px;
          height: 1px;
          content: '';
          transform: rotate(90deg);
          background-color: #000;
        }
      }
    }
  }

  .mf-group {
    margin: 0 auto 4.8rem;

    .row {
      margin-right: -.8rem;
      margin-left: -.8rem;
    }

    [class*="col-"] {
      padding-right: .8rem;
      padding-left: .8rem;
    }
  }

  .mf-group_help {
    display: block;
    font-size: calc(1em + .3rem);
    line-height: 1.2;
    margin-top: .5rem;

    &.is-error-only {
      margin-top: -4.8rem;
    }
  }

  .mf-group {
    .is-active, .form-control:focus {
      background-color: #fff;
      border-bottom-color: #537b2b;
    }

    &.is-error {
      color: #d22323;

      .mf-field * {
        color: #d22323;
      }

      .form-control {
        background-color: #fff;
        border-bottom-color: #d22323;
      }
    }
  }

  .mf-horizontal {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    .form-control {
      background: #fff;
      border-bottom-color: #ddd;
      color: inherit;
      font-weight: 700;
    }

    .mf-label {
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      margin-right: .5rem;
      margin-bottom: 0;
    }
  }

  .mf-icon {
    color: #666;
    font-size: calc(1em + 1rem);
  }

  .mf-label {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }

  .mf-label_icon {
    border-radius: 0;
    font-size: 13px;
    font-weight: 400;
    margin-left: 1rem;
    padding: .2rem .6rem;
  }

  .mf-label .label-danger {
    background-color: #d22323;
  }

  .mf-radio {
    font-size: calc(1em + .2rem);
    margin-bottom: 1.2rem;

    input:checked + .mf-radio_label {
      &::before {
        border-color: #366678;
      }

      &::after {
        background-color: #366678;
      }
    }
  }

  .mf-radio_label {
    font-weight: 400;
    margin-bottom: 0;
    padding-left: 2.5rem;
    position: relative;

    &::before, &::after {
      border-radius: 50%;
      content: "";
      position: absolute;
      top: .2em;
      left: 0;
      transition: all .3s ease;
    }

    &::before {
      border: .1rem solid #b5b5b5;
      height: 1.5rem;
      width: 1.5rem;
    }

    &::after {
      background-color: #fff;
      height: .9rem;
      top: calc(.2em + .3rem);
      left: .3rem;
      width: .9rem;
    }
  }

  .mf-radiogroup {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }

  .mf-radiogroup_item {
    border: .1rem solid #ddd;
    border-right: none;
    color: #ccc;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    height: 4.5rem;
    font-size: calc(1em + .4rem);
    width: 100%;
    &:last-child {
      border-right: .1rem solid #ddd;
    }
  }

  um-form-input:last-child .mf-radiogroup_item {
    border-right: .1rem solid #ddd;
  }

  .mf-radiogroup_label {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 400;
    margin: 0;
    transition: all .3s ease;
    width: 100%;
  }

  .mf-radiogroup input:checked + .mf-radiogroup_label {
    background-color: #366678;
    color: #fff;
  }

  .mf-select {
    &::after {
      border-color: #999 transparent transparent transparent;
      border-style: solid;
      border-width: .75rem .45rem 0 .45rem;
      content: "";
      height: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      right: 1.2rem;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: 0;
    }

    select.form-control {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding-right: 3rem;

      &.has-unit {
        padding-right: 6rem;
      }
    }

    .mf-unit:last-child {
      right: 3.2rem;
    }
  }

  .mf-subgroup {
    margin: 2rem auto;

    .mf-label {
      color: #777;
    }
    &.is-error {
      *.is-error {
        color: #d22323;
      }

      .form-control.is-error {
        background-color: #fff;
        border-bottom-color: #d22323;
      }
    }
  }

  .mf-table {
    color: #555;
    font-size: calc(1em + .2rem);
    line-height: 1.4;
    margin-top: 2rem;
  }

  .mf-table_data {
    padding: .2rem 0;
    vertical-align: top;
  }

  .mf-table_head {
    padding: .2rem 0;
    vertical-align: top;
    font-weight: 400;
    text-align: right;
    white-space: nowrap;
    width: 10%;
  }

}


/*
  NOTE: um-form 独自タグが独自にwidthをしてしてしまうのを避ける対応
  */
.mf-radiogroup um-form-input {
  width: 100%;
}

.mf-checkboxgroup um-form-input {
  width: 50%;

  .mf-checkboxgroup_item {
    width: 100%;
  }
}


/* イベント登録画面で余白を削除する */
main.layout-main.event-register-mobile {
  padding-bottom: 0;
}

/* モバイル版 タイムライン・ボタン用CSS */
.detail-tab .timeline {
  margin: 0;
}

.box-history #timeline-cow {
  .timeline {
    padding-top: 2rem;
  }
  .box-history-innner {
    @media (orientation: landscape) {
      padding-top: 30px;
    }
  }
}

.timeline-header {
  width: 100%;
  padding: 0 1rem;
  z-index: 2;
  background-color: #fff;

  &.is-filter-shown {
    height: 100%;
    overflow: auto;
  }

  @media (orientation: landscape) {
    position: relative;
    height: auto !important;
  }
}

.timeline-control {
  border-bottom: .2rem solid #ddd;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;

  .tc-button {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: calc(1em + .2rem);
    font-weight: 700;
    position: relative;
    width: 50%;

    * {
      width: 100%;
    }

    a, button {
      background-color: #fff;
      border: none;
      border-radius: 0 !important;
      color: inherit;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      min-height: 4.5rem;
      margin: 0 !important;
      padding: 0 1.5rem 0 4.4rem !important;
      position: relative;
    }

    a *, button * {
      width: auto;
    }

    a [class*="icon"], button [class*="icon"] {
      font-size: calc(1em + .8rem);
      position: absolute;
      top: 50%;
      left: 1.2rem;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    a .icon-s-filter, button .icon-s-filter {
      padding: .4rem !important;
    }

    .button-bar {
      border-left: none;
      margin-left: 0;
      padding: 0;
      text-align: left;
      width: 100%;
    }

    &:not(:first-child)::before {
      background-color: #ddd;
      content: "";
      display: block;
      height: 3.3rem;
      position: absolute;
      top: 50%;
      left: -.1rem;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      width: .2rem;
      z-index: 1;
    }
  }
}

@media (orientation: landscape) {
  .timeline-control {
    border-bottom: none;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;

    &::before {
      background-color: #ddd;
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      left: 3.6rem;
      width: .3rem;
    }
  }
}

@media (orientation: landscape) {
  .timeline-control .tc-button {
    margin-left: 1.2rem;
    width: auto;
    min-width: 14.9rem;

    a, button {
      background-color: #f2f2f2;
    }

    &:not(:first-child)::before {
      display: none;
    }
  }
}

@media (orientation: portrait) {
  .timeline-control .tc-button {
    a [class*="icon"], button [class*="icon"] {
      border-radius: 50%;
      padding: .5rem;
    }

    a .icon-s-filter, button .icon-s-filter {
      padding: .3rem;
    }
  }
}


/* モバイル版イベント選択画面 */

.event-entry {
  background-color: #fff;
  padding: 3rem 1.6rem 0;

  .ee-lead {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 3.5rem;
  }

  .ee-section {
    padding-bottom: 4.8rem;
  }

  .ee-section_button {
    background-color: #fff;
    border-radius: .3rem;
    box-shadow: 0 0.1rem 0.2rem rgba(77, 77, 77, 0.5);
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 700;
    line-height: 1.2;
    min-height: 6.8rem;
    text-align: center;
    width: 100%;
  }

  .ee-section_list {
    background-color: #f0f0f0;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-size: 15px;
    margin-right: -1.6rem;
    margin-left: -1.6rem;
    padding: 1.6rem .8rem 0;
  }

  .ee-section_listItem {
    margin-bottom: 1.6rem;
    padding-right: .8rem;
    padding-left: .8rem;
    width: 33.3333333333%;
  }

  .ee-section_title {
    font-size: 15px;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }

  .ee-breeding {
    color: #e3659a;
  }

  .ee-other {
    color: #999636;
  }

  .ee-treatment {
    color: #1298ba;
  }
}

@media (max-width: 568px) and (orientation: landscape) {
  .event-entry {
    font-size: 1.2rem;
  }
}

@media (orientation: landscape) {
  .event-entry .ee-section_listItem {
    width: 20%;
  }
}

.modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 12px;
}

/* イベント登録モーダル用 */
.modal-event-register-lg {
  &.modal-content {
    width: 100%;
    height: 100%;
  }
}

/* jQueryデートピッカー */
.modal-content header.layout-header + main.layout-main {
  margin-top: 50px;
  padding-top: 0;
}

.ui-datepicker {
  top: auto !important;
  bottom: -500px;
  left: 0 !important;
  display: block !important;
  visibility: hidden;
  overflow: hidden;
  width: 100%;
  padding: 0 0 env(safe-area-inset-bottom);
  transition-timing-function: ease;
  transition-duration: 400ms;
  transition-property: bottom, opacity, visibility;
  opacity: 0;
  border-radius: 16px 16px 0 0;
  background: #fff;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.5);

  @media (orientation: landscape) {
    display: grid !important;
    padding: 12px calc((100vw - 444px) / 2) calc(env(safe-area-inset-bottom) + 8px);

    grid-template-columns: auto 1fr;
    grid-template-rows: repeat(2, auto);
    column-gap: 4px;

    @media (min-height: 360px) {
      padding: 28px calc((100vw - 520px) / 2) calc(env(safe-area-inset-bottom) + 16px);

      column-gap: 8px;
    }
  }

  &-header {
    display: grid;
    width: 304px;
    margin: auto;
    padding: 21px 0 16px;

    align-items: center;
    grid-template-columns: 1fr repeat(2, 40px);
    grid-template-rows: 40px;
    gap: 4px;

    @media (min-width: 360px) {
      width: 328px;
      padding-top: 28px;

      gap: 8px;
    }

    @media (orientation: landscape) {
      width: 100%;
      margin: 0;
      padding: 40px 0 0;

      justify-content: flex-end;
      grid-template-columns: repeat(3, 40px);
      grid-template-rows: repeat(2, 40px);
      grid-column: 1/2;
      grid-row: 1/2;
      row-gap: 4px;

      @media (min-height: 360px) {
        grid-template-columns: repeat(4, 40px);
      }
    }
  }

  &-title {
    font-size: 17px;
    font-weight: bold;
    margin: 0;
    padding-left: 12px;
    color: #212121;

    grid-column: 1/2;
    grid-row: 1/2;

    @media (min-width: 360px) {
      padding-left: 9px;
    }

    @media (orientation: landscape) {
      font-size: 21px;
      padding-left: 4px;

      grid-column: 1/4;
      grid-row: 1/2;

      @media (min-height: 360px) {
        grid-column: 1/5;
      }
    }
  }

  &-prev {
    display: grid;
    height: 40px;
    border-radius: 50%;

    grid-column: 2/3;
    grid-row: 1/2;
    align-items: center;
    justify-content: center;

    &::before {
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 4px;
      content: "";
      transform: rotate(-45deg);
      border-top: 2px solid #212121;
      border-left: 2px solid #212121;
    }

    @media (orientation: landscape) {
      grid-column: 1/2;
      grid-row: 2/3;
    }

    .ui-icon {
      display: none;
    }
  }

  &-next {
    display: grid;
    height: 40px;
    border-radius: 50%;

    grid-column: 3/4;
    grid-row: 1/2;
    align-items: center;
    justify-content: center;

    &::before {
      display: block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
      content: "";
      transform: rotate(45deg);
      border-top: 2px solid #212121;
      border-right: 2px solid #212121;
    }

    @media (orientation: landscape) {
      grid-column: 2/3;
      grid-row: 2/3;
    }

    .ui-icon {
      display: none;
    }
  }

  &-calendar {
    display: grid;
    margin: 0;

    align-items: center;
    justify-content: center;

    @media (orientation: landscape) {
      grid-column: 2/3;
      grid-row: 1/3;
      justify-content: flex-start;
    }

    thead {
      display: block;

      tr {
        display: grid;

        grid-template-columns: repeat(7, 40px);
        gap: 4px;

        @media (min-width: 360px) {
          gap: 8px;
        }

        @media (orientation: landscape) {
          gap: 4px;

          @media (min-height: 360px) {
            gap: 8px;
          }
        }
      }

      th {
        display: block;

        span {
          font-size: 15px;
          font-weight: normal;
          display: grid;
          width: 40px;
          height: 40px;
          color: #666;

          align-items: center;
          justify-content: center;
        }
      }
    }

    tbody {
      display: grid;

      gap: 4px;
      grid-template-rows: repeat(6, 40px);

      tr {
        display: grid;

        grid-template-columns: repeat(7, 40px);
        gap: 4px;

        @media (min-width: 360px) {
          gap: 8px;
        }

        @media (orientation: landscape) {
          gap: 4px;

          @media (min-height: 360px) {
            gap: 8px;
          }
        }

        td {
          position: relative;
          display: block;
          width: 40px;
          height: 40px;
          color: #666;
          border-radius: 50%;

          &.ui-datepicker-other-month {
            color: #adadad;
          }
        }

        .ui-state-default {
          font-size: 17px;
          display: grid;
          width: 40px;
          height: 40px;
          text-decoration: none;
          color: inherit;

          align-items: center;
          justify-content: center;
        }

        .ui-datepicker-today {
          .ui-state-default {
            font-weight: bold;
            color: #0e52a2;
            border: 2px solid #2a66b7;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &-buttonpane {
    padding: 20px 0 12px;
    text-align: center;

    @media (orientation: landscape) {
      display: grid;
      padding: 0 0 44px;
      text-align: left;

      grid-column: 1/2;
      grid-row: 2/3;
      align-self: flex-end;
      justify-content: flex-end;
      grid-template-columns: 104px 32px;

      @media (min-height: 360px) {
        grid-template-columns: 104px 80px;
      }
    }

    .ui-datepicker-current {
      display: none;
    }

    .ui-datepicker-close {
      font-size: 15px;
      position: relative;
      width: 100px;
      height: 40px;
      padding: 0 0 0 17px;
      color: #212121;
      border: none;
      border-radius: 9999px;
      background: none;

      @media (orientation: landscape) {
        width: 104px;
      }

      &-icon {
        &::before {
          position: absolute;
          top: 18px;
          left: 12px;
          display: block;
          width: 13px;
          content: "";
          transform: rotate(45deg);
          border-top: 2px solid #000;
        }

        &::after {
          position: absolute;
          top: 18px;
          left: 12px;
          display: block;
          width: 13px;
          content: "";
          transform: rotate(-45deg);
          border-top: 2px solid #000;
        }
      }
    }
  }

  .ripple {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &__position {
      position: absolute;
    }

    &__circle {
      position: absolute;
      transform: scale(0);
      opacity: 1;
      border-radius: 50%;
    }
  }
}

/* 確認ダイアログ */

div {
  &.modal.fade.in {
    z-index: 10005000 !important;
  }

  &.modal-backdrop.fade.in {
    z-index: 104000 !important;
  }
}

h2.modal-title {
  display: none;
}

.history-modal.modal-body#confirm-lead {
  font-size: 1.3rem;
}

button {
  &#confirm-yes {
    background-color: #f0f0f0;
    border: none;
    padding: 4px 12px;
    border-radius: 4px;
  }

  &#confirm-no {
    background-color: #5d7836;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 4px 12px;
  }

  &#ok:disabled {
    color: #d9d9d9;
    background-color: #e5e5e5;
  }
}


/* 蹄病診断結果のグルーピング */

table.lame-diagnosises {
  margin: 0px;
  padding: 0px;
  border: none;
  background-color: white;

  tr {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: white;
  }

  td {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: white;
    padding-bottom: 5px;
  }

  tr {
    th:first-child {
      border: none !important;
      vertical-align: top;
    }

    td {
      &:first-child {
        border: none !important;
        vertical-align: top;
      }

      border: none !important;
      vertical-align: top;
    }
  }
}


/* ハンバーガーメニュー */

.hamburger-menu-button {
  background-repeat: no-repeat;
  padding-left: 20px;
  width: 38px;
  height: 33px;
  display: block;
  float: right;
  position: fixed;
  font-size: 30px;
  right: 20px;
  top: 0px;
  z-index: 999999;

  .is-stickyAlertNotification &,
  .is-stickyAlertDysstasia & {
    top: 10.6rem;

    &.barn--mobileCowDetail {
      top: 102px
    }
  }

  .is-stickyAlertNotification.is-stickyAlertDysstasia & {
    top: 19.4rem;

    .barn--mobileCowDetail & {
      top: 190px
    }
  }

  .barn--mobileMasterSpermDetail & {
    display: none;
  }

  .barn--mobileCowDetail & {
    top: 13px;
    right: 13px;
  }

  .barn--mobileCowDetail.is-stickyAlertNotification &,
  .barn--mobileCowDetail.is-stickyAlertDysstasia & {
    top: 102px
  }

  .barn--mobileCowDetail.is-stickyAlertNotification.is-stickyAlertDysstasia & {
    top: 190px
  }
}

.hamburger-menu-content {
  position: fixed;
  top: 0;
  right: 0;
  width: 95%;
  max-width: 70%;
  transform: translate(999px, 0);
  transition: transform 0.5s;
  background: white;
  font-size: 16px;
  padding: 16px;
  height: 100%;
  bottom: 0;
  z-index: 99999999999;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.5);

  input {
    width: 70%;
    margin-left: 20px;
    padding: 5px;
    font-size: 12px;
    border: 1px solid #ddd;
  }

  &.active {
    transform: translate(0, 0);
  }
}
.hamburger-menu-header {
  width: 100%;
  height: 88px;
  padding: 24px 0 0 24px;
  .hamburger-menu-header-main {
    width: calc(100% - 55px);
    p {
      margin-bottom: 3px;
    }
    .hamburger-menu-header-email {
      font-size: 14px;
    }
  }
}

.hamburger-menu-close-wrapper {
  margin-bottom: 30px;
  padding-right: 9px;

  .hamburger-menu-close {
    float: right;
    background-repeat: no-repeat;
    padding-left: 20px;
    width: 40px;
    height: 40px;
    display: block;
    background-position: -2px 50%;
  }
}

.hamburger-menu-content {
  .menu-list {
    padding-right: 20px;
    padding-left: 20px;
  }

  .menu-item {
    list-style: circle;
    padding: 0;
    margin: 0;
    height: 50px;
    padding: 13px 0px 8px 0px;
    margin-left: 20px;
    cursor: pointer;
    line-height: 50px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.hamburger-backdrop {
  display: none;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  transition: opacity 0.3s;

  &.active {
    display: block;
  }
}

.modal-open .layout-header {
  top: 0!important;
  z-index: 1000;
}

.module-dialog {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  overflow: hidden;
  padding: 3.2rem;
  padding-bottom: 5.2rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999999999;

  .module-form {
    padding: 0;

    .mf-checkbox, .mf-radio {
      font-size: calc(1em - .2rem);
    }

    .mf-checkbox {
      margin-bottom: 1.2rem;
    }

    .mf-checkbox_label {
      font-weight: 400;
      margin-bottom: 0;
      padding-left: 3.3rem;
      position: relative;

      &::before {
        background-image: url('data:image/svg+xml;charset=utf8,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%3Csvg%20version%3D%221.1%22%20id%3D%22%E3%83%AC%E3%82%A4%E3%83%A4%E3%83%BC_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20x%3D%220px%22%20y%3D%220px%22%20viewBox%3D%220%200%2040%2040%22%20style%3D%22enable-background%3Anew%200%200%2040%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%20.st0%7Bfill%3A%23333333%3B%7D%20.st1%7Bdisplay%3Anone%3B%7D%3C%2Fstyle%3E%3Cg%3E%20%3Cpath%20class%3D%22st0%22%20d%3D%22M34.7%2C8v25.8c0%2C1-0.8%2C1.8-1.8%2C1.8H7.1c-1%2C0-1.8-0.8-1.8-1.8V8c0-1%2C0.8-1.8%2C1.8-1.8h25.8%20C33.9%2C6.2%2C34.7%2C7%2C34.7%2C8z%20M32.9%2C9.3c0-0.8-0.6-1.4-1.4-1.4h-23C7.7%2C8%2C7.1%2C8.6%2C7.1%2C9.3v23c0%2C0.8%2C0.6%2C1.4%2C1.4%2C1.4h23%20c0.8%2C0%2C1.4-0.6%2C1.4-1.4C32.9%2C32.3%2C32.9%2C9.3%2C32.9%2C9.3z%22%2F%3E%3C%2Fg%3E%3Ccircle%20class%3D%22st1%22%20cx%3D%2228.7%22%20cy%3D%2228.2%22%20r%3D%226.9%22%2F%3E%3C%2Fsvg%3E');
        content: "";
        height: 2rem;
        margin-right: .5rem;
        position: absolute;
        top: 0;
        left: 0;
        width: 2rem;
      }
    }

    .mf-radio {
      padding-left: .2rem;
    }

    .mf-radio_label {
      max-height: 100%;
      padding-left: 3.1rem;
    }
  }

  .md-body {
    font-size: calc(1em + .2rem);
    padding: 2.5rem 1.6rem 0;
    max-height: 100%;

    &:last-child {
      padding-bottom: 2.5rem;
    }

    &.body-frame {
      border-bottom: .1rem solid #ddd;
      max-height: 54vh;
      overflow-y: auto;
      padding: 2.5rem 1.6rem;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .md-container {
    margin-top: 18%;
    margin-bottom: 18%;
    width: 100%;

    &.container-bottom {
      -webkit-align-self: flex-end;
      -ms-flex-item-align: end;
      align-self: flex-end;
      margin-bottom: 0;
    }

    &.center {
      margin-top: auto;
      margin-bottom: auto;
    }
  }

  .md-content {
    background-color: #fff;
    border-radius: .3rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.16);
    font-size: calc(1em + .2rem);

    p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .md-footer {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1.6rem .8rem;

    .md-button {
      background-color: #fff;
      border: none;
      color: #777;
      font-size: calc(1em + .1rem);
      padding: .6rem .8rem;
      min-width: 6rem;

      &:hover {
        background-color: #f2f2f2;
      }

      &.button-continue {
        color: #366678;
        font-weight: 700;
      }

      ~ .md-button {
        margin-left: .8rem;
      }
    }
  }

  .md-group {
    margin-bottom: 1.2rem;
  }

  .md-group_button {
    background-color: #fff;
    border: none;
    border-radius: 0;
    display: block;
    font-size: calc(1em + .4rem);
    font-weight: 700;
    padding: 1.4rem;
    width: 100%;

    &:hover {
      background-color: #f2f2f2;
    }

    &:first-child {
      border-top-right-radius: .4rem;
      border-top-left-radius: .4rem;
    }

    &:last-child {
      border-bottom-right-radius: .4rem;
      border-bottom-left-radius: .4rem;
    }

    ~ .md-group_button {
      border-top: .1rem solid #ddd;
    }

    &.button-continue {
      color: #366678;
    }

    &.button-danger {
      color: #C92E2E;
    }
  }

  .md-group_title {
    background-color: #F2F2F2;
    border-bottom: .1rem solid #ddd;
    border-radius: .4rem .4rem 0 0;
    color: #777;
    font-weight: 400;
    margin-bottom: 0;
    padding: .9rem;
    text-align: center;
  }

  .md-group:last-child {
    margin-bottom: 0;
  }

  .md-notice {
    font-size: calc(1em - .2rem);
  }

  .md-title {
    color: #fff;
    font-family: MyYuGothicM, YuGothic, "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
    font-size: calc(1em + .8rem);
    font-weight: 500;
    margin-bottom: 1rem;
    padding-right: 1.6rem;
    padding-left: 1.6rem;
  }

  .md-title_label {
    display: block;
    font-size: calc(1em - .6rem);
    font-weight: 400;
  }

  .module-form .mf-group:last-child {
    margin-bottom: 0;
  }

  &.dialog-short {
    .md-container {
      margin-top: calc(18% - 2.4rem);
      margin-bottom: calc(18% - 2.4rem);
    }

    .md-body.body-frame {
      max-height: calc(64vh - 4.8rem);
    }
  }

  .md-button.button-cancel {
    background: none;
    border: none;
    color: #fff;
    height: 2.4rem;
    padding: 0;
    position: fixed;
    top: 1.6rem;
    right: 1.6rem;
    width: 2.4rem;

    &::before, &::after {
      background-color: #fff;
      content: "";
      height: 2.4rem;
      position: absolute;
      top: 0;
      width: .1rem;
    }

    &::before {
      transform: rotate(315deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }

  .md-body .table {
    th, td {
      border-top: none;
      border-bottom: .1rem solid #ddd;
      font-size: calc(1em - .2rem);
      line-height: 1.4;
      padding-top: .9rem;
      padding-bottom: .9rem;
      max-height: 100%;
    }

    &:first-child tr:first-child {
      th, td {
        padding-top: 0;
      }
    }

    tr:last-child {
      th, td {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  .module-dialog {
    top: initial;
    right: initial;
    bottom: initial;
    left: initial;
    z-index: initial;
  }

  &.mobile-cow-search-modal {
    .module-form .mf-group {
      .mf-radiogroup {
        .mf-radiogroup_item .mf-radiogroup_label {
          font-size: 1.3rem;
        }
        .mf-radiogroup_item:not(:first-child) .mf-radiogroup_label {
          font-size: 1.2rem;
        }
      }
      .mf-field input {
        padding: 1rem 1.1rem;
        &::placeholder {
          font-size: 1.2rem;
          line-height: 2.2;
        }
      }
    }
  }
}

.module-controller {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  display: flex;
  align-items: center;
  font-size: calc(1em + .2rem);
  height: 4.8rem;
  margin-bottom: 0;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100000;

  .mc-button {
    background: none;
    border: none;
    padding: 1rem 0;

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }

    &.button-left {
      margin-left: 0;
    }

    &.button-right {
      margin-right: 0;
    }
  }
}

.module-dialog + .module-event-entry + .module-controller {
  z-index: 105001 !important;
}

.modal-open .module-event-entry, .modal-open .module-dialog + .module-event-entry + .module-controller {
  z-index: 1 !important;
}

.modal-open .chakra-modal__overlay--customListCowDetail {
  display: none;
}

/* グローバルナビゲーション */

.hamburger-menu-button {
  background-repeat: no-repeat;
  padding-left: 20px;
  width: 38px;
  height: 33px;
  display: block;
  float: right;
  position: fixed;
  font-size: 30px;
  right: 20px;
  top: 0px;
  z-index: 103000;
}

.hamburger-menu-content {
  position: fixed;
  top: 0;
  right: 0;
  width: 95%;
  max-width: 70%;
  transform: translate(999px, 0);
  transition: transform 0.5s;
  background: white;
  font-size: 16px;
  padding: 16px;
  height: 100%;
  bottom: 0;
  z-index: 106000;
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.5);

  input {
    width: 70%;
    margin-left: 20px;
    padding: 5px;
    font-size: 12px;
    border: 1px solid #ddd;
  }

  &.active {
    transform: translate(0, 0);
  }
}

.hamburger-menu-close-wrapper {
  margin-bottom: 30px;
  padding-right: 9px;

  .hamburger-menu-close {
    float: right;
    background-repeat: no-repeat;
    padding-left: 20px;
    width: 40px;
    height: 40px;
    display: block;
    background-position: -2px 50%;
  }
}

.hamburger-menu-content {
  .menu-list {
    padding-right: 20px;
    padding-left: 20px;
  }

  .menu-item {
    list-style: circle;
    padding: 0;
    margin: 0;
    height: 50px;
    padding: 13px 0px 8px 0px;
    margin-left: 20px;
    cursor: pointer;
    line-height: 50px;

    &:last-child {
      border-bottom: none;
    }
  }
}

.hamburger-backdrop {
  display: none;
  z-index: 100001;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
  opacity: 0.3;
  transition: opacity 0.3s;

  &.active {
    display: block;
  }
}


/* 追加分 */

.hamburger-menu-open {
  overflow: hidden;

  .hamburger-menu-button {
    display: none;
  }
}

.hamburger-menu-button {
  border-top: 2px solid #fff;
  font-size: 0;
  height: 14px;
  top: 1.8rem;
  right: 1.7rem;
  width: 20px;

  &::before, &::after {
    background-color: #fff;
    content: "";
    display: block;
    height: 2px;
    position: absolute;
    left: 0;
    width: 100%;
  }

  &::before {
    bottom: 0;
  }

  &::after {
    top: 4px;
  }
}

.hamburger-menu-close-wrapper {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;

  .hamburger-menu-close {
    background: rgba(57, 57, 57, 0.9);
    font-size: 0;
    height: 55px;
    padding: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 55px;

    &::before, &::after {
      background-color: #fff;
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 24px;
    }

    &::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &::after {
      transform: translate(-50%, -50%) rotate(315deg);
    }
  }

  + br {
    display: none;
  }
}

.hamburger-menu-container {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow-y: auto;
  padding: 40px 16px 16px;
}

.hamburger-menu-content {
  background: rgba(34, 34, 34, 0.9);
  box-shadow: none;
  color: #fff;
  padding: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;

  form {
    position: relative;

    input {
      background-color: transparent;
      border-color: #aaa;
      border-radius: 0;
      font-size: 16px;
      height: 44px;
      margin: 0;
      padding-right: 48px;
      padding-left: 16px;
      width: 100%;
    }

    button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 38px;
      height: 44px;
      padding: 0;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 44px;
    }
  }

  .menu-list {
    margin-top: 48px;
    padding-right: 0;
    padding-left: 0;
  }

  .menu-item {
    border-bottom: 1px solid #888;
    height: auto;
    line-height: inherit;
    list-style: none;
    margin: 0;
    padding: 0;

    &:last-child {
      border-bottom: 1px solid #888;
    }

    a, button {
      background: transparent;
      border: none;
      display: flex;
      align-items: center;
      min-height: 44px;
      line-height: 1.2;
      padding: 8px 32px 8px 16px;
      position: relative;
      text-decoration: none;
      width: 100%;
    }

    .icon-css-arrow {
      position: absolute;
      top: calc(50% - 4px);
      right: 18px;
      display: block;
      width: 10px;
      height: 10px;
      transform: rotate(45deg);
      border-top: 2px solid #fff;
      border-right: 2px solid #fff;
    }

    ul {
      font-size: 14px;
      padding-bottom: 10px;
      padding-left: 0;
    }

    button {
      &[aria-expanded] {
        position: relative;

        &::before, &::after {
          background-color: #fff;
          content: "";
          display: block;
          height: 1px;
          position: absolute;
          top: 50%;
          right: 13px;
          width: 14px;
        }

        &::before {
          transform: translateY(-50%);
        }

        &::after {
          transform: translateY(-50%) rotate(90deg);
        }
      }

      &[aria-expanded="true"]::after, &[aria-expanded="false"] + ul {
        display: none;
      }

      &[aria-expanded="true"] + ul {
        display: block;
      }
    }

    .menu-item {
      border-bottom: none;
      padding-left: 16px;

      a {
        min-height: 36px;
      }
    }
  }
}

.hamburger-menu-banner {
  padding: 8px 0 24px;

  &-inner {
    display: block;
    margin: auto;
    width: fit-content;
    border-radius: 16px;
    overflow: hidden;
  }
}

/* 牛群リスト */

.cow-list table {
  width: 100%;

  th {
    width: 30%;
    vertical-align: top;
  }

  td {
    width: 20%;
    vertical-align: top;
  }
}

.header-info {
  height: 4rem;
  text-align: right;
  padding: 1rem;
  vertical-align: middle;
  font-size: 1.6rem;
  font-weight: bold;
}

.cow-labels {
  display: inline-block;
  padding: 1px 5px;
  margin-top: 2px;
  font-size: 1.1rem;
  margin-left: 3px;
  border: 0.1rem dashed #ccc;
}

.smooth-scroll {
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  overflow: scroll;
}


.comma-separation-group {
  .comma-separation-group-item::after {
    content: ', ';
  }
  .comma-separation-group-item:last-of-type::after {
    content: initial;
  }
}


input.align-right {
  text-align: right;
}

.margin-top-10 {
  margin-top: 10px;
}

[um-type="validity-number"] {
  .mf-group_help {
    color: #d22323;
  }
}

.sensorNumber-separate {
  position: absolute;
  display: flex;
  width: calc(100% - 1.6rem);
  height: 4.5rem;
  pointer-events: none;

  align-items: center;
  justify-content: center;
}

.embryos {
  display: flex;

  flex-wrap: wrap;
  gap: 10px;

  &-item {
    width: calc(50% - 5px);
  }

  .mf-group {
    margin: 0;

    .row {
      margin: 0;

      .col-xs-6 {
        width: 100%;
        padding: 0;
      }
    }
  }
}
