@font-face {
  font-family: 'icomoon-s';
  src:  url('../fonts/s/icomoon.eot?lbhq30');
  src:  url('../fonts/s/icomoon.eot?lbhq30#iefix') format('embedded-opentype'),
    url('../fonts/s/icomoon.ttf?lbhq30') format('truetype'),
    url('../fonts/s/icomoon.woff?lbhq30') format('woff'),
    url('../fonts/s/icomoon.svg?lbhq30#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-s-"], [class*=" icon-s-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-s' !important;
  speak: none;
  font-size: 16px !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-s-back:before {
  content: "\e900";
}
.icon-s-book:before {
  content: "\e901";
}
.icon-s-calendar:before {
  content: "\e902";
}
.icon-s-caution:before {
  content: "\e903";
}
.icon-s-check-box-indeterminate-outlined:before {
  content: "\e904";
}
.icon-s-check-box-outlined:before {
  content: "\e905";
}
.icon-s-check-box:before {
  content: "\e906";
}
.icon-s-check:before {
  content: "\e907";
}
.icon-s-chevron-r01:before {
  content: "\e908";
}
.icon-s-delete:before {
  content: "\e909";
}
.icon-s-doc-pdf:before {
  content: "\e90a";
}
.icon-s-double-arrow:before {
  content: "\e90b";
}
.icon-s-edit:before {
  content: "\e90c";
}
.icon-s-filter:before {
  content: "\e90d";
}
.icon-s-heat:before {
  content: "\e90e";
}
.icon-s-home:before {
  content: "\e90f";
}
.icon-s-modal:before {
  content: "\e910";
}
.icon-s-note:before {
  content: "\e911";
}
.icon-s-notice:before {
  content: "\e912";
}
.icon-s-play-outlined:before {
  content: "\e913";
}
.icon-s-print:before {
  content: "\e914";
}
.icon-s-search:before {
  content: "\e915";
}
.icon-s-setting:before {
  content: "\e916";
}
.icon-s-share:before {
  content: "\e917";
}
.icon-s-slide-left:before {
  content: "\e918";
}
.icon-s-slide-right:before {
  content: "\e919";
}
.icon-s-swap:before {
  content: "\e91a";
}
.icon-s-tag:before {
  content: "\e91b";
}
.icon-s-tune:before {
  content: "\e91c";
}
.icon-s-view-list-outlined:before {
  content: "\e91d";
}
.icon-s-view-module-outlined:before {
  content: "\e91e";
}
