/* mobile-alert-calving-sensor-dropped */

.mobile-alert-calving-sensor-dropped {}

/* mobile-alert-calving-list-item */

.mobile-alert-calving-sensor-dropped-list-item .content-wrap {
  height: 0px;
  overflow: hidden;
  padding: 0;
  transition: height, 0.2s linear;
  width: 100%;

  &[aria-hidden='false'] {
    display: block;
    height: 216px;
    transition: height, 0.2s linear;
  }
}

.mobile-alert-calving-sensor-dropped-list-item .content-wrap-eartag {
  padding: 0;
  width: 100%;
  display: block;
}

.mobile-alert-calving-sensor-dropped-list-item .mm-listItem_continue {
  display: flex;
  justify-content: space-between;
}

.mobile-alert-calving-sensor-dropped-list-item .mm-listItem_continue .solution-selection-button {
  background: #366678;
  color: #fff;
  padding: 2px 15px;
}

/* mobile-alert-calving-sensor-dropped-solution-selection */

.mobile-alert-calving-sensor-dropped-solution-selection .content {
  margin: -54px 0 0 0;
}

.mobile-alert-calving-sensor-dropped-solution-selection .content .content-note {
  background: none;
  border: 0;
  font-size: 17px;
  color: #ffffff;
  height: 54px;
  margin: 0 auto 31px;
  padding: 0;
  width: 230px;
}

.mobile-alert-calving-sensor-dropped-solution-selection .content .content-buttons {
  background: #f0f0f0 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 32px;
  box-shadow: 0px 3px 16px #00000052;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  height: 288px;
  width: 288px;

  %__solution_button_base {
    background: transparent linear-gradient(135deg, #ffffff 0%, #ebebeb 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #f0f0f0;
    border-radius: 32px;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 17px;
    font-weight: 700;
    opacity: 1;
  }

  .reequip-button {
    @extend %__solution_button_base;
    margin: 16px 12px 12px 16px;
  }

  .ignore-button {
    @extend %__solution_button_base;
    margin: 16px 16px 12px 12px;
  }

  .unequip-button {
    @extend %__solution_button_base;
    margin: 12px 12px 16px 16px;
  }

  .replace-button {
    @extend %__solution_button_base;
    margin: 12px 16px 16px 12px;
  }
}

.mobile-alert-calving-sensor-dropped-solution-selection .form-contents {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  box-shadow: 0px 3px 16px #00000052;
  display: grid;
  height: 60%;
  width: 90%;

  .content-note {
    padding: 6px 6px 0 6px;
    font-size: 14px;
  }

  .input-wrapper {
    margin: 0 10px;
  }

  select {
    background: #f2f2f2;
  }

  .mf-action {
    background-color: #f2f2f2;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;

    .mf-action_button {
      background-color: #fff;
      border-color: transparent;
      height: 5.0rem;
      font-size: 17px;
      margin: auto 1rem;
      width: 90%;

      &[type="submit"] {
        background-color: #5d7836;
        color: #fff;
        font-weight: 700;

        &:not(:first-child) {
          width: 162.5%;
        }
      }
    }
  }
}
